import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
// import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { FormField } from 'src/app/shared/types/form-field.interface';
import { FormClassNameEnum } from 'src/app/shared/enums/form-class-name.enum';
import { FormFieldTypeEnum } from 'src/app/shared/enums/form-field-type.enum';
import { RegistrationHelperService } from '../registration-helper.service';
import { Subscription } from 'rxjs';
import { NavHelper } from 'src/app/shared/helpers';
import { AuthMethodsService } from 'src/app/shared/services/auth/auth-methods.service';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  @Output() signInButton = new EventEmitter();
  public form: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  // socialUser: SocialUser;
  isLoggedin: boolean;
  loading = false;
  signUpSubscription: Subscription = null;
  commonServerErrors: string[] = [];
  environment = environment;
  buildVersion = BuildVersion;
  formArray: FormField[] = [
    {
      className: FormClassNameEnum.formGroup,
      fieldType: FormFieldTypeEnum.email,
      placeHolder: this._translateService.instant(
        'MODALS.LOGIN.EMAIL_PLACEHOLDER',
      ),
      controlName: 'email',
      validators: {
        required: true,
        email: true,
      },
    },
    {
      className: FormClassNameEnum.formGroup,
      fieldType: FormFieldTypeEnum.password,
      placeHolder: this._translateService.instant(
        'MODALS.LOGIN.PASSWORD_PLACEHOLDER',
      ),
      controlName: 'password',
      validators: {
        required: true,
        minLength: 5,
      },
    },
    // ANTI-BOT field
    // This field will bw hidden
    // If this field will be filled -> meanss that bot did it.
    {
      className: FormClassNameEnum.formGroupHidden,
      fieldType: FormFieldTypeEnum.password,
      placeHolder: 'Phone',
      controlName: 'phone',
      validators: {},
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _authMethodsService: AuthMethodsService,
    // private socialAuthService: SocialAuthService,
    public registrationHelperService: RegistrationHelperService,
    private _navHelper: NavHelper,
    private _translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.formArray.forEach((item: FormField) => {
      const validators = this.registrationHelperService.setValidators(
        item.validators,
      );
      this.form.addControl(
        item.controlName,
        this.formBuilder.control('', validators),
      );
    });
    // this.socialAuthService.authState.subscribe((user) => {
    //   this.socialUser = user;
    //   this.isLoggedin = user != null;
    // });
  }

  public async singUp(): Promise<void> {
    this.loading = true;
    const { form } = this;
    const { value } = form;
    if (form.valid) {
      this.signUpSubscription = this._authMethodsService
        .signUp(value.email, value.password, value.nickName)
        .subscribe({
          next: () => {
            this.loading = false;
            // this._navHelper.goToMain();
          },
          error: (err) => {
            this.commonServerErrors =
              this.registrationHelperService.setServerErrors(err, this.form);
            this.loading = false;
          },
        });
    } else {
      this.loading = false;
      Object.keys(form.controls).forEach((field) => {
        const control = form.controls[field];
        if (!control.valid || control.value.lenght) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  public goToSignIn(): void {
    this.signInButton.emit();
  }

  public checkLength(event): void {
    if (event.target.value.length > 40) {
      event.target.value = event.target.value.slice(0, 40);
    }
  }

  ngOnDestroy() {
    this.signUpSubscription?.unsubscribe();
  }
}
