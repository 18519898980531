<div #bar (click)="shopPopup()" class="bar">
  <div class="fill negative-back" [class.visible]="isZeroLevel"></div>
  <div
    class="fill fill-back"
    [style.left]="backLeft + '%'"
    [style.right]="backRight + '%'"
    [class.negative]="ratingBack < 0"
  ></div>
  <div
    #fill
    class="fill fill-main"
    [style.left]="barLeft + '%'"
    [style.right]="barRight + '%'"
    [class.negative]="progress < 0"
  >
    <div class="rating-popup" [class.negative]="rating < 0">
      <div class="popup-content" [class.visible]="popupVisible">
        {{ rating }}
      </div>
    </div>
  </div>

  <div class="heart left-heart">
    <img
      src="/assets/img/chat-page/rating-bar/broken_heart.png"
      alt=""
      [style.opacity]="brokenHeartVisible ? 1 : 0"
    />
    <img
      src="/assets/img/chat-page/rating-bar/completed_heart.png"
      alt=""
      [style.opacity]="brokenHeartVisible ? 0 : 1"
    />
  </div>
  <div class="heart heart-animation" [class.animated]="heartAnimationEnabled">
    <img
      src="/assets/img/chat-page/rating-bar/heart.png"
      alt=""
      [style.opacity]="animatedHeartVisible ? 1 : 0"
    />
  </div>
  <div class="heart right-heart">
    <img src="/assets/img/chat-page/rating-bar/heart.png" alt="" />
    <span class="level-value" [class.max-level]="level >= maxLevel">
      {{ level >= maxLevel ? "max" : level + 1 }}
    </span>
  </div>
</div>

<div
  #ratingCircleWrapper
  class="rating-circle"
  [class.animated]="ratingValueAnimationEnabled"
>
  <div
    class="horizontal-wrapper"
    [class.animated]="ratingValueAnimationEnabled"
  >
    <div
      class="vertical-wrapper"
      [class.animated]="ratingValueAnimationEnabled"
    >
      <div #ratingCircle class="rating-value" [class.negative]="ratingDiff < 0">
        {{ ratingDiff > 0 ? "+" + ratingDiff : ratingDiff }}
      </div>
    </div>
  </div>
</div>

<app-chat-levelup-popup></app-chat-levelup-popup>
