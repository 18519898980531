import { TutorialService } from 'src/app/shared/services/communication_services/tutorial.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { PromptsInChatType } from 'src/app/shared/services/prompts-in-chat.service';
import { NextMessageAnswer } from 'src/app/shared/types/next-message.interface';
import { PaywallService } from 'src/app/shared/services/paywall.service';
import { ChatAnswersService } from '../services/chat-answers.service';
import { ChatStateService } from '../services/chat-state.service';
import { ChatTrialService } from '../services/chat-trial.service';
import { ChatService } from '../services/chat.service';
import { Component, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription, map } from 'rxjs';

enum CustomMessageId {
  agePrompt = 'AGE_PROMPT',
  agreementPrompt = 'AGREEMENT_PROMPT',
}

@Component({
  selector: 'app-answer-input',
  templateUrl: './answer-input.component.html',
  styleUrls: ['./answer-input.component.scss'],
})
export class ChatAnswerInputComponent implements OnDestroy {
  public inputField = '';

  public readonly answers$ = this._chatAnswersService.answerMessage$.pipe(
    map((m) => m?.answers ?? []),
  );
  public readonly tutorialStep$ = this._tutorialService.currentStep$;
  public readonly chatState$ = this._chatStateService.chatState$;
  public readonly isAiChat$ = this._chatStateService.isAiChat$;

  public readonly maxMessageLength = this._chatAnswersService.maxMessageLength;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _chatAnswersService: ChatAnswersService,
    private _analyticsService: AnalyticsService,
    private _chatStateService: ChatStateService,
    private _chatTrialService: ChatTrialService,
    private _tutorialService: TutorialService,
    private _paywallService: PaywallService,
    private _chatService: ChatService,
    private _platform: Platform,
  ) {
    this._subscriptions.push(
      this._chatAnswersService.answerMessage$.subscribe((res) => {
        this._tutorialService.isAnswersVisible$.next(!!res);
      }),
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  public sendAnswer(answer: NextMessageAnswer) {
    if (answer.isNicknameInput) {
      this.submitNickname(answer.localizedMessageText.en, false);
      return;
    }

    const customMessageId = this._chatAnswersService.answerMessage$.value
      .id as CustomMessageId;

    if (Object.values(CustomMessageId).includes(customMessageId)) {
      this.handleCustomQuestionAnswering(answer, customMessageId);
      return;
    }

    this._subscriptions.push(
      this._chatAnswersService.clickOnAnswer(answer).subscribe(),
    );
  }

  public clickEmpty() {
    if (this._chatStateService.chatState$.value === 'aiOffline') {
      this._chatService
        .getNextMessages(this._chatStateService.chatId)
        .subscribe();
    } else if (this._chatStateService.chatState$.value === 'trialBlocked') {
      this._chatTrialService.openTrialPurchase();
    }
  }

  public openPaywall() {
    this._paywallService.showPaywall('chat').subscribe();
  }

  public submitInput(answer: NextMessageAnswer, event?: Event) {
    event?.preventDefault();
    if (!this.inputField.trim().length) {
      return;
    }
    const input = this.inputField;
    this.inputField = '';

    if (answer.isNicknameInput) {
      this.submitNickname(input, true);
    } else {
      this._subscriptions.push(
        this._chatAnswersService.submitInput(input).subscribe((result) => {
          if (!result) {
            this.inputField = input;
          }
        }),
      );
    }
  }

  private submitNickname(nickname: string, handWrited: boolean) {
    this._subscriptions.push(
      this._chatAnswersService.submitNickname(nickname, handWrited).subscribe(),
    );
  }

  private handleCustomQuestionAnswering(
    answer: NextMessageAnswer,
    customMessageId: CustomMessageId,
  ) {
    switch (customMessageId) {
      case CustomMessageId.agePrompt:
        if (answer.id < 0) {
          this._analyticsService.ageIsSet(false);
          const storeLink =
            'https://apps.apple.com/cy/app/lovechats/id6444935703';
          // const storeLink = this._platform.is('ios')
          //   ? 'https://apps.apple.com/cy/app/lovechats/id6444935703'
          //   : 'https://play.google.com/store/apps/details?id=app.perchapps.lovechats';
          this._chatAnswersService.answerMessage$.next(null);
          setTimeout(() => (location.href = storeLink), 1000); //  Settimout to set analytics before redirect
        } else {
          this._subscriptions.push(
            this._chatAnswersService
              .customAnswer(PromptsInChatType.ageOver18, answer)
              .subscribe(),
          );
        }

        break;
      case CustomMessageId.agreementPrompt:
        this._subscriptions.push(
          this._chatAnswersService
            .customAnswer(PromptsInChatType.agreement, answer)
            .subscribe(),
        );
        break;
    }
  }
}
