import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { PromoAnalyticsData } from './../../types/show-promo-analytics-options.interface';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';
import { PromoBrand } from '../../enums/chat-promo-brand.enum';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { PromoStripchatService } from '../../services/promo-stripchat.service';

const bannerList = environment.banners;

@Component({
  selector: 'app-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss'],
})
export class BannerSliderComponent implements OnInit {
  @Input() bannerAnalyticOptions: PromoAnalyticsData = null;
  public showBanner = false;
  banners: {
    img: string;
    url: string;
  }[];
  getPackSub: Subscription;
  link: string;
  activeSlide = 0;
  swiperConfig: SwiperOptions = {
    autoplay: {
      delay: 8000,
    },
    spaceBetween: 30,
    loop: true,
    parallax: true,
    centeredSlides: true,
    navigation: true,
    initialSlide: Math.floor(Math.random() * bannerList.length),
  };

  constructor(
    private _analyticsService: AnalyticsService,
    private _promoStripchatService: PromoStripchatService,
    private _settingsDataService: SettingsDataService,
  ) {}

  onSlideChange(event) {
    this.activeSlide = event.activeIndex;
  }

  clickOnBanner(banner: string) {
    this._analyticsService.clickOnPromoWidget({
      promo: PromoBrand.bannerSlider,
      chatName: this.bannerAnalyticOptions.chatName,
      section: this.bannerAnalyticOptions.section,
      placeInSection: this.bannerAnalyticOptions.placeInSection,
      messageIndex: this.bannerAnalyticOptions.messageIndex,
      positionInChatList: this.bannerAnalyticOptions.positionInChatList,
      bannerSlide: banner,
    });
  }

  ngOnInit() {
    console.log(
      'showBanner',
      this.showBanner,
      environment.isPromoWidgetChat,
      environment.showAdmobAds,
    );
    this.link = this._promoStripchatService.getStandaloneBannerLink();
    this.banners = bannerList.map((banner) => {
      if (banner.url === '') {
        banner.url = this.link;
      }
      return banner;
    });

    if (environment.isPromoWidgetChat) {
      this._settingsDataService.updateSettingsData$.subscribe((res) => {
        this.showBanner = res.isAgeConfirmed && res.isAgreementAccepted;
      });
    }
  }
}
