import { WebsocketSignalRService } from '../../services/websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { PaymentsService } from '../../services/payments/payments.service';
import {
  BehaviorSubject,
  Observable,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { PurchaseSource } from '../../enums/purchase-source.enum';
import { OfferData } from '../../types/offer-data.interface';
import { ShopLot } from '../../types/shop-lot-new.interface';
import { OfferType } from '../../enums/offer-type.enum';
import { Injectable } from '@angular/core';
import { BonusService } from '../../services/API_services/bonus.service';
import { UserPreference } from '../../enums/user-preference.enum';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ShopOffersService {
  public readonly offers$ = new BehaviorSubject<OfferData[]>([]);

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _paymentsService: PaymentsService,
    private _bonusService: BonusService,
  ) {}

  public fetchOffers(): Observable<boolean> {
    return this._bonusService.questLines.pipe(
      filter((x) => x != null),
      take(1),
      switchMap(() =>
        this._websocketSignalRService
          .invoke<ShopLot[]>(WebsocketCommandType.getPacks, {})
          .pipe(
            map((res) => {
              const knownOfferTypes = this.getKnownOfferTypes();

              // res = [
              //   {
              //     ...res[0],
              //     offerType: OfferType.bigBreastsAndFlatChestsEvent,
              //   },
              // ];

              // res.find(
              //   (o) => o.offerType === OfferType.bigBreastsAndFlatChestsEvent,
              // ).offerType = OfferType.easter2024;

              this.offers$.next(
                res
                  .filter(
                    (pack) =>
                      pack.offerType !== OfferType.monthlySubscription &&
                      pack.offerType !== OfferType.weeklySubscription &&
                      pack.offerType !== OfferType.yearlySubscription &&
                      pack.offerType !== OfferType.threeChats &&
                      pack.offerType !== OfferType.fiveChats &&
                      knownOfferTypes.includes(pack.offerType),
                  )
                  .map((pack) => ({
                    productId: pack.productId,
                    id: pack.productId,
                    type: pack.offerType,
                    time: 0,
                    purchase: pack.purchase,
                    softPurchase: pack.softPurchase,
                    localizedTitle: pack.localizedTitle,
                    localizedDescription: pack.localizedDescription,
                    items: pack.items,
                    coins: 0,
                    chats: [],
                    userPreference: pack.userPreference,
                  })),
              );

              if (environment.buildVersion === BuildVersion.default) {
                this.offers$.next([
                  this.createOnlyfansOffer(),
                  ...this.offers$.value,
                ]);
              } else {
                const discordOffer = this.createDiscordOffer();

                if (discordOffer) {
                  this.offers$.next([discordOffer, ...this.offers$.value]);
                }
              }

              return true;
            }),
          ),
      ),
    );
  }

  public purchase(offer: OfferData): Observable<boolean> {
    return this._paymentsService.purchaseOffer(offer, PurchaseSource.shop).pipe(
      tap((res) => {
        if (res) {
          const packs = this.offers$.value.filter(
            (p) => p.productId !== offer.productId,
          );
          this.offers$.next(packs);
        }
      }),
    );
  }

  private getKnownOfferTypes(): string[] {
    const knownOfferTypes: string[] = [];
    for (const key in OfferType) {
      if (Object.prototype.hasOwnProperty.call(OfferType, key)) {
        knownOfferTypes.push(OfferType[key]);
      }
    }
    return knownOfferTypes;
  }

  private createDiscordOffer(): OfferData {
    const quest = this._bonusService.getDiscordQuest();

    if (!quest) {
      return null;
    }

    return {
      productId: '',
      id: quest.action.data,
      type: OfferType.discord,
      time: 0,
      purchase: null,
      softPurchase: null,
      localizedTitle: null,
      localizedDescription: null,
      items: [],
      coins: 0,
      chats: [],
      userPreference: UserPreference.none,
    };
  }

  private createOnlyfansOffer(): OfferData {
    return {
      productId: '',
      id: 'onlyfans',
      type: OfferType.onlyfans,
      time: 0,
      purchase: null,
      softPurchase: null,
      localizedTitle: null,
      localizedDescription: null,
      items: [],
      coins: 0,
      chats: [],
      userPreference: UserPreference.none,
    };
  }
}
