import {
  InventoryItem,
  InventoryService,
} from 'src/app/shared/services/inventory.service';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { HalloweenModalComponent } from './modal/halloween-modal.component';
import { ShopLotItem } from 'src/app/shared/types/shop-lot-item.interface';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { BehaviorSubject, catchError, map, of, tap } from 'rxjs';
import { NavHelper } from 'src/app/shared/helpers';
import { Injectable } from '@angular/core';
import { ToastsService } from 'src/app/shared/services/toasts.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';

type ScratchOfferReward = ShopLotItem & {
  isClaimable: boolean;
  isClaimed: boolean;
};

interface ScratchOfferResponse {
  scratchPrice: number;
  scratchedCount: number;
  rewards: ScratchOfferReward[];
}

export interface HalloweenReward {
  index: number;
  itemIndex: number;
  item: ScratchOfferReward;
}

@Injectable({
  providedIn: 'root',
})
export class Halloween2023Service {
  public readonly rewards$ = new BehaviorSubject<HalloweenReward[]>([]);
  public readonly isAvailiable$ = new BehaviorSubject(false);

  private _isShown = false;
  private _scratchPrice = 0;
  private _scratchedCount = 0;
  private _allRewards: ScratchOfferReward[] = [];
  private _pendingScratchCount = 0;
  private readonly _lsKey = 'halloween23_rewards';

  public get scratchPice() {
    return this._scratchPrice;
  }

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _inventoryService: InventoryService,
    private _analyticsService: AnalyticsService,
    private _toastsService: ToastsService,
    private _modalsService: ModalsService,
    private _navHelper: NavHelper,
  ) {
    this.rewards$.next(
      [...Array(9).keys()].map((i) => ({
        index: i,
        itemIndex: -1,
        item: null,
      })),
    );
  }

  public init() {
    return this.fetchOfferData();
  }

  public openModal(source: 'modal' | 'quest') {
    if (!this.isAvailiable$.value) {
      return of(true);
    }

    if (!this._isShown || source === 'quest') {
      this._analyticsService.showScratch(source);
      this._isShown = true;
      return this._modalsService.openModal(HalloweenModalComponent);
    }

    return of(true);
  }

  public scratchReward(index: number) {
    if (
      this._inventoryService.gems -
        this._pendingScratchCount * this.scratchPice <
      this._scratchPrice
    ) {
      this._toastsService.showError('Not enough tokens');
      this._navHelper.goToShopGems();
      return false;
    }

    this.rewards$.value[index].item = this._allRewards[this._scratchedCount];

    const savedData = this.getSavedData();
    savedData[index] = this._scratchedCount;
    this.saveLsData(savedData);
    this.rewards$.value[index].itemIndex = this._scratchedCount;

    this._scratchedCount++;
    this._pendingScratchCount++;

    this._websocketSignalRService
      .invoke<{ inventory: InventoryItem[] }>(
        WebsocketCommandType.scratchHalloweenOfferPosition,
      )
      .subscribe({
        next: ({ inventory }) => {
          this._inventoryService.setInventory(inventory);
          this.rewards$.value[index].item.isClaimable = true;
          this._pendingScratchCount--;
          this._analyticsService.scratchOffer(this._scratchedCount);
        },
        error: (error) => {
          console.error(error);

          if (error.error) {
            switch (error.error.status) {
              case 'notEnoughCoins':
                this._navHelper.goToShopGems();
                this._toastsService.showError('Not enough tokens');
                break;
              case 'offerNotAvailable':
              case 'allPositionsScratched':
              default:
                this._toastsService.showError();
                break;
            }
          }

          this._pendingScratchCount--;
        },
      });

    return true;
  }

  public getReward(itemIndex: number) {
    return this._websocketSignalRService
      .invoke<{ inventory: InventoryItem[] }>(
        WebsocketCommandType.claimHalloweenScratchOfferReward,
        { index: itemIndex },
      )
      .pipe(
        map(({ inventory }) => {
          this._inventoryService.setInventory(inventory);

          this.rewards$.value.find(
            (r) => r.itemIndex === itemIndex,
          ).item.isClaimed = true;

          if (
            this.rewards$.value.filter((r) => r.item?.isClaimed).length === 9
          ) {
            this.isAvailiable$.next(false);
          }

          this.rewards$.next(this.rewards$.value);
          return true;
        }),
        catchError((error) => {
          console.error(error);

          if (error.error) {
            switch (error.error.status) {
              case 'alreadyClaimed':
                this._toastsService.showError('Reward already claimed');
                break;
              case 'offerNotAvailable':
              case 'notScratched':
              case 'invalidIndex':
              default:
                this._toastsService.showError();
                break;
            }
          }

          return of(false);
        }),
      );
  }

  private getSavedData(): number[] {
    const data = localStorage.getItem(this._lsKey);

    if (data) {
      return JSON.parse(data);
    }
    return [-1, -1, -1, -1, -1, -1, -1, -1, -1];
  }

  public saveLsData(data: number[]) {
    localStorage.setItem(this._lsKey, JSON.stringify(data));
  }

  private fetchOfferData() {
    return this._websocketSignalRService
      .invoke<ScratchOfferResponse>(
        WebsocketCommandType.getHalloweenScratchOffer,
        {},
      )
      .pipe(
        tap((res) => {
          if (!res || res.rewards.filter((r) => r.isClaimed).length === 9) {
            return;
          }

          this.isAvailiable$.next(true);

          this._scratchPrice = res.scratchPrice;
          this._scratchedCount = res.scratchedCount;
          this._allRewards = res.rewards;

          const savedData = this.getSavedData();

          //if local storage data erased
          if (
            savedData.filter((el) => el !== -1).length !==
            res.rewards.filter((el) => el.isClaimable || el.isClaimed).length
          ) {
            for (let i = 0; i < 9; i++) {
              savedData[i] = i < res.scratchedCount ? i : -1;
            }
            this.shuffleArray(savedData);
            this.saveLsData(savedData);
          }

          this.rewards$.value.forEach((reward) => {
            const savedIndex = savedData[reward.index];

            if (savedIndex !== -1) {
              reward.itemIndex = savedIndex;
              reward.item = res.rewards[savedIndex];
            }
          });
        }),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
