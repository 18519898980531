import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { RealCostProperties } from '../../types/purchase-data.interface';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-price-block',
  templateUrl: './price-block.component.html',
  styleUrls: ['./price-block.component.scss'],
})
export class PriceBlockComponent implements OnInit, OnDestroy {
  @Input()
  public purchase: RealCostProperties;
  @Input()
  public oldPrice = false;
  @Input()
  public nextPrice = false;

  public readonly isNutaku = environment.buildVersion === BuildVersion.nutaku;
  public readonly isErogames =
    environment.buildVersion === BuildVersion.erogames;
  public readonly environment = environment;

  public price: string | number = null;

  private _priceSubscribtion: Subscription;

  constructor(
    private _legalPaymentService: LegalPaymentService,
    private _platform: Platform,
  ) {}

  public ngOnInit(): void {
    this.setPrice();
  }

  public setPrice() {
    if (!this.oldPrice && !this.nextPrice) {
      switch (environment.buildVersion) {
        case BuildVersion.default:
          this.price = `$${this.purchase.cost.toFixed(2)}`;
          break;
        case BuildVersion.nutaku:
          this.price = this.purchase.nutakuCost;
          break;
        case BuildVersion.erogames:
          this.price = this.purchase.erogamesCost;
          break;
        case BuildVersion.legal:
          if (!this._platform.is('cordova')) {
            this.price = '{price}';
            break;
          }
          this._priceSubscribtion = this._legalPaymentService
            .getProductPricingPhases(this.purchase)
            .subscribe((phases) => {
              this.price = phases[0].price;
            });
          break;
      }
    } else if (this.oldPrice) {
      switch (environment.buildVersion) {
        case BuildVersion.default:
          this.price = `$${this.purchase.oldCost.toFixed(2)}`;
          break;
        case BuildVersion.nutaku:
          this.price = this.purchase.oldNutakuCost;
          break;
        case BuildVersion.erogames:
          this.price = this.purchase.oldErogamesCost;
          break;
        case BuildVersion.legal:
          this.price = '';
          break;
      }
    } else if (this.nextPrice) {
      switch (environment.buildVersion) {
        case BuildVersion.default:
          this.price = `$${this.purchase.nextCost.toFixed(2)}`;
          break;
        case BuildVersion.legal:
          if (!this._platform.is('cordova')) {
            this.price = '{price}';
            break;
          }
          this._priceSubscribtion = this._legalPaymentService
            .getProductPricingPhases(this.purchase)
            .subscribe((phases) => {
              this.price = phases[1].price;
            });
          break;
        case BuildVersion.nutaku:
        case BuildVersion.erogames:
          this.price = '';
          break;
      }
    }
  }

  // private getLegalPrice(phase: CdvPurchase.PricingPhase) {
  //   if (phase.recurrenceMode === CdvPurchase.RecurrenceMode.NON_RECURRING) {
  //     return phase.price;
  //   }

  //   if (phase.priceMicros === 0) {
  //     return `${this.getPeriodLength(phase.billingPeriod)} ${this.getPeriodType(
  //       phase.billingPeriod,
  //       true,
  //     )} free`;
  //   } else {
  //     return `${phase.price} / ${this.getPeriodType(
  //       phase.billingPeriod,
  //       false,
  //     )}`;
  //   }
  // }

  // private getPeriodLength(billingPeriod: string) {
  //   return billingPeriod.slice(1, billingPeriod.length - 1);
  // }

  // private getPeriodType(billingPeriod: string, plural: boolean) {
  //   const type = billingPeriod.slice(billingPeriod.length - 1);

  //   switch (type) {
  //     case 'D':
  //       return plural ? 'days' : 'day';
  //     case 'W':
  //       return plural ? 'weeks' : 'week';
  //     case 'M':
  //       return plural ? 'months' : 'month';
  //     case 'Y':
  //       return plural ? 'years' : 'year';
  //     default:
  //       return type;
  //   }
  // }

  public ngOnDestroy(): void {
    this._priceSubscribtion?.unsubscribe();
  }
}
