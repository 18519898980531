import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Observable,
  Subscription,
  animationFrameScheduler,
  interval,
  of,
  pairwise,
  timer,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  endWith,
  last,
  map,
  skip,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { ChatLevelUpPopupComponent } from '../chat-levelup-popup/chat-levelup-popup.component';
import { GlobalRatingService } from '../../services/global-rating.service';
import { TutorialService } from '../../services/communication_services/tutorial.service';
import { ChatStateService } from '../../views/chat/services/chat-state.service';

interface RatingValue {
  rating: number;
  progress: number;
  level: number;
}

interface RatingStep {
  prev: RatingValue;
  current: RatingValue;
}

@Component({
  selector: 'app-chat-rating-bar',
  templateUrl: './chat-rating-bar.component.html',
  styleUrls: ['./chat-rating-bar.component.scss'],
})
export class ChatRatingBarComponent implements OnInit, OnDestroy {
  @ViewChild(ChatLevelUpPopupComponent)
  public levelUpPopup: ChatLevelUpPopupComponent;
  @ViewChild('bar')
  public readonly barEl: ElementRef<HTMLElement>;
  @ViewChild('fill')
  public readonly fillEl: ElementRef<HTMLElement>;
  @ViewChild('ratingCircleWrapper')
  public readonly ratingCircleWrapperEl: ElementRef<HTMLElement>;
  @ViewChild('ratingCircle')
  public readonly ratingCircleEl: ElementRef<HTMLElement>;

  public readonly maxLevel = 7;

  public level = 0;
  public rating = 0;
  public progress = 0;
  public ratingBack = 0;
  public ratingDiff = 0;
  public backLeft = 50;
  public backRight = 50;
  public barLeft = 50;
  public barRight = 50;
  public popupVisible = false;
  public animatedHeartVisible = false;
  public brokenHeartVisible = true;
  public heartAnimationEnabled = false;
  public ratingValueAnimationEnabled = false;

  public isZeroLevel = true;

  private _steps: RatingStep[] = [];
  private _animationEnabled = false;
  private _initialized = false;

  private readonly _barAminationLength = 500;
  private readonly _barAnimationDelay = 300;
  private readonly _popupShowTime = 1500;

  private _subscriptions: Subscription[] = [];
  private _popupSubscription: Subscription;

  constructor(
    private _unsubscribeHelper: UnsubscribeHelper,
    private _changeDetection: ChangeDetectorRef,
    private _globalRatingService: GlobalRatingService,
    private _tutorialService: TutorialService,
    private _chatStateService: ChatStateService,
  ) {}

  public ngOnInit() {
    this._subscriptions.push(
      this._chatStateService.chatRating$
        .pipe(
          skip(1),
          map(
            (rating) =>
              ({
                rating,
                level: this._chatStateService.chatLevel$.value,
                progress: this._chatStateService.chatLevelProgress$.value,
              }) as RatingValue,
          ),
          pairwise(),
          map((res) => ({ prev: res[0], current: res[1] }) as RatingStep),
        )
        .subscribe((res) => {
          if (!this._initialized) {
            this._initialized = true;
            this.setLevel(res.current.level);
            this.brokenHeartVisible = res.current.level === 0;
            this.rating = res.current.rating;
            this.progress = res.current.progress;
            this.barLeft = this.calculateLeft(res.current.progress);
            this.barRight = this.calculateRight(res.current.progress);
            this.shopPopup();
            return;
          }

          if (
            res.prev.level === res.current.level &&
            res.prev.rating === res.current.rating
          ) {
            return;
          }

          if (this._animationEnabled) {
            this._steps.push(res);
          } else {
            this._subscriptions.push(this.doStep(res).subscribe());
          }
        }),
    );
  }

  public shopPopup(force = false) {
    if (!force && this._animationEnabled) {
      return;
    }
    this._popupSubscription?.unsubscribe();
    this.popupVisible = true;
    this._popupSubscription = timer(this._popupShowTime).subscribe(
      () => (this.popupVisible = false),
    );
  }

  private doStep(step: RatingStep): Observable<void> {
    this._animationEnabled = true;

    this.ratingDiff = step.current.rating - step.prev.rating;

    return of(null).pipe(
      switchMap(() => this.ratingCircleAnimation()),
      switchMap(() => {
        if (step.prev.level === step.current.level) {
          return this.moveProgress(step.prev, step.current);
        } else if (step.prev.level < step.current.level) {
          const changeLevelRating =
            step.prev.rating +
            ((100 - step.prev.progress) /
              (step.current.progress - step.prev.progress + 100)) *
              (step.current.rating - step.prev.rating);

          return this.moveProgress(step.prev, {
            level: step.prev.level,
            progress: 100,
            rating: changeLevelRating,
          }).pipe(
            map(() => this.setLevel(step.current.level)),
            switchMap(() =>
              this.levelChangeBarAnimation(step.prev.level === 0, true),
            ),
            switchMap(() => this.heartAnimation()),
            switchMap(() => {
              if (step.current.progress === 0) {
                this.shopPopup(true);
                return of(null);
              } else {
                return this.moveProgress(
                  {
                    level: step.current.level,
                    progress: 0,
                    rating: changeLevelRating,
                  },
                  step.current,
                );
              }
            }),
            tap(() => this.tryShowLevelUpPopup()),
          );
        } else {
          const changeLevelRating =
            step.prev.rating +
            (step.prev.progress /
              (step.prev.progress - step.current.progress + 100)) *
              (step.current.rating - step.prev.rating);

          return (
            step.prev.progress === 0
              ? of(null)
              : this.moveProgress(step.prev, {
                  level: step.prev.level,
                  progress: 0,
                  rating: changeLevelRating,
                })
          ).pipe(
            map(() => this.setLevel(step.current.level)),
            switchMap(() => {
              this.brokenHeartVisible = step.current.level === 0;
              this._changeDetection.detectChanges();
              return this.levelChangeBarAnimation(
                step.current.level === 0,
                false,
              );
            }),
            switchMap(() =>
              this.moveProgress(
                {
                  level: step.current.level,
                  progress: 100,
                  rating: changeLevelRating,
                },
                step.current,
              ),
            ),
          );
        }
      }),
      switchMap(() => {
        if (this._steps.length) {
          return this.doStep(this._steps.shift());
        } else {
          this._animationEnabled = false;
          return of(null);
        }
      }),
    );
  }

  private ratingCircleAnimation(): Observable<void> {
    const fillRect = this.fillEl.nativeElement.getBoundingClientRect();
    const wrapperRect =
      this.ratingCircleWrapperEl.nativeElement.getBoundingClientRect();

    let x =
      this.rating < 0
        ? wrapperRect.x - fillRect.x
        : wrapperRect.x - fillRect.right;

    if (this.level !== 0 && (this.progress === 0 || this.progress === 100)) {
      const barRect = this.barEl.nativeElement.getBoundingClientRect();
      x = wrapperRect.x - barRect.x;
    }

    let y = wrapperRect.y - fillRect.top;

    if (document.querySelector('ion-app')?.classList.contains('low-height')) {
      const scaleK = 1 / 0.7;
      x *= scaleK;
      y *= scaleK;
    }

    this.ratingCircleWrapperEl.nativeElement.style.setProperty(
      '--vertical',
      `translateY(${-y}px)`,
    );
    this.ratingCircleWrapperEl.nativeElement.style.setProperty(
      '--vertical-up',
      `translateY(${-y - 30}px)`,
    );
    this.ratingCircleWrapperEl.nativeElement.style.setProperty(
      '--horizontal',
      `translateX(${-x}px)`,
    );

    this.ratingValueAnimationEnabled = true;

    return timer(1000).pipe(
      map(() => {
        this.ratingCircleEl.nativeElement.style.transform = 'scale(0)';
        this._subscriptions.push(
          timer(300)
            .pipe(
              map(() => {
                this.ratingCircleEl.nativeElement.style.transform = 'none';
                this.ratingValueAnimationEnabled = false;
              }),
            )
            .subscribe(),
        );
      }),
    );
  }

  private heartAnimation(): Observable<void> {
    this.heartAnimationEnabled = false;
    this.animatedHeartVisible = true;
    this._changeDetection.detectChanges();

    return timer(300).pipe(
      switchMap(() => {
        this.heartAnimationEnabled = true;
        return timer(1000);
      }),
      map(() => {
        this.brokenHeartVisible = false;
        this.animatedHeartVisible = false;
        return null;
      }),
    );
  }

  private levelChangeBarAnimation(
    fromZero: boolean,
    positive: boolean,
  ): Observable<void> {
    this.backLeft = 50;
    this.backRight = 50;
    this.barRight = 0;

    return of(null).pipe(
      startWith(0),
      pairwise(),
      switchMap(() => {
        const startTime = animationFrameScheduler.now();

        return interval(0, animationFrameScheduler).pipe(
          map(() => animationFrameScheduler.now() - startTime),
          map((elapsedTime) => elapsedTime / this._barAminationLength),
          takeWhile((progress) => progress <= 1),
          map((progress) => progress * 100),
          endWith(100),
          distinctUntilChanged(),
        );
      }),
      map((x) => {
        if (positive) {
          this.barLeft = fromZero ? 50 + x / 2 : x;
        } else {
          this.barLeft = 100 - (fromZero ? x / 2 : x);
        }
      }),
      last(),
      map(() => {
        this.barLeft = 0;
        this.barRight = 100;
      }),
    );
  }

  private calculateLeft(x: number) {
    return this.isZeroLevel ? (x > 0 ? 50 : 50 + x / 2) : 0;
  }
  private calculateRight(x: number) {
    return this.isZeroLevel ? (x > 0 ? (100 - x) / 2 : 50) : 100 - x;
  }

  private moveProgress(from: RatingValue, to: RatingValue): Observable<void> {
    this._popupSubscription?.unsubscribe();

    this.ratingBack = to.progress;
    this.backLeft = this.calculateLeft(to.progress);
    this.backRight = this.calculateRight(to.progress);
    this.barLeft = this.calculateLeft(from.progress);
    this.barRight = this.calculateRight(from.progress);
    this.popupVisible = true;

    return of(null).pipe(
      delay(this._barAnimationDelay),
      startWith(0),
      pairwise(),
      switchMap(() => {
        const startTime = animationFrameScheduler.now();

        return interval(0, animationFrameScheduler).pipe(
          map(() => animationFrameScheduler.now() - startTime),
          map((elapsedTime) => elapsedTime / this._barAminationLength),
          takeWhile((progress) => progress <= 1),
          map((progress) => {
            const diff = to.progress - from.progress;
            return { progressValue: from.progress + progress * diff, progress };
          }),
          endWith({ progressValue: to.progress, progress: 1 }),
          distinctUntilChanged(),
        );
      }),
      map((res) => {
        this.rating = Math.floor(
          from.rating + (to.rating - from.rating) * res.progress,
        );
        this.progress = res.progressValue;

        this.barLeft = this.calculateLeft(res.progressValue);
        this.barRight = this.calculateRight(res.progressValue);
      }),
      last(),
      delay(this._barAnimationDelay),
      map(() => {
        this.popupVisible = false;
      }),
      delay(this._barAnimationDelay),
    );
  }

  private setLevel(value: number) {
    this.level = value;
    this.isZeroLevel = value === 0;
  }

  private tryShowLevelUpPopup() {
    if (this._tutorialService.currentStep$.value) {
      return;
    }

    this._globalRatingService.rewards.value.forEach((r) => {
      if (
        r.requiredRating ===
          this._globalRatingService.currentRating.value + 1 &&
        !r.taken
      ) {
        this.levelUpPopup.show();
        return;
      }
    });
  }

  public ngOnDestroy(): void {
    this._popupSubscription?.unsubscribe();
    this._unsubscribeHelper.unsubscribe(this._subscriptions);
  }
}
