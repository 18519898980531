import { PremiumUnsubscribeModalModule } from '../premium-unsubscribe-modal/premium-unsubscribe-modal.module';
import { SubscriptionTemplatesModule } from '../../subscription-templates/subscription-templates.module';
import { PremiumDescriptionTextModule } from '../description-text/premium-description-text.module';
import { PremiumButtonTextModule } from '../button-text/premium-button-text.module';
import { PriceBlockModule } from '../../price-block/price-block.module';
import { PremiumModalComponent } from './premium-modal.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [PremiumModalComponent],
  imports: [
    PremiumUnsubscribeModalModule,
    PremiumDescriptionTextModule,
    SubscriptionTemplatesModule,
    PremiumButtonTextModule,
    PriceBlockModule,
    TranslateModule,
    CommonModule,
    SwiperModule,
    PipesModule,
  ],
  exports: [PremiumModalComponent],
})
export class PremiumModalModule {}
