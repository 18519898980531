import { BuildVersion, EnvironmentModel } from './environment-model.interface';

export const environment: EnvironmentModel = {
  appId: 'com.apk.getmynudes',
  appName: 'GetMyNudes',
  gameName: 'Get My Nudes',
  production: true,
  apiUrl: 'https://nutakuapi.getmynudes.com',
  buildVersion: BuildVersion.nutaku,
  webSiteUrl: 'https://getmynudes.com',
  discordLink: 'https://discord.gg/3UNBKCX2ZH',
  rootPath: '',
  mode: 'prod',
  showTncs: false,
  allowRegistrationForm: false,
  allowPaymentIframe: false,
  isPromoWidgetChat: false,
  showAdmobAds: false,
  showOnlineWebcamNotification: false,
  splashScreenImgSrc: 'assets/imgx/splash-bg.png',
  firebaseConfig: {
    apiKey: 'AIzaSyCchXDxWly2G8Pf2pyWwU49SxxRo3rZ12A',
    authDomain: 'getmynudes-d4d83.firebaseapp.com',
    projectId: 'getmynudes-d4d83',
    storageBucket: 'getmynudes-d4d83.appspot.com',
    messagingSenderId: '215449707370',
    appId: '1:215449707370:web:1404c575674bc96385bb13',
    measurementId: 'G-3PN0MYE8RD',
  },
  nutakuConfig: {
    parentUrl: 'https://osapi.nutaku.com',
    buyGoldUrl: 'https://www.nutaku.net/members/cart/purchase/gold/',
  },
  policy: {
    name: 'GetMyNudes',
    url: 'https://getmynudes.com/',
    site: 'GetMyNudes.com',
    docsBaseUrl: 'https://info.getmynudes.com/legal/',
    email: 'info@getmynudes.com',
  },
  promoCodeIsAvailable: true,
  banners: [],
  appMetricaKey: '176d7169-8aa4-41da-bc1a-7fa4e9900f11',
};
