import {
  BehaviorSubject,
  Subscription,
  Observable,
  switchMap,
  interval,
  Subject,
  take,
  map,
  of,
} from 'rxjs';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { AiOfflineModalComponent } from '../modals/ai-offline-modal/ai-offline-modal.component';
import { SoftPaymentsService } from 'src/app/shared/services/payments/soft-payments.service';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { PaywallService } from 'src/app/shared/services/paywall.service';
import { SoundsService } from 'src/app/shared/services/sounds.service';
import { ToastsService } from 'src/app/shared/services/toasts.service';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { ShopLot } from 'src/app/shared/types/shop-lot-new.interface';
import { AdmobService } from 'src/app/shared/services/admob.service';
import { ReasonType } from 'src/app/shared/enums/reason-type.enum';
import { SoundsEnum } from 'src/app/shared/enums/sounds.enum';
import { ChatStateService } from './chat-state.service';
import { TranslateService } from '@ngx-translate/core';
import { NavHelper } from 'src/app/shared/helpers';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

export interface OfflineData {
  offlineSkipCost: number;
  offlineSkipTokens: number;
  canSkipOfflineForAd: boolean;
  skipOfflinePackPurchase: ShopLot;
}

@Injectable({ providedIn: 'root' })
export class ChatOfflineService {
  public readonly offlineTimer$ = new BehaviorSubject(0);
  public readonly offlineTimerPremium$ = new BehaviorSubject(0);
  public readonly offlineData$: BehaviorSubject<OfflineData> =
    new BehaviorSubject(null);

  public readonly offlineCanceled = new Subject();

  private _offlineSubscription: Subscription;

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _softPaymentsService: SoftPaymentsService,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
    private _translateService: TranslateService,
    private _chatStateService: ChatStateService,
    private _inventoryService: InventoryService,
    private _paywallService: PaywallService,
    private _toastsService: ToastsService,
    private _modalService: ModalsService,
    private _soundService: SoundsService,
    private _admobService: AdmobService,
    private _navHelper: NavHelper,
    private _platform: Platform,
  ) {}

  public skipOneOffline(skipReason?: ReasonType): Observable<boolean> {
    if (
      this._inventoryService.gems >= this.offlineData$.value.offlineSkipCost ||
      skipReason === ReasonType.adWatched
    ) {
      return this._websocketSignalRService
        .invoke<{ coins: number }>(WebsocketCommandType.skipOffline, {
          chatId: this._chatStateService.chatId,
          skipReason,
        })
        .pipe(
          map((res) => {
            this._inventoryService.setGems(res.coins);

            this._soundService.play(SoundsEnum.bonus2);
            this._analyticsService.skipOfflineModal(
              this._chatStateService.chatId,
              this._chatStateService.modelName$.value,
              this.offlineData$.value.offlineSkipCost,
              skipReason,
            );
            this.cancelOffline();
            return true;
          }),
        );
    } else {
      this._toastsService.showError(
        this._translateService.instant('SHOP_PAGE.NOT_ENOUGH_TOKENS'),
      );
      this._navHelper.goToShopGems();
      return of(false);
    }
  }

  public skipPackOffline(): Observable<boolean> {
    if (
      this._inventoryService.gems <
      this._softPaymentsService.getCost(
        this.offlineData$.value.skipOfflinePackPurchase.softPurchase,
      )
    ) {
      this._toastsService.showError(
        this._translateService.instant('SHOP_PAGE.NOT_ENOUGH_TOKENS'),
      );
      this._navHelper.goToShopGems();
      return of(false);
    }

    return this._softPaymentsService
      .purchase({
        productId: this.offlineData$.value.skipOfflinePackPurchase.productId,
        softPurchase:
          this.offlineData$.value.skipOfflinePackPurchase.softPurchase,
      })
      .pipe(switchMap(() => this.skipOneOffline()));
  }

  public skipAdOffline(): Observable<boolean> {
    return this._admobService.showRewardVideo('skipOffline').pipe(
      switchMap((isSuccess) => {
        if (!isSuccess) {
          return of(false);
        }
        return this.skipOneOffline(ReasonType.adWatched);
      }),
    );
  }

  public setOffline({
    time,
    premiumTime,
    messageIndex,
    offlineSkipCost,
    offlineSkipTokens,
    canSkipOfflineForAd,
    skipOfflinePackPurchase,
  }: {
    time: number;
    premiumTime: number;
    messageIndex: number;
    offlineSkipCost: number;
    offlineSkipTokens: number;
    canSkipOfflineForAd: boolean;
    skipOfflinePackPurchase: ShopLot;
  }) {
    // TODO android review
    if (this._platform.is('cordova')) {
      canSkipOfflineForAd = false;
    }

    this._offlineSubscription?.unsubscribe();

    this.offlineData$.next({
      offlineSkipCost,
      offlineSkipTokens,
      canSkipOfflineForAd,
      skipOfflinePackPurchase,
    });

    this.offlineTimer$.next(time);
    this.offlineTimerPremium$.next(premiumTime);

    this._offlineSubscription = interval(1000)
      .pipe(take(time))
      .subscribe({
        next: () => {
          this.offlineTimer$.next(this.offlineTimer$.value - 1);
          this.offlineTimerPremium$.next(
            Math.max(0, this.offlineTimerPremium$.value - 1),
          );
        },
        complete: () => {
          this.cancelOffline();
        },
      });

    this._analyticsService.chatIsOffline({
      chatId: this._chatStateService.chatId,
      chatName: this._chatStateService.modelName$.value,
      chatType: this._chatStateService.chatType$.value,
      messageIndex,
    });
    this._settingsDataService.updateSettingsData$.value.config.paywallShowOnOffline =
      false;
    if (
      this._settingsDataService.updateSettingsData$.value.config
        .paywallShowOnOffline
    ) {
      this._paywallService.showPaywall('offline').subscribe((result) => {
        if (result === false) {
          this._navHelper.goToAllChats();
        }
      });
    } else {
      this._chatStateService.setState('offline');
    }
  }

  public setAiOffline(messageIndex: number) {
    this._analyticsService.chatIsOffline({
      chatId: this._chatStateService.chatId,
      chatName: this._chatStateService.modelName$.value,
      chatType: this._chatStateService.chatType$.value,
      messageIndex,
    });

    this._modalService.openModal(AiOfflineModalComponent).subscribe();
    this._chatStateService.setState('aiOffline');
  }

  private cancelOffline() {
    this._chatStateService.setState('normal');
    this._offlineSubscription?.unsubscribe();

    this.offlineCanceled.next(null);
  }

  public clear() {
    this.offlineData$.next(null);
    this._offlineSubscription?.unsubscribe();
  }
}
