import { ChatByIdResponse } from 'src/app/shared/types/chat-by-id-response.interface';
import { ChatMessageType } from 'src/app/shared/enums/chat-message-type.enum';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

interface ImageData {
  url: string;
  new: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ChatImagesService {
  public newImagesCount$ = new BehaviorSubject(0);
  public newPhotoEvent = new Subject();

  private readonly _lsPrefix = 'chatImagesData-';
  private _images: ImageData[];
  private _currentChatId: string;

  public initChat(chatData: ChatByIdResponse): void {
    this._currentChatId = chatData.id;
    const existedData = localStorage.getItem(`${this._lsPrefix}${chatData.id}`);

    if (existedData) {
      this._images = JSON.parse(existedData) as ImageData[];
      this.updateNewImagesCount();
      return;
    }

    this._images = chatData.messages
      .filter((m) => m.type === ChatMessageType.modelImage)
      .map((m) => ({ url: m.contentLink, new: false }));

    this.saveToLs();
  }

  public unlockImage(url: string) {
    if (!this._images.find((i) => i.url === url)) {
      this._images.push({ url, new: true });
      this.saveToLs();
      this.updateNewImagesCount();

      this.newPhotoEvent.next(null);
    }
  }

  public addUnlocked(url: string) {
    if (!this._images.find((i) => i.url === url)) {
      this._images.push({ url, new: false });
      this.saveToLs();
    }
  }

  public getNewImages(chatId: string): string[] {
    const existedData = localStorage.getItem(`${this._lsPrefix}${chatId}`);

    if (!existedData) {
      return [];
    }
    const images = JSON.parse(existedData) as ImageData[];

    const newImages = images.filter((i) => i.new).map((i) => i.url);
    images.forEach((i) => (i.new = false));
    localStorage.setItem(`${this._lsPrefix}${chatId}`, JSON.stringify(images));

    return newImages;
  }

  private updateNewImagesCount() {
    this.newImagesCount$.next(this._images.filter((i) => i.new).length);
  }

  private saveToLs() {
    localStorage.setItem(
      `${this._lsPrefix}${this._currentChatId}`,
      JSON.stringify(this._images),
    );
  }
}
