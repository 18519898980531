import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalAnimationsService } from '../animations/modal-animations.service';
import { MaintenanceModalComponent } from '../components/misc-alerts/maintenance-modal/maintenance-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ServerMaintenanceModalService {
  isShowed = false;

  constructor(
    private _modalCtrl: ModalController,
    private _modalAnimationsService: ModalAnimationsService,
  ) {}

  showModal() {
    if (this.isShowed) {
      return;
    }
    this.isShowed = true;
    this._modalCtrl
      .create({
        component: MaintenanceModalComponent,
        componentProps: {},
        showBackdrop: true,
        cssClass: 'transparent-modal',
        // enterAnimation: this._modalAnimationsService.enterAnimation,
        // leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
      .then((modal: HTMLIonModalElement) => {
        modal.onDidDismiss().then(() => {
          this.isShowed = false;
        });
        return modal.present();
      });
  }
}
