@if (isModal) {
  <button (click)="close.emit()" class="close-btn">&times;</button>
}

<div class="template-content">
  <div class="template-head">
    <h1 class="title">PREMIUM</h1>

    <div class="description">
      Full access <br />to all <span>AI Chats!</span><br />with hot🔥photos
    </div>
  </div>

  <div class="content-spacer"></div>

  <div class="swiper-wrapper">
    <img
      src="/assets/img/premium/templates/catgirl/girl.png"
      alt=""
      class="girl"
    />

    <swiper #swiper [config]="swiperConfig" class="subscription-selector">
      @for (subscription of subscriptions; track subscription.id) {
        <ng-template swiperSlide>
          <div class="subscription">
            <div class="inner">
              @if (subscription.type === offerType.yearlySubscription) {
                <div class="badge">BEST VALUE</div>
              }

              <div class="period">
                @switch (subscription.type) {
                  @case (offerType.yearlySubscription) {
                    Yearly
                  }
                  @case (offerType.weeklySubscription) {
                    Weekly
                  }
                  @case (offerType.monthlySubscription) {
                    Monthly
                  }
                }
              </div>

              <app-premium-button-text
                [offerData]="subscription"
                fullTemplate="{cost} then<br/> {nextCost} / {period}"
                shortTemplate="1 {period} / {cost}"
                class="price"
              ></app-premium-button-text>
            </div>
          </div>
        </ng-template>
      }
    </swiper>
  </div>

  <button class="subscribe-btn" (click)="purchase()">Subscribe</button>

  <app-premium-description-text
    [offerData]="selectedSubscription"
    class="subscription-info"
  >
  </app-premium-description-text>

  @if (
    environment.buildVersion === buildVersion.legal ||
    environment.mode === "test"
  ) {
    <div class="paywall-policies">
      <a
        (click)="openTerms.emit()"
        [innerHTML]="'SETTINGS_PAGE.TERMS_OF_USE' | translate"
      ></a>
      <a
        (click)="openPolicy.emit()"
        [innerHTML]="'SETTINGS_PAGE.PRIVACY' | translate"
      ></a>
    </div>
  }
</div>
