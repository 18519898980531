import { SettingsDataService } from '../../services/communication_services/settingsData.service';
import { TutorialService } from '../../services/communication_services/tutorial.service';
import { UiStateService } from '../../services/communication_services/uiStates.service';
import { SplitTestCasesService } from '../../services/split-test-cases.service';
import { ChatStateService } from '../../views/chat/services/chat-state.service';
import { AuthMethodsService } from '../../services/auth/auth-methods.service';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { BonusService } from '../../services/API_services/bonus.service';
import { ChatMessageType } from '../../enums/chat-message-type.enum';
import { InventoryService } from '../../services/inventory.service';
import { UserPreference } from '../../enums/user-preference.enum';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UiStates } from '../../enums/uiStates';
import { NavHelper } from '../../helpers';

const lsKey = 'cheatMenuCollapsed';

@Component({
  selector: 'app-cheat-menu',
  templateUrl: './cheat-menu.component.html',
  styleUrls: ['./cheat-menu.component.scss'],
})
export class CheatMenuComponent implements OnInit {
  public readonly id$ = this._settingsDataService.updateSettingsData$.pipe(
    map((res) => res.id),
  );
  public readonly hasSubscription$ =
    this._settingsDataService.updateSettingsData$.pipe(
      map((res) => res.subscriptionStatus === SubscriptionStatus.active),
    );
  public readonly splitTests$ = this._splitTestService.splitTests$;
  public readonly isTutorialActive$ = this._tutorialService.currentStep$.pipe(
    map((res) => res !== null),
  );
  public inventory: [string, BehaviorSubject<number>][] = [];

  @HostBinding('class.collapsed')
  public collapsed = false;

  public isChatPage = false;
  public isAnalyticsLoggingEnabled = false;

  public userPreference$ = this._settingsDataService.updateSettingsData$.pipe(
    map((data) => data.preference),
  );

  constructor(
    private _settingsDataService: SettingsDataService,
    private _splitTestService: SplitTestCasesService,
    private _authMethodsService: AuthMethodsService,
    private _chatStateService: ChatStateService,
    private _inventoryService: InventoryService,
    private _tutorialService: TutorialService,
    private _uiStateService: UiStateService,
    private _host: ElementRef<HTMLElement>,
    private _bonusService: BonusService,
    private _navHelper: NavHelper,
    private _http: HttpClient,
  ) {
    if (environment.mode === 'prod') {
      _host.nativeElement.remove();
      return;
    }

    if (localStorage.getItem(lsKey) === '1') {
      this.collapsed = true;
    }

    this.isAnalyticsLoggingEnabled =
      localStorage.getItem('analytics_logging') === 'true' ? true : false;

    _uiStateService.updateStateData.subscribe((data) => {
      this.isChatPage = data.state === UiStates.chat;
    });
  }

  public ngOnInit(): void {
    this.inventory = Object.entries(this._inventoryService.items);
    this.inventory.forEach(
      (keyValue) => (keyValue[0] = keyValue[0].slice(0, -1)),
    );
  }

  public toogleCollapse() {
    this.collapsed = !this.collapsed;

    localStorage.setItem(lsKey, this.collapsed ? '1' : '0');
  }

  public wipe() {
    if (confirm('Are you sure?')) {
      this._authMethodsService.wipeOut();
    }
  }

  public setAiChatOffline(enabled: boolean) {
    if (confirm('This action will affect all users. Use carefully')) {
      if (enabled) {
        this._http
          .post(`${environment.apiUrl}/api/Test/ai-test-disable`, {})
          .subscribe();
      } else {
        this._http
          .post(`${environment.apiUrl}/api/Test/ai-test-enable`, {})
          .subscribe();
      }
    }
  }

  public addTokens() {
    this.sendPromocode('!@#givemecoins.').subscribe();
  }

  public getChat() {
    const chatId = prompt('Enter chat id');

    if (!chatId) {
      return;
    }

    this.sendPromocode(`!@#givemechat_${chatId}`).subscribe({
      complete: () => this._navHelper.goToChat(chatId),
      error: () => alert('wrong id'),
    });
  }

  public toggleUserPreferences() {
    const currentPreference =
      this._settingsDataService.updateSettingsData$.value.preference;

    this._settingsDataService
      .updateUserPreference(
        currentPreference === UserPreference.boy
          ? UserPreference.girl
          : UserPreference.boy,
      )
      .pipe(switchMap(() => this.skipTutorialBase()))
      .subscribe(() => location.reload());
  }

  public addSubscription() {
    this.sendPromocode('!@#subs_add_1q2w.').subscribe(() => location.reload());
  }

  public removeSubscription() {
    this.sendPromocode('!@#subs_none_1q2w.').subscribe(() => location.reload());
  }

  public skipTutorial() {
    this.skipTutorialBase().subscribe(() => this._navHelper.goToCards());
  }

  private skipTutorialBase() {
    return this._tutorialService.finishTutorial();
  }

  public copyChatHistory() {
    const history = this._chatStateService.messages$.value
      .filter(
        (m) =>
          m.type === ChatMessageType.hero || m.type === ChatMessageType.model,
      )
      .map(
        (m) =>
          `${m.type === ChatMessageType.hero ? 'Hero' : 'Model'}: ${
            m.messageText ?? m.localizedMessageText.en
          }`,
      );

    try {
      navigator?.clipboard
        ?.writeText(
          JSON.stringify(history)
            .replace(/\",\"/g, '",\n"')
            .replace(/\[\"/g, '[\n"')
            .replace(/\"\]/g, '"\n]'),
        )
        .then(() => alert('Copied'));
    } catch (error) {
      console.error(error);
    }
  }

  public copyId() {
    try {
      navigator?.clipboard
        ?.writeText(this._settingsDataService.updateSettingsData$.value.id)
        .then(() => alert('Copied'));
    } catch (error) {
      console.error(error);
    }
  }

  public toggleAnalyticsLogging() {
    const isAnalyticsLoggingEnabled =
      localStorage.getItem('analytics_logging') === 'true' ? true : false;

    this.isAnalyticsLoggingEnabled = !isAnalyticsLoggingEnabled;

    localStorage.setItem(
      'analytics_logging',
      this.isAnalyticsLoggingEnabled.toString(),
    );
  }

  private sendPromocode(promoCode: string) {
    return this._bonusService.getPromoBonus(promoCode, 0);
  }
}
