<button [disabled]="disabled || pending">
  <div class="btn-content">
    <ng-content></ng-content>
  </div>
  <div *ngIf="pending" class="preloader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</button>
