<div class="success-modal">
  <h3>Сongratulations!</h3>

  <img src="/assets/img/premium/success/crown.png" alt="" class="crown" />

  <div class="bottom-row">
    <p>
      You got Premium access
      <br />
      to all the features
    </p>

    <app-button class="price-text-size-14" (click)="close()">
      Continue
    </app-button>
  </div>
</div>
