<p>Effective Date: [12/19/2023]</p>

<ol>
  <li>
    <h2>INTRODUCTION</h2>
    <p>Welcome to GETHINS LIMITED.</p>

    <p>
      GETHINS LIMITED is a corporate entity existing under the laws of the
      Republic of Cyprus with registration number HE 390472, having its
      registered address at Agiou Andreou 334, 2nd floor, Vashiotis Ag. Andreou
      Business Center, 3035, Limassol, Cyprus (“Gethins”, “us”, “we”, or “our”)
    </p>

    <p>
      We operate
      <a [href]="environment.policy.url" target="_blank">
        {{ environment.policy.url }}</a
      >
      (the “<b>Service</b>” or “<b>{{ environment.policy.name }}</b
      >” or “<b>Website</b>”) available in the form of either a website or an
      application.
    </p>
    <p>
      Our Privacy Policy governs your visit to {{ environment.policy.name }},
      and explains how we collect, safeguard and disclose information that
      results from your use of our Service.
    </p>
    <p>
      We use your data to provide and improve the Service. By using the Service,
      you agree to the collection and use of information in accordance with this
      policy. Unless otherwise defined in this Privacy Policy, the terms used in
      this Privacy Policy have the same meanings as in our
      <a [href]="environment.policy.docsBaseUrl + 'termofuse/'" target="_blank"
        >Terms and Conditions</a
      >.
    </p>
    <p>
      Our Terms and Conditions (the “Terms”) govern all use of our Service and
      together with the Privacy Policy constitutes your agreement with us (the
      “Agreement”).
    </p>
  </li>

  <li>
    <h2>DEFINITIONS</h2>
    <ol>
      <li>
        Cookies are small files stored on your device (computer or mobile
        device).
      </li>
      <li>
        Data controller means a natural or legal person who (either alone or
        jointly or in common with other persons) determines the purposes for
        which and the manner in which any personal data are, or are to be,
        processed. For the purpose of this privacy policy, we are a data
        controller of your data.
      </li>
      <li>
        GDPR means the European Regulation (EU) 2016/679 of the European
        Parliament and of the Council on the protection of natural persons with
        regard to the processing of personal data and on the free movement of
        such data, and repealing Directive 95/46/EC, as amended or may be
        amended from time to time,
      </li>
      <li>Personal Data means any data about a Data Subject.</li>
      <li>
        Data Processors (or Service Providers) mean any natural or legal person
        who processes the data on behalf of the data controller. we may use the
        services of various service providers in order to process your data more
        effectively.
      </li>
      <li>
        Data Subject means an identified or identifiable natural person who is
        the subject of personal data.
      </li>
      <li>
        User is the individual using our Service under the categories described
        in Section 3 below. The User corresponds to the Data Subject, who is the
        subject of Personal Data.
      </li>
      <li>
        Usage Data is data collected automatically either generated by the use
        of Service or from Service infrastructure itself (for example, the
        duration of a page visit).
      </li>
    </ol>
  </li>

  <li>
    <h2>USERS</h2>
    <ol>
      <li>
        <div>
          The Website will be used by the following categories of users:

          <ul class="letters-list">
            <li>
              Category 1: a visitor of the Website who does not create an
              account on the Website but can use limited features of the Service
              though a trial account. Upon reaching the usage limits, the user
              under this category will be requested to subscribe to continue
              using the Service.
            </li>
            <li>
              Category 2: a user of the Website who creates an account and can
              upload any Content.
            </li>
          </ul>
        </div>
      </li>

      <li>
        <p>
          Subject to our
          <a
            [href]="environment.policy.docsBaseUrl + 'termofuse/'"
            target="_blank"
            >Terms and Conditions</a
          >
          and as explained in the privacy notice at
          <a
            [href]="environment.policy.docsBaseUrl + 'privacynotice'"
            target="_blank"
            >{{ environment.policy.docsBaseUrl + "privacynotice/" }}</a
          >, we do not process personal data of the Category 1 users (i.e., the
          visitors of the Website who have not created an account). Instead, in
          such instances, we use cookies for the purposes identified in our
          cookie policy, which is available at
          <a
            [href]="environment.policy.docsBaseUrl + 'cookiepolicy'"
            target="_blank"
            >{{ environment.policy.docsBaseUrl + "cookiepolicy/" }}</a
          >. For more information, including on how to manage your preferences
          on, and disable, cookies, please refer to our cookie policy, which is
          available at
          <a
            [href]="environment.policy.docsBaseUrl + 'cookiepolicy'"
            target="_blank"
            >{{ environment.policy.docsBaseUrl + "cookiepolicy/" }}</a
          >.
        </p>
      </li>

      <li>
        For the individuals who fall within the remaining Category 2, personal
        data is processed in line with this privacy policy.This privacy policy
        describes how we collect, use, process, and disclose information,
        including Personal Data, for Users falling under Category 2. It is
        applicable in conjunction with your access to and use of the Website. We
        process personal data only in accordance with this privacy policy.
      </li>
      <li>
        This privacy policy describes how we collect, use, process, and disclose
        information, including Personal Data, for Users falling under Category
        2. It is applicable in conjunction with your access to and use of the
        Website. We process personal data only in accordance with this privacy
        policy.
      </li>
    </ol>
  </li>

  <li>
    <h2>ACKNOWLEDGMENT</h2>
    <ol>
      <li>
        By accessing the Website, you acknowledge that you have read this
        privacy policy and understand that your Personal Data, including data
        that may reflect or concern your sex life, sexual preferences, and
        sexual orientation, may be processed as explained below.
      </li>
      <li>
        You also acknowledge that, if you do not grant us with your consent to
        process your Personal Data in accordance with the terms of this Privacy
        Policy, we may be unable to comply with our contractual obligations,
        offer you the entire range of products and services available on the
        Website, and allow you to create an account on this Website.
      </li>
      <li>
        By accessing this Website and creating an account, you consent to
        GETHINS processing your Personal Data, including data that may reflect
        or concern your sex life, sexual preferences, and sexual orientation.
        You grant your consent based on the understanding that GETHINS will rely
        on this basis only when necessary and proportionate to carry out its
        contractual obligations, comply with its legal obligations and protect
        its legitimate business aims.
      </li>
    </ol>
  </li>

  <li>
    <h2>PERSONAL DATA COLLECTION</h2>
    <p>
      We collect different types of Personal Data for various purposes to
      provide and improve our Service to you.
    </p>

    <ol>
      <li>
        <div>
          <b>Types of Personal Data we collect</b>
          <ol>
            <li>
              <div>
                While using our Service (including but not limited to watching a
                video, making an inquiry or application for our services,
                registering to use and/or using any of our services and when you
                communicating with us through email, the Website or portal, or
                any other electronic means), we may request you to provide us
                with certain information and Personal Data, including but not
                limited to:

                <ol>
                  <li>
                    your full name including first name and family name, gender,
                    date of birth, email address, billing address, username,
                    password, photograph, nationality and country of residence;
                  </li>
                  <li>other Personal Data (such sexual orientation);</li>
                  <li>Cookies and Usage Data.</li>
                </ol>
              </div>
            </li>
            <p>
              We do not act as a joint controller of Personal Data belonging to
              any third parties (other than yourself) that is available or
              contained in any of the forms submitted, or otherwise provided to
              us by you (the “<b>Third Party Personal Data</b>”), and we hereby
              disclaim any and all liability in connection with the Third Party
              Personal Data.
            </p>
            <li>
              <div>
                We collect Personal Data about the services that you use and how
                you use them, such as when you watch a video on the Website,
                visit a website that uses our advertising services or view or
                interact with our ads and content. This includes:

                <ul>
                  <li>
                    <p>
                      <b>Device information.</b> We collect device specific
                      information such as IP address, access dates and times,
                      hardware and software information, device information and
                      cookie data.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Geo-location Information.</b> When you use certain
                      features of the Website, we may collect information about
                      your approximate location as determined through data such
                      as your IP address.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Usage Information.</b> We collect information about
                      your interactions with the Website such as the content you
                      view, your searches queries and other interactions with
                      the Website.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Cookies and Similar Technologies.</b> We use cookies
                      and other similar technologies, such as web beacons,
                      pixels, and mobile identifiers, to track the activity of
                      our Service. For more information on our use of these
                      technologies, see our Cookie Policy, available at
                      <a
                        [href]="environment.policy.docsBaseUrl + 'cookiepolicy'"
                        target="_blank"
                        >{{
                          environment.policy.docsBaseUrl + "cookiepolicy"
                        }}</a
                      >. You can instruct your browser to refuse all cookies or
                      to indicate when a cookie is being sent. However, if you
                      do not accept cookies, you may not be able to use some
                      portions of our Service.
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              We may collect information, including Personal Data, that others
              provide about you when they use the Website, or obtain information
              from other sources and combine it with information we collect
              through the Website. We do not control or supervise, and
              therefore, we are not responsible for how the third parties
              providing your information process your Personal Data. Any
              inquiries regarding the disclosure of your personal information to
              us should be directed to such third parties.
            </li>
            <li>
              To the extent permitted by the applicable law, we may receive
              additional information about you, such as demographic data or
              fraud detection information and combine it with the information we
              already have about you.
            </li>
          </ol>
        </div>
      </li>

      <li>
        Please note that this Website may include links to third-party websites,
        plug-ins and applications. Clicking on those links or enabling those
        connections may allow third parties to collect or share Personal Data
        about you. We do not control or supervise these third-party websites and
        therefore, we are not responsible for their privacy statements. When you
        leave our Website, we encourage you to read the privacy policy of every
        site you visit.
      </li>
      <li>
        It is also important that the Personal Data we hold about you is
        accurate and up-to-date. Please keep us informed if your Personal Data
        changes during your relationship with us.
      </li>
    </ol>
  </li>

  <li>
    <h2>HOW WE USE PERSONAL DATA WE COLLECT</h2>
    <p>
      We use, store, and process information, including Personal Data, to
      provide, understand, improve, and develop the Website and/or the Service,
      to protect us and our users and to comply with our legal obligations.
    </p>
    <p>
      We also use this information to offer you tailored content – like giving
      you more relevant search results and ads.
    </p>
    <p>
      We collect your Personal Data for various purposes based on the below
      legal grounds:
    </p>
    <ol>
      <li>
        <div>
          <b>Provide, Improve, and Develop the Website.</b>
          <ul>
            <li>Enable you to access and use the Website.</li>
            <li>Enable you to communicate with other users.</li>
            <li>
              Operate, protect, improve, and optimize the Website, such as by
              performing analytics and conducting research.
            </li>
            <li>Provide customer service.</li>
            <li>
              Send you service or support messages, updates, security alerts,
              and account notifications.
            </li>
            <li>
              To operate, protect, improve, and optimize the Website, and
              personalize and customize your experience (such as making
              suggestions or ranking search results), we conduct profiling based
              on your interactions with the Website, your search and viewing
              history, your profile information and preferences, and other
              content you submit to the Website.
            </li>
          </ul>
        </div>
      </li>
      <div>
        <p>
          <b>Lawful bases</b>: we rely on the following three lawful bases to
          process your Personal Data for the specific purpose of providing,
          improving and developing the Website as described above:
        </p>
        <ul class="numbers-list">
          <li>
            we process this Personal Data in order to adequately perform the
            contract with you;
          </li>
          <li>
            our legitimate business interests in processing your Personal Data,
            specifically for the purpose of improving the Website and our users’
            experience with it, which we have determined can only be achieved by
            processing your Personal Data. We consider the processing of your
            Personal Data to be proportionate with your interests, rights and
            freedoms;
          </li>
          <li>
            to the extent necessary to perform our contract with you and
            customize your experience, we seek your consent to process special
            categories of data.
          </li>
        </ul>
      </div>
      <li>
        <div>
          <b>
            Protect us and our Users and comply with our legal obligations
          </b>
          <ul>
            <li>
              Detect and prevent fraud, spam, abuse, security incidents, and
              other harmful activity.
            </li>
            <li>
              Conduct security investigations and risk assessments, including
              any reports third parties may file about you or the content you
              have uploaded pursuant to the Terms of Service.
            </li>
            <li>
              Comply with our legal obligations, including assistance in the
              prevention of crimes, including child sexual abuse material or
              non-consensual sexual acts.
            </li>
            <li>
              Resolve any disputes with any of our users and enforce our
              agreements with third parties.
            </li>
            <li>
              Communicate with you regarding any abuse reports (if you do not
              submit them anonymously), or any copyright infringement takedown
              requests or counter notifications (which cannot be submitted
              anonymously) that you may file pursuant to the Terms of Service.
            </li>
            <li>Enforce our Terms and Conditions and other policies.</li>
          </ul>
        </div>
      </li>
      <div>
        <p>
          <b>Lawful bases</b>: we rely on the following three lawful basis to
          process your Personal Data for this specific purpose:
        </p>
        <ul class="numbers-list">
          <li>
            legal obligations to which we are subject, which may include
            disclosing your Personal Data to a third party or competent national
            authorities.
          </li>
          <li>
            consent to process certain special categories of personal data is
            sought to the extent necessary to achieve the purposes set out
            above. This includes transferring limited categories of your
            Personal Data to third parties against whom you have filed a
            copyright infringement takedown request under the Digital Millennium
            Copyright Act (DMCA) or to whom you are responding with a copyright
            counter-notification, in accordance with our Terms and Conditions
            and as further described in this Privacy Policy.
          </li>
          <li>
            legitimate interests in protecting our business, which includes
            protecting the Website and our reputation as a law-abiding business
            to the extent appropriate. We consider the processing of your
            Personal Data to be proportionate with your interests, rights and
            freedoms.
          </li>
        </ul>

        <ol>
          <li>
            <div>
              <b
                >Personal Data processed when filing a takedown request or a
                counter-notification pursuant to paragraph 11 of our Terms and
                Conditions</b
              >
              <p>
                Although our Company is based outside the United States, we
                respect the rights of copyright owners and thus have implemented
                certain policies in an effort to voluntarily comply with laws,
                such as the Digital Millennium Copyright Act (the "DMCA").
              </p>
              <br />
              <b>What is the Digital Millennium Copyright Act?</b>
              <p>
                The takedown process provided by the DMCA is a tool for
                copyright holders to have user-uploaded material that infringes
                their copyrights taken down from our website. The process
                entails the copyright owner (or the owner’s agent) sending a
                copyright infringement takedown request to a service provider
                requesting that the provider remove material that the copyright
                owner contends is infringing their copyright(s). Once a website
                is in receipt of the takedown request, it is required to send
                the takedown request to the uploader of the material in
                question. The uploader is subsequently given a chance to respond
                to the takedown request . The mechanism serves as an initial
                step for the copyright holder and the uploader to resolve any
                dispute relating to the copyrighted material at issue. For that
                purpose, NKL is required to process and transfer limited
                personal data between the individuals submitting a takedown
                request (i.e., the alleged copyright holder) and the uploader.
              </p>
              <br />
              <p>
                In compliance with the DMCA, we only accept copyright
                infringement takedown notices from content owners or someone
                officially authorized to act on their behalf. To read more about
                the requirements of a complete notice, we invite you to visit
                <a
                  href="https://www.dmca.com/faq/What-is-a-DMCA-Takedown"
                  target="_blank"
                  >https://www.dmca.com/faq/What-is-a-DMCA-Takedown</a
                >, and consult with your own counsel. (We do not and cannot
                provide you with legal advice, and none should be implied from
                this Privacy Notice or anything else on our site.) Information
                provided for purposes of copyright takedown requests /
                counter-notifications will be forwarded to the uploader of the
                material at issue.
              </p>
              <br />
              <b
                >What lawful basis do we rely upon to process your Personal Data
                when you file a copyright infringement takedown request /
                counter-notification pursuant to the DMCA?</b
              >
              <p>
                For the specific purpose of our review of your copyright
                infringement takedown request / counter-notification which you
                may have filed pursuant to paragraph 11 of our Terms and
                Conditions, you are required to provide the information needed
                to make a valid request, including but not limited to:
                <br />
                (a) takedown website URL and
                <br />
                (b) statement regarding the ownership of this content.
              </p>
              <br />
              <p>
                We will process your Personal Data in order to further our
                legitimate interests as an entertainment business that respects
                intellectual property rights, abide by the law and acts
                appropriately when reports of copyright infringements are made.
                For those legitimate purposes, we process your personal data we
                deem necessary in order to be able to review the takedown
                request / counter-notification that you may have submitted
                pursuant to paragraph 6C of the Terms of Service and to allow us
                to contact you about its content. We consider the processing of
                your personal data to be proportionate with your interests,
                rights and freedoms.
              </p>
              <br />
              <p>
                In line with the mechanism provided for under the DMCA,
                explained above, we will also share with the takedown request
                claimant or the counter-notification claimant the following
                personal data to comply with and follow the prescribed steps
                under the DMCA: your name, your contact information, as well as
                the contents of your takedown request or your
                counter-notification. We rely on your consent to transmit your
                personal data to the addressees of your takedown request or
                counter-notifications. By submitting a copyright infringement
                takedown request, you consent to your data being transmitted to
                the uploader of the material in question in line with and for
                the reasons explained above and detailed in our Terms of
                Service. Without your consent, we are unable to process your
                takedown request or counter-notifications as prescribed under
                the DMCA.
              </p>
            </div>
          </li>
        </ol>
      </div>

      <li>
        <div>
          <b
            >Provide, Personalize, Measure, and Improve our Advertising and
            Marketing.</b
          >
          <ul>
            <li>
              Send you promotional messages, marketing, advertising, and other
              information that may be of interest to you based on your
              preferences (including information about Website).
            </li>
            <li>
              Personalize, measure, and improve our advertising based on your
              characteristics and preferences (based on the information you
              provide to us, your interactions with the Website, information
              obtained from third parties, and your search and booking history)
              to send you promotional messages, marketing, advertising and other
              information that we think may be of interest to you.
            </li>
          </ul>
          <p>
            Lawful bases: we rely on the following two lawful bases to process
            your Personal Data for this specific purpose:
          </p>
          <ul class="numbers-list">
            <li>
              We process your Personal Data for the purposes listed in this
              section given our legitimate interest in undertaking marketing
              activities to offer you products or services that may be of your
              interest. We consider the processing of your Personal Data to be
              proportionate with your interests, rights and freedoms.
            </li>
            <li>
              To the extent necessary, we will also rely on your consent to
              process special categories of Data in undertaking marketing
              activities and personalising the content on the Website.
            </li>
          </ul>
          <p>
            You can opt-out of receiving marketing communications from us by
            following the unsubscribe instructions included in our marketing
            communications or changing your notification settings within your
            account.
          </p>
        </div>
      </li>
      <li>
        <div>
          <b
            >your direct contact with us through our Website or by post, phone,
            email or otherwise</b
          >
          <ul>
            <li>
              Respond to your queries regarding the Service or to your
              complaints.
            </li>
            <li>Provide you with further information on privacy.</li>
            <li>Any other queries you may have for which you contact us.</li>
          </ul>
          <p>
            Lawful bases: when you contact us directly through our Website or by
            post, phone, email or otherwise, including for the purposes of
            exercising your rights under the GDPR or to file any complaints, we
            will rely on the following two bases to process your Personal Data:
          </p>
          <ul class="numbers-list">
            <li>
              We rely on our legitimate interests as a customer-friendly
              entertainment business that wants to improve the experience of the
              customers using our Website, to resolve satisfactorily any
              complaints made by our customers, or to respond to any requests
              for further information. We will process your personal data we
              deem necessary for these legitimate interests. Additionally, in
              rare cases where we reasonably determine that your direct
              communications with us or our staff are or become abusive, amount
              to harassment or otherwise illegal under the applicable laws, we
              may also process your personal data for these purposes and pass it
              onto the relevant governmental authorities. We consider the
              processing of your Personal Data to be proportionate with your
              interests, rights and freedoms.
            </li>
            <li>
              We may also need to rely on our legal obligations to process your
              Personal Data in cases where the applicable law compels us to
              communicate your Personal Data exchanged in direct communications
              with us to the responsible governmental authorities.
            </li>
          </ul>
        </div>
      </li>
    </ol>
  </li>

  <li>
    <h2>YOUR RIGHTS UNDER GDPR</h2>
    <p>
      In accordance with the GDPR you have specific rights related to your
      Personal Data.
    </p>
    <p>
      You may exercise any of the rights described in this section by adjusting
      settings in your user account and, if it is not possible, you can contact
      us at
      <a [href]="'mailto:' + environment.policy.email">{{
        environment.policy.email
      }}</a
      >.
    </p>
    <p>
      Please note that we will ask you to verify your identity before taking
      further action on your request, and if we cannot identify you we cannot
      act according to your request.
    </p>
    <p>You have the following data protection rights:</p>
    <ol>
      <li>
        <div>
          <b>Managing Your Information.</b>
          <p>
            You may access and update the Personal Data we hold about you. You
            are responsible for keeping your Personal Data up-to-date in a
            secure place.
          </p>
        </div>
      </li>
      <li>
        <div>
          <b>Rectification of Inaccurate or Incomplete Information.</b>
          <p>
            You have the right to ask us to correct inaccurate or incomplete
            personal information concerning you (and which you cannot update
            yourself within your account) by contacting us at the address listed
            in the Contact Us section below.
          </p>
        </div>
      </li>
      <li>
        <div>
          <b>Withdrawing Consent and Restriction of Processing.</b>
          <p>
            Where you have provided your consent to the processing of your
            Personal Data by us, you may withdraw your consent at any time by
            changing your account settings. The withdrawal of your consent does
            not affect the lawfulness of any processing activities based on such
            consent before its withdrawal.
            <br />Additionally, you have the right to limit the ways in which we
            use your Personal Data, in particular where (i) you contest the
            accuracy of your Personal Data; (ii) the processing is unlawful and
            you oppose the erasure of your Personal Data; (iii) we no longer
            need your Personal Data for the purposes of the processing, but you
            require the information for the establishment, exercise or defence
            of legal claims; or (iv) you have objected to the processing and
            pending the verification whether the legitimate grounds of the
            Website provider override your own.
          </p>
        </div>
      </li>
      <li>
        <div>
          <b>Objection to Processing.</b>
          <p>
            You have a right to require us not to process your Personal Data for
            certain specific purposes (including profiling) where such
            processing is based on legitimate interest. If you object to such
            processing we will no longer process your Personal Data for these
            purposes unless we can demonstrate compelling legitimate grounds for
            such processing or such processing is required for the
            establishment, exercise or defence of legal claims.
            <br />Where your sensitive Personal Data are processed you may, at
            any time ask us to cease processing your data by changing your
            user’s profile settings.
          </p>
        </div>
      </li>
      <li>
        <div>
          <b>Lodging Complaints.</b>
          <p>
            We take privacy concerns seriously. If you believe that we have not
            complied with this Privacy Policy with respect to your Personal
            Data, you may contact our respective Data Protection Officer at
            <a [href]="'mailto:' + environment.policy.email">{{
              environment.policy.email
            }}</a
            >. We will investigate your complaint promptly and will reply to you
            within 30 (thirty) calendar days.
            <br />
            If you do not receive acknowledgment of your complaint or your
            complaint is not satisfactorily addressed, you have the right to
            lodge a complaint at any time to Cyprus supervisory authority, the
            Office of the Commissioner for Personal Data.
            <a
              href="https://www.dataprotection.gov.cy/dataprotection/dataprotection.nsf/page1i_gr/page1i_gr?opendocument"
              target="_blank"
              >https://www.dataprotection.gov.cy/dataprotection/dataprotection.nsf/page1i_gr/page1i_gr?opendocument</a
            >
            We would appreciate the chance to deal with your concerns and
            address your queries before you approach the supervisory authority.
            <br />
            <br />
            While we recognize that the rights of Data Subjects under GDPR are
            fundamental, there are certain circumstances where these rights
            might be subject to exceptions or limitations. Any exceptions or
            limitations to these rights are either defined by applicable laws or
            are necessary, proportionate and respect the essence of fundamental
            rights and freedoms of the Data Subject.
          </p>
        </div>
      </li>
    </ol>
  </li>

  <li>
    <h2>CHILDREN’S PRIVACY</h2>
    <ol>
      <li>
        Some of our Services are not intended for use by children under the age
        of
        @if (isIos && environment.buildVersion === buildVersion.legal) {
          17
        } @else if (
          isAndroid && environment.buildVersion === buildVersion.legal
        ) {
          16
        } @else {
          18
        }
        or the age of majority in their jurisdiction (the “Child” or
        “Children”).
      </li>
      <li>
        We do not knowingly solicit or collect Personal Data from Children.
      </li>
      <li>
        If you are under the age specified above, please refrain from attempting
        to register on our Website or provide us with any information about
        yourself.
      </li>
      <li>
        If you become aware that a Child has provided us with Personal Data or
        that we have collected Personal Data from Children without verification
        of parental consent, please inform us immediately to take steps to
        remove that information from our records.
      </li>
      <li>
        We will take all necessary steps to remove and permanently delete from
        our records any Personal Data provided to us about a minor and promptly
        suspend any account created by a minor. Additionally, any account
        created by an adult that contains Content depicting minors will be
        immediately and permanently suspended without prior notification and
        without eligibility for a refund. We also reserve the right to report
        such incidents to the competent authorities for further investigation.
      </li>
    </ol>
  </li>

  <li>
    <h2>PERSONAL DATA TRANSFER OUTSIDE THE EEA</h2>
    <p>
      Where we transfer, store, and process your personal data outside of the
      EEA, we have ensured that appropriate safeguards are in place to ensure an
      adequate level of data protection. We rely on EU model clauses and
      adequacy decisions issued by competent European authorities. If we are
      unable to rely on these two bases, we will transfer data outside the EEA
      to the extent doing so is: (a) necessary for the performance of a contract
      between you and us; (b) necessary for the conclusion or performance of a
      contract concluded for your interest between us and another natural or
      legal person; (c) necessary for the establishment, exercise, or defense of
      legal claims; or (d) any other bases listed in Article 49 of the GDPR if
      applicable, such as seeking your consent.
    </p>
    <p>
      We share certain data within our corporate group of companies and with the
      following groups of recipients to the extent we deem it necessary to be
      able to perform our operations or are compelled to do by law:
    </p>
    <ul>
      <li>Government authorities;</li>
      <li>Third party service providers;</li>
      <li>Financial institutions;</li>
      <li>Providers of storage systems;</li>
      <li>IT services providers;</li>
      <li>
        Providers of services necessary for the performance of our activity
        (administrative activities, archiving, legal advice, receivables
        management, etc.).
      </li>
    </ul>
  </li>

  <li>
    <h2>COOKIES POLICY AND GOOGLE ANALYTICS</h2>
    <p>
      Our Website uses session cookies and persistent cookies. To learn more
      about cookies, please visit our cookies policy, available at
      <a
        [href]="environment.policy.docsBaseUrl + 'cookiepolicy'"
        target="_blank"
        >{{ environment.policy.docsBaseUrl + "cookiepolicy" }}</a
      >, which explains how cookies works, what data we collect and how you can
      manage your preferences.
    </p>
  </li>

  <li>
    <h2>SECURITY</h2>
    <p>
      Gethins keeps all the data it processes in a secure environment. We
      protect Personal Data by using appropriate safeguard procedures and
      measures, in particular:
    </p>
    <ul>
      <li>
        <div>
          We encrypt our services using SSL (you can reach our
          <a
            href="https://www.google.com/url?q=https://support.google.com/websearch/answer/173733?hl%3Den-GB&sa=D&source=editors&ust=1635513749138000&usg=AOvVaw1BbkNds8npgwUyhoGEswQ-"
            target="_blank"
            >SSL</a
          >
          protected website under
          <a [href]="environment.policy.url" target="_blank">
            {{ environment.policy.url }}</a
          >).
        </div>
      </li>
      <li>We use firewalls to protect your Personal Data.</li>
      <li>
        We review our information collection, storage and processing practices
        regularly, including physical security measures, to guard against
        unauthorized access to systems.
      </li>
      <li>
        We restrict access to Personal Data to employees, contractors and agents
        who need to know that information in order to process it for us and who
        are subject to strict contractual or other legal confidentiality
        obligations.
      </li>
    </ul>

    <p>
      While we strive to use commercially acceptable means to protect your
      Personal Data, we cannot guarantee its absolute security.
    </p>
    <p>
      If you know or have reason to believe that your account credentials or
      your Personal Data have been lost, stolen, misappropriated, or otherwise
      compromised or in case of any actual or suspected unauthorized use of your
      account, please contact us immediately by email
      <a [href]="'mailto:' + environment.policy.email">{{
        environment.policy.email
      }}</a
      >.
    </p>
  </li>

  <li>
    <h2>RETENTION OF PERSONAL DATA</h2>
    <ol>
      <li>
        We will retain your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </li>
      <li>
        We will also retain Usage Data for internal analysis purposes. Usage
        Data is generally retained for a shorter period, except when this data
        is used to strengthen the security or to improve the functionality of
        our Service, or we are legally obligated to retain this data for longer
        time periods.
      </li>
      <li>
        To determine the appropriate retention period for Personal Data, we
        consider factors such as the quantity, nature and sensitivity of the
        Personal Data, the potential risk of harm from unauthorized use or
        disclosure, the purposes of processing, the feasibility of achieving
        those purposes through alternative means and compliance with applicable
        legal requirements.
      </li>
      <li>
        When the need to process your Personal Data for purposes outlined in
        this Privacy Policy, ceases, we will permanently delete your Personal
        Data from our systems. However, if we are unable to delete it due to
        legal, regulatory or technical reasons, we may retain your Personal Data
        for an extended period. In such cases, we ensure that your privacy is
        protected and that your Personal Data is solely processed for the
        specified purposes.
      </li>
    </ol>
  </li>

  <li>
    <h2>LINKS TO OTHER SITES</h2>
    <p>
      Our Service may contain links to other sites that are not operated by us.
      If you click a third party link, you will be directed to that third
      party’s site. We strongly advise you to review the Privacy Policy of every
      site you visit. We have no control over and assume no responsibility for
      the content, privacy policies or practices of any third party sites or
      services.
    </p>
  </li>

  <li>
    <h2>CHANGES TO THIS PRIVACY POLICY</h2>
    <p>
      We reserve the right to modify this Privacy Policy at any time and without
      prior notification to you. If we make changes to this Privacy Policy, we
      will post the revised Privacy Policy on the Website and update the “Last
      Updated” date at the top of this Privacy Policy. If you disagree with the
      revised privacy policy, you may cancel your account. If you do not cancel
      your account before the date the revised privacy policy becomes effective,
      your continued access to or use of the Website will be subject to the
      revised privacy policy.
    </p>
  </li>

  <li>
    <h2>LAW AND HARM</h2>
    <p>
      Notwithstanding anything to the contrary in this Privacy Policy, we may
      preserve or disclose your Personal Data if we believe it is reasonably
      necessary to comply with a law, regulation, legal process, or governmental
      request; to protect the safety of any person; to address fraud, security
      or technical issues; or to protect our or our Users’ rights or property.
      This Privacy does not intend to limit any legal defenses or objections you
      may have to a government’s request for disclosure of your Personal Data.
    </p>
  </li>

  <li>
    <h2>NO ERROR FREE PERFORMANCE</h2>
    <p>
      We do not guarantee error-free performance under this privacy policy. We
      will use reasonable efforts to comply with this privacy policy and will
      take prompt corrective action when we learn of any failure to comply with
      our privacy policy. We shall not be liable for any incidental,
      consequential or punitive damages relating to this Privacy Policy.
    </p>
  </li>

  <li>
    <h2>CONTACT US</h2>
    <p>
      You may contact us at<br />
      GETHINS LIMITED Cyprus a.s. Att. of Data Privacy Officer<br />
      Postal address: Agiou Andreou 334, 2nd floor, Vashiotis Ag. Andreou
      Business Center, 3035, Limassol, Cyprus<br />
      Email address:
      <a [href]="'mailto:' + environment.policy.email">{{
        environment.policy.email
      }}</a>
    </p>
  </li>
</ol>
