import { GenderSelectModalComponent } from '../../components/gender-select-modal/gender-select-modal.component';
import { ChatGiftsService } from '../../views/chat/services/gifts/chat-gifts.service';
import { SettingsDataService } from '../communication_services/settingsData.service';
import { DailyQuestsService } from '../../views/daily-quests/daily-quests.service';
import { ShopTokensService } from '../../views/shop-tokens/shop-tokens.service';
import { ShopOffersService } from '../../views/shop-offers/shop-offers.service';
import { ShopItemsService } from '../../views/shop-items/shop-items.service';
import { JWTTokenService } from '../communication_services/JWTToken.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthErogamesService } from './auth-erogames.service.ts.service';
import { UserPreference } from '../../enums/user-preference.enum';
import { GlobalRatingService } from '../global-rating.service';
import { BonusService } from '../API_services/bonus.service';
import { ChatsService } from '../API_services/chats.service';
import { AuthMethodsService } from './auth-methods.service';
import { AuthPayload } from '../../enums/auth-payload.enum';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';
import { AuthNutakuService } from './auth-nutaku.service';
import { AuthStateService } from './auth-state.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { NavHelper } from '../../helpers/nav.helper';
import { PaywallService } from '../paywall.service';
import { ModalsService } from '../modals.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isPremiumModalShown = false;
  private _isServicesInitialized = false;

  constructor(
    private _authErogamesService: AuthErogamesService,
    private _settingsDataService: SettingsDataService,
    private _authMethodsService: AuthMethodsService,
    private _authNutakuService: AuthNutakuService,
    private _authStateService: AuthStateService,
    private _paywallService: PaywallService,
    private _tokenService: JWTTokenService,
    private _modalsService: ModalsService,
    private _navHelper: NavHelper,
    //preload
    private _dailyQuestsService: DailyQuestsService,
    private _shopBoostersService: ShopItemsService,
    private _shopTokensService: ShopTokensService,
    private _shopPacksService: ShopOffersService,
    private _ratingService: GlobalRatingService,
    private _chatGiftsService: ChatGiftsService,
    private _bonusService: BonusService,
    private _chatService: ChatsService,
  ) {
    if (environment.buildVersion === BuildVersion.erogames) {
      this._tokenService.erogamesToken = null;
    }
  }

  ///////////////

  private _changeRegistrationVisibility(isShow: boolean): void {
    console.log('change reg', isShow);
    if (environment.allowRegistrationForm) {
      console.log('allow');
      if (isShow) {
        console.log('is Show');
        this._authStateService.showRegistrationForm({
          isShow: true,
          data: {
            placeWereCalled: 'Auth At Start',
            enableClosing: false,
          },
        });
      } else {
        this._authStateService.hideRegistrationForm(false);
      }
    }
  }

  private _isAuthorisedByBuildVersion(): boolean {
    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        return !!this._tokenService.erogamesToken;
      case BuildVersion.legal:
        // LEGAL TEST HACK @Todo
        return !!(
          this._tokenService.getUserEmail() &&
          !this._tokenService.isTokenExpired()
        );
      // return true;
      case BuildVersion.nutaku:
        return false;
      case BuildVersion.default:
        return !!(
          this._tokenService.getUserEmail() &&
          !this._tokenService.isTokenExpired()
        );
    }
  }

  private _showRegform() {
    // alert('show reg form');
    this._navHelper.goToLaunch();
    console.log(
      '!dd',
      !environment.showTncs || localStorage.getItem('read_tncs'),
    );
    if (!environment.showTncs || localStorage.getItem('read_tncs')) {
      console.log('change reg');
      this._changeRegistrationVisibility(true);
    }
  }

  private _tryAsGuestABTestOrShowRegform() {
    const uspidntfr = localStorage.getItem(AuthPayload.uspidntfr);
    const usptoken = localStorage.getItem(AuthPayload.usptoken);

    // Check if it's switching from WEB to APK
    const authMethod =
      uspidntfr && usptoken
        ? this._authMethodsService.signBySwitchTokenApk()
        : this._authMethodsService.trySignAsGuest();

    authMethod
      .pipe(
        catchError(() => {
          this._showRegform();
          return of(null);
        }),
      )
      .subscribe();
  }

  private authenticationProcess(): Observable<boolean> {
    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        this._authErogamesService.erogamesTokenUpdateOnRefresh();
        break;
      case BuildVersion.legal:
        // LEGAL TEST HACK @Todo
        // this._showRegform();
        this._tryAsGuestABTestOrShowRegform();
        break;
      case BuildVersion.nutaku:
        this._authNutakuService.auth();
        // this._tryAsGuestABTestOrShowRegform();
        break;
      case BuildVersion.default:
        this._tryAsGuestABTestOrShowRegform();
        break;
    }

    return this._authStateService.authStatus$.pipe(
      filter((res) => res === AuthStatus.authenticated),
      map(() => true),
      take(1),
    );
  }

  authorise(): Observable<any> {
    return this._authStateService.authStatus$.pipe(
      filter((res) => res !== AuthStatus.inProgress),
      take(1),
      switchMap((res) => {
        if (res === AuthStatus.authenticated) {
          // alert('Authed');
          return from(this._authMethodsService.getUser()).pipe(map(() => true));
        } else if (this._isAuthorisedByBuildVersion()) {
          // alert('_isAuthorisedByBuildVersion');
          this._authStateService.authStatus = AuthStatus.authenticated;
          return from(this._authMethodsService.getUser()).pipe(map(() => true));
        } else {
          // alert('auth else');
          this._authStateService.authStatus = AuthStatus.inProgress;
          return this.authenticationProcess();
        }
      }),
      switchMap(() =>
        this._settingsDataService.updateSettingsData$.pipe(
          filter((data) => !!data.id),
          take(1),
        ),
      ),
      switchMap((userData) => {
        if (userData.preference === UserPreference.none) {
          return this._modalsService.openModal(GenderSelectModalComponent).pipe(
            switchMap(() => this._authMethodsService.getUser(true)),
            map(() => true),
          );
        }

        return of(true);
      }),
      switchMap((res) => {
        if (this._isServicesInitialized) {
          return of(res);
        }
        return forkJoin([
          this._shopBoostersService.fetchBoosters(),
          this._dailyQuestsService.fetchQuests(),
          this._shopTokensService.fetchTokens(),
          this._paywallService.getPremiumData(),
          this._bonusService.fetchQuestLines(),
          this._shopPacksService.fetchOffers(),
          this._chatGiftsService.fetchGifts(),
          this._ratingService.fetchRewards(),
          this._chatService.getAllChats(),
        ]).pipe(
          map(() => {
            this._isServicesInitialized = true;
            return res;
          }),
        );
      }),
      switchMap((res) => {
        const userData = this._settingsDataService.updateSettingsData$.value;
        if (
          !this._isPremiumModalShown &&
          userData.config.paywallShowOnStart &&
          userData.subscriptionStatus !== SubscriptionStatus.active &&
          userData.tutorialIsPassed
        ) {
          this._isPremiumModalShown = true;
          return this._paywallService.showPaywall('auth').pipe(map(() => res));
        }
        return of(res);
      }),
    );
  }
}
