import { WebsocketSignalRService } from '../../services/websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ShopLot } from '../../types/shop-lot-new.interface';
import { LotType } from '../../enums/lot-type.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShopItemsService {
  private _lots = new BehaviorSubject<ShopLot[]>([]);

  public boosters$ = this._lots.pipe(
    map((lots) => lots.filter((lot) => lot.lotType !== LotType.giftPack)),
  );
  public gifts$ = this._lots.pipe(
    map((lots) => lots.filter((lot) => lot.lotType === LotType.giftPack)),
  );

  constructor(private _websocketSignalRService: WebsocketSignalRService) {}

  public fetchBoosters(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<ShopLot[]>(WebsocketCommandType.getBoosters, {})
      .pipe(
        take(1),
        map((res) => {
          this._lots.next(res);
          return true;
        }),
      );
  }
}
