import { PremiumModalComponent } from '../components/premium/modal/premium-modal.component';
import { SettingsDataService } from './communication_services/settingsData.service';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { SubscriptionStatus } from '../types/user-my-response.interface';
import { WebsocketSignalRService } from './websocket-signalr.service';
import { Observable, switchMap, of, map, filter, BehaviorSubject } from 'rxjs';
import { OfferData } from '../types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { RouteName } from './../enums/route-name.enum';
import { ModalsService } from './modals.service';
import { Injectable } from '@angular/core';
import { OfferType } from '../enums/offer-type.enum';

@Injectable({
  providedIn: 'root',
})
export class PaywallService {
  private _isModalOpen = false;
  private _cachedPremiumData: OfferData[];
  private _cachedDataTimeStamp = 0;
  private _dailyChatcoinsAmount$ = new BehaviorSubject(0);

  public get dailyChatcoinsAmount$(): Observable<number> {
    return this._dailyChatcoinsAmount$;
  }

  public get premiumData() {
    return this._cachedPremiumData;
  }

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _settingsDataService: SettingsDataService,
    private _modalsService: ModalsService,
    private _router: Router,
  ) {
    if (environment.buildVersion !== BuildVersion.legal) {
      _router.events
        .pipe(
          filter(
            (e) =>
              e instanceof NavigationEnd &&
              e.url.substring(1) === RouteName.swipingChatCards,
          ),
          filter((value, index) => index % 2 === 1),
          switchMap(() => this.showPaywall('swipes_second_visit')),
        )
        .subscribe();
    }
  }

  public getPremiumData(): Observable<OfferData[]> {
    if (
      this._cachedPremiumData &&
      Date.now() - this._cachedDataTimeStamp < 3_600_000
    ) {
      return of(this._cachedPremiumData);
    }

    return this._websocketSignalRService
      .invoke<OfferData[]>(WebsocketCommandType.getSubscriptionsOffer, {})
      .pipe(
        map((res) => {
          if (res) {
            if (res.length) {
              this._dailyChatcoinsAmount$.next(res[0].dailyRewardGemsAmount);
            }
            this._cachedDataTimeStamp = Date.now();
            this._cachedPremiumData = res;

            return res;
          } else {
            return null;
          }
        }),
      );
  }

  public getMonthlyPremiumData(): Observable<OfferData> {
    return this.getPremiumData().pipe(
      map((offers) =>
        offers.find((o) => o.type === OfferType.monthlySubscription),
      ) || null,
    );
  }

  public showPaywall(callFrom: string): Observable<boolean> {
    if (
      this._settingsDataService.updateSettingsData$.value.subscriptionStatus ===
      SubscriptionStatus.active
    ) {
      return of(false);
    }

    return this.getPremiumData().pipe(
      switchMap((res) => {
        if (res) {
          return this.showPaywallModal(res, callFrom);
        }
        return of(false);
      }),
    );
  }

  private showPaywallModal(
    subscriptionOffers: OfferData[],
    callFrom: string,
  ): Observable<boolean> {
    if (this._isModalOpen) {
      return of(null);
    }
    this._isModalOpen = true;
    return this._modalsService
      .openModal(PremiumModalComponent, {
        subscriptionOffers,
        callFrom,
      })
      .pipe(
        map((res) => {
          this._isModalOpen = false;
          return !!res;
        }),
      );
  }
}
