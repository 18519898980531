import 'cordova-plugin-purchase';
import { PaymentsStateService } from 'src/app/shared/services/payments/payments-state.service';
import { SettingsDataService } from '../communication_services/settingsData.service';
import { PaymentsCommonMethodsService } from './payments-common-methods.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';
import { JWTTokenService } from '../communication_services/JWTToken.service';
import { PaymentResponse } from '../../types/payment-response.interface';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { StoreItemType } from '../../enums/store-Item-type.enum';
// import { Logger, LoggingService } from 'ionic-logging-service';
import { Platform, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RealCostProperties,
  RealPurchaseData,
} from '../../types/purchase-data.interface';
import {
  Observable,
  throwError,
  catchError,
  switchMap,
  timer,
  retry,
  take,
  from,
  map,
  of,
} from 'rxjs';

export interface StoreProduct {
  appleStoreId: string;
  coins: number;
  cost: number;
  discount: number;
  googlePlayId: string;
  lotType: string;
  offerType: string;
  oldCoins: number;
  oldCost: number;
  productId: string;
  productType: StoreItemType;
  isOwned: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LegalPaymentService {
  private readonly _appStoreApplicationTransaction = 'appstore.application';

  private _store: CdvPurchase.Store;
  private _host = environment.apiUrl;
  // private _logger: Logger;

  constructor(
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService,
    private _paymentsStateService: PaymentsStateService,
    private _settingsDataService: SettingsDataService,
    private _signalRService: WebsocketSignalRService,
    private _analyticsService: AnalyticsService,
    // private _logginService: LoggingService,
    private _tokenService: JWTTokenService,
    private _toastCtrl: ToastController,
    private _platform: Platform,
    private _http: HttpClient,
  ) {
    // this._logger = this._logginService.getLogger('App.LegalPaymentService');
  }

  public init() {
    this._platform.ready().then(() => {
      this._store = CdvPurchase.store;

      this.addListeners();
      this._signalRService
        .invoke<StoreProduct[]>(WebsocketCommandType.getStoreLots, {})
        .pipe(take(1))
        .subscribe((products) => {
          try {
            this._store.log.info('Init');

            this._store.applicationUsername = () =>
              this._settingsDataService.updateSettingsData$.value.id;

            this._store.validator = {
              url: `${this._host}/api/Shop/validate-store-receipt`,
              headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: `Bearer ${this._tokenService.getToken()}`,
              },
            };

            this._store.register(
              products
                .filter((p) => !!p.googlePlayId)
                .map((product) => {
                  let type: CdvPurchase.ProductType;

                  // if (product.productType === StoreItemType.paidSubscription) {
                  //   alert('register: ' + JSON.stringify(product));
                  // }

                  switch (product.productType) {
                    case StoreItemType.nonConsumable:
                      type = CdvPurchase.ProductType.NON_CONSUMABLE;
                      break;
                    case StoreItemType.paidSubscription:
                      type = CdvPurchase.ProductType.PAID_SUBSCRIPTION;
                      break;
                    case StoreItemType.consumable:
                    default:
                      type = CdvPurchase.ProductType.CONSUMABLE;
                      break;
                  }
                  return {
                    id: this.getCurrentStoreId(
                      product.googlePlayId,
                      product.appleStoreId,
                    ),
                    type,
                    platform: this._platform.is('ios')
                      ? CdvPurchase.Platform.APPLE_APPSTORE
                      : CdvPurchase.Platform.GOOGLE_PLAY,
                  };
                }),
            );
            this._store.initialize();
          } catch (error) {
            console.error('legal payments init error', error);
            // alert('legal payments init error: ' + error);
          }
        });
    });
  }

  private addListeners() {
    try {
      this._store
        .when()

        // .productUpdated((product) => {
        //   if (
        //     product.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION ||
        //     product.type === CdvPurchase.ProductType.NON_RENEWING_SUBSCRIPTION
        //   )
        //     alert('productUpdated: ' + JSON.stringify(product));
        // })

        .approved((transaction) => {
          // alert('approved: ' + JSON.stringify(transaction));

          this._analyticsService.serverLogging('Approved', transaction);
          this._store.log.info('approved: ' + JSON.stringify(transaction));
          return transaction.verify();
        })

        .verified((verifiedReceipt) => {
          // alert('verifiedReceipt: ' + JSON.stringify(verifiedReceipt));

          this._analyticsService.serverLogging('Verified', verifiedReceipt);
          this._store.log.info(
            'verifiedReceipt: ' + JSON.stringify(verifiedReceipt),
          );
          return verifiedReceipt.finish();
        })

        .unverified((unverifiedReceipt) => {
          // alert('unverifiedReceipt: ' + JSON.stringify(unverifiedReceipt));

          this._store.log.info('Unverified: ' + unverifiedReceipt);
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
        })

        .finished((transaction) => {
          // alert('finished: ' + JSON.stringify(transaction));

          if (
            transaction.transactionId === this._appStoreApplicationTransaction
          ) {
            return;
          }

          this._analyticsService.serverLogging('Finished', transaction);

          this._store.log.info(
            'Finished: ' + JSON.stringify(transaction?.transactionId),
          );

          this._paymentsStateService.paymentState =
            PaymentAnimationStates.progress;

          const requestUrl = `${this._host}/api/Shop/store-payment?transactionId=${transaction.transactionId}`;
          this._http
            .get<PaymentResponse>(requestUrl)
            .subscribe((res) =>
              this._paymentsCommonMethodsService.checkTransactionHandler(res),
            );
        });
    } catch (error) {}
  }

  public getProductPrice(purchase: RealCostProperties): Observable<string> {
    if (!this._platform.is('cordova')) {
      return of('{price}');
    }

    const id = this.getCurrentStoreId(
      purchase.googlePlayId,
      purchase.appleStoreId,
    );

    return of(null).pipe(
      switchMap(() => {
        if (!this._store.ready) {
          return throwError(() => new Error('Store not ready'));
        }
        const product = this._store.get(id);

        if (!product) {
          return throwError(() => new Error('Product not found'));
        }

        if (product.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION) {
          return of(
            product.offers[0].pricingPhases[
              product.offers[0].pricingPhases.length - 1
            ].price,
          );
        } else {
          return of(product.pricing.price);
        }
      }),
      retry({
        delay: (error, retries) => {
          if (retries <= 10) {
            return timer(1000);
          }
          throw new Error('Max retries exceeded');
        },
      }),
    );
  }

  public getProductPricingPhases(
    purchase: RealCostProperties,
  ): Observable<CdvPurchase.PricingPhase[]> {
    if (!this._platform.is('cordova')) {
      return of(null);
    }

    const id = this.getCurrentStoreId(
      purchase.googlePlayId,
      purchase.appleStoreId,
    );

    return of(null).pipe(
      switchMap(() => {
        if (!this._store.ready) {
          return throwError(() => new Error('Store not ready'));
        }
        const product = this._store.get(id);

        if (!product) {
          return throwError(() => new Error('Product not found'));
        }

        return of(product.offers[0].pricingPhases);
      }),
      retry({
        delay: (error, retries) => {
          if (retries <= 10) {
            return timer(1000);
          }
          throw new Error('Max retries exceeded');
        },
      }),
    );
  }

  public purchase(data: RealPurchaseData): Observable<boolean> {
    const productId = this.getCurrentStoreId(
      data.purchase.googlePlayId,
      data.purchase.appleStoreId,
    );
    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

    return this.waitUntilCanPurchase(productId).pipe(
      switchMap(() => from(this._store.get(productId).getOffer().order())),
      map((error) => {
        if (error?.isError) {
          this._analyticsService.paymentReject(error.productId, error.message);
          throw new Error(error.message);
        }
        return true;
      }),
      catchError((e) => {
        this._paymentsStateService.paymentState = PaymentAnimationStates.error;
        return of(false);
      }),
    );
  }

  private waitUntilCanPurchase(id: string): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => {
        const item = this._store.get(id);

        // alert('purchase: ' + JSON.stringify(id));
        // alert('item: ' + id + ' ' + JSON.stringify(item));

        if (item?.canPurchase) {
          return of(true);
        }

        return throwError(() => new Error('Cannot Purchase'));
      }),
      retry({
        delay: (error, retries) => {
          if (retries <= 10) {
            return timer(500);
          }

          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
          this._toastCtrl
            .create({
              message: 'Please try again',
              duration: 1000,
            })
            .then((res) => res.present());

          throw new Error('Max retries exceeded');
        },
      }),
    );
  }

  // To comply with AppStore rules
  public restore() {
    if (!this._platform.is('cordova')) {
      alert('run this on device');
      return;
    }
    this._store.restorePurchases().then((error) => {
      if (error?.isError) {
        this._analyticsService.restorePurchases({ status: error.message });
        alert('Try again later');
      } else {
        this._analyticsService.restorePurchases({ status: 'success' });
        alert('Your purchases restored');
      }
    });
  }

  private getCurrentStoreId(
    googlePlayId: string,
    appleStoreId: string,
  ): string {
    return this._platform.is('android')
      ? googlePlayId
      : this._platform.is('ios')
        ? appleStoreId
        : null;
  }
}
