import {
  SoftCostProperties,
  SoftPurchaseData,
} from '../../types/purchase-data.interface';
import { PaymentResponeStatus } from '../../enums/payment-respone-status.enum';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PaymentResponse } from '../../types/payment-response.interface';
import { AnalyticsService } from '../analytics/analytics.service';
import { PaymentsStateService } from './payments-state.service';
import { environment } from 'src/environments/environment';
import { InventoryService } from '../inventory.service';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SoftPaymentsService {
  private _host: string = environment.apiUrl;

  constructor(
    private _paymentsStateService: PaymentsStateService,
    private _analyticsService: AnalyticsService,
    private _inventoryService: InventoryService,
    private _http: HttpClient,
  ) {}

  public getCost(purchase: SoftCostProperties) {
    if (!purchase) {
      return 0;
    }

    switch (environment.buildVersion) {
      case BuildVersion.nutaku:
        return purchase.nutakuCost;

      case BuildVersion.erogames:
        return purchase.erogamesCost;

      default:
        return purchase.cost;
    }
  }

  /**
   * @deprecated Old purchase method
   */
  public purchase(data: SoftPurchaseData): Observable<boolean> {
    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

    const requestUrl = `${this._host}/api/Shop/buy`;

    return this._http
      .post<PaymentResponse>(requestUrl, {
        productId: data.productId,
      })
      .pipe(
        map((res) => {
          this._analyticsService.buyGiftLot(
            res.payment.lotType,
            res.payment.productId,
            res.payment?.status === PaymentResponeStatus.success,
          );
          if (res.payment?.status === PaymentResponeStatus.success) {
            this._inventoryService.setInventory(res.inventory);
            this._paymentsStateService.paymentState =
              PaymentAnimationStates.success;
            return true;
          }

          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
          return false;
        }),
        catchError((error) => {
          console.error(error);
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
          return of(false);
        }),
      );
  }

  public purchaseNew(data: SoftCostProperties): Observable<boolean> {
    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

    const requestUrl = `${this._host}/api/Shop/buy`;

    return this._http
      .post<PaymentResponse>(requestUrl, {
        productId: data.productId,
      })
      .pipe(
        map((res) => {
          this._analyticsService.buyGiftLot(
            res.payment.lotType,
            res.payment.productId,
            res.payment?.status === PaymentResponeStatus.success,
          );
          if (res.payment?.status === PaymentResponeStatus.success) {
            this._inventoryService.setInventory(res.inventory);
            this._paymentsStateService.paymentState =
              PaymentAnimationStates.success;
            return true;
          }

          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
          return false;
        }),
        catchError((error) => {
          console.error(error);
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
          return of(false);
        }),
      );
  }
}
