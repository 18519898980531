import {
  BehaviorSubject,
  Subscription,
  Observable,
  switchMap,
  timer,
  take,
  map,
  of,
} from 'rxjs';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { DailyQuest, DailyQuestsResponse } from './daily-quests.interface';
import { AdCollectCoins } from 'src/app/shared/types/ads.interface';
import { InventoryService } from '../../services/inventory.service';
import { ToastsService } from '../../services/toasts.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DailyQuestsService {
  public readonly dailyData$: BehaviorSubject<DailyQuestsResponse> =
    new BehaviorSubject(null);
  public readonly unclaimedRewardsCount$ = this.dailyData$.pipe(
    map((res) =>
      res ? res.dailyQuests.filter((q) => q.completed && !q.awarded).length : 0,
    ),
  );
  private _updateTimer$: Subscription;
  private _lastUpdateTime = 0;

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _analyticsService: AnalyticsService,
    private _translateService: TranslateService,
    private _inventoryService: InventoryService,
    private _toastsService: ToastsService,
  ) {}

  public fetchQuests(force = false): Observable<boolean> {
    if (!force && Date.now() - this._lastUpdateTime < 5000) {
      return of(false);
    }

    this._lastUpdateTime = Date.now();

    return this._websocketSignalRService
      .invoke<DailyQuestsResponse>(WebsocketCommandType.getDailyQuests, {})
      .pipe(
        take(1),
        map((res) => {
          this.dailyData$.next(res);
          this._updateTimer$?.unsubscribe();
          this._updateTimer$ = timer(res.time * 1000).subscribe(() =>
            this.fetchQuests(true),
          );

          return true;
        }),
      );
  }

  public claim(quest: DailyQuest): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<AdCollectCoins>(WebsocketCommandType.getDailyQuestReward, {
        id: quest.id,
      })
      .pipe(
        switchMap((res) => {
          this._analyticsService.claimDailyQuest(quest.type);
          return this.applyReward(res);
        }),
      );
  }

  public claimAll(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<AdCollectCoins>(WebsocketCommandType.getDailyQuestDayReward, {})
      .pipe(
        switchMap((res) => {
          this._analyticsService.claimAllDailyQuests();
          return this.applyReward(res);
        }),
      );
  }

  private applyReward(reward: AdCollectCoins): Observable<boolean> {
    this._toastsService.showMessage(
      this._translateService.instant('BONUS_PAGE.COINS_RECEIVED', {
        value: reward.difference,
      }),
    );
    this._inventoryService.setGems(reward.coins);
    return this.fetchQuests(true);
  }
}
