<div
  *ngIf="enabled"
  (click)="openProfile()"
  class="popup"
  [class.is-open]="isOpen"
>
  <img src="/assets/img/header/photo-icon.svg" alt="" class="photo-icon" />You
  unblocked new photos
  <img src="/assets/img/icon/arrow-right-thin.svg" alt="" class="arrow" />
</div>
