import {
  PromptsInChatService,
  PromptsInChatType,
} from 'src/app/shared/services/prompts-in-chat.service';
import {
  TermsModalComponent,
  TermsType,
} from '../../terms-modal/terms-modal.component';
import { TutorialService } from 'src/app/shared/services/communication_services/tutorial.service';
import { UserDataService } from 'src/app/shared/services/communication_services/userData.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { NicknameService } from 'src/app/shared/services/nickname.service';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { ChatType } from 'src/app/shared/types/chat-data.interface';
import { environment } from 'src/environments/environment';
import { ModalController, Platform } from '@ionic/angular';
import { NavHelper } from 'src/app/shared/helpers';
import { FormsModule } from '@angular/forms';
import { Component } from '@angular/core';

interface Step {
  answers: { text: string; action: () => void }[];
}

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  standalone: true,
  imports: [FormsModule],
})
export class OnboardingModalComponent {
  public readonly defaultNickname = 'Gigachad';
  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;
  public inputField = '';
  public step = 0;

  public steps: Step[] = [
    {
      answers: [
        {
          text: "It's okay, I don't mind at all 😉",
          action: () => {
            this.goToStep(1);
          },
        },
      ],
    },
    {
      answers: [],
    },
    {
      answers: [
        {
          text: "I'm under 18",
          action: () => {
            const storeLink = this._platform.is('ios')
              ? 'https://apps.apple.com/cy/app/lovechats/id6444935703'
              : 'https://play.google.com/store/apps/details?id=app.perchapps.lovechats';

            this._analyticsService.ageIsSet(false);
            setTimeout(() => (location.href = storeLink), 1000);
          },
        },
        {
          text: "I'm over 18",
          action: () => {
            this._promptsInChatService
              .setPrompt(PromptsInChatType.ageOver18)
              .subscribe();
            this.goToStep(3);
          },
        },
      ],
    },
    {
      answers: [
        {
          text: 'Yeah, it’s ok',
          action: () => {
            this._promptsInChatService
              .setPrompt(PromptsInChatType.agreement)
              .subscribe();
            this.goToStep(4);
          },
        },
      ],
    },
    {
      answers: [
        {
          text: "I'm very like to chat as well",
          action: () => {
            this.goToStep(5);
          },
        },
      ],
    },
    {
      answers: [
        {
          text: 'That is I love to hear!',
          action: () => {
            this.goToStep(6);
          },
        },
      ],
    },
    {
      answers: [
        {
          text: "I'm as ready as never 🍆💦",
          action: () => {
            this.endTutorial();
          },
        },
      ],
    },
  ];

  constructor(
    private _promptsInChatService: PromptsInChatService,
    private _analyticsService: AnalyticsService,
    private _tutorialService: TutorialService,
    private _nicknameService: NicknameService,
    private _userDataService: UserDataService,
    private _modalController: ModalController,
    private _modalsService: ModalsService,
    private _navHelper: NavHelper,
    private _platform: Platform,
  ) {}

  public submitNickname(event: Event) {
    event.preventDefault();

    this._nicknameService
      .set(
        this.inputField ? this.inputField : this.defaultNickname,
        !!this.inputField.length,
      )
      .subscribe();

    if (environment.buildVersion === BuildVersion.legal) {
      this.goToStep(3);
    } else {
      this.goToStep(2);
    }
  }

  public goToStep(index: number) {
    switch (index) {
      case 1:
        this._tutorialService.setStep('enter_name');
        break;
      case 2:
        this._tutorialService.setStep('18_years');
        break;
      case 3:
        this._tutorialService.setStep('policy');
        break;
      case 4:
        this._tutorialService.setStep('my_girls');
        break;
      case 5:
        this._tutorialService.setStep('photos');
        break;
      case 6:
        this._tutorialService.setStep('tokens');
        break;
    }

    this.step = index;
  }

  public endTutorial() {
    this._tutorialService.finishTutorial().subscribe(() => {
      const aiChat = this._userDataService.updateUserData.value.chats.find(
        (c) => c.type === ChatType.ai,
      ).id;
      this._navHelper.goToChat(aiChat);

      this._modalController.dismiss();
    });
  }

  public openTermsModal(): void {
    this._modalsService
      .openModal(TermsModalComponent, {
        type: TermsType.termsOfUse,
      })
      .subscribe();
  }

  public openPrivacyModal(): void {
    this._modalsService
      .openModal(TermsModalComponent, {
        type: TermsType.privacy,
      })
      .subscribe();
  }

  public openCookiesModal(): void {
    this._modalsService
      .openModal(TermsModalComponent, {
        type: TermsType.cookies,
      })
      .subscribe();
  }

  public open18UscModal(): void {
    this._modalsService
      .openModal(TermsModalComponent, {
        type: TermsType.usc18,
      })
      .subscribe();
  }
}
