<app-answer-input
  *ngIf="state === 'chat' && (isChatMessagesOpen$ | async) === true"
></app-answer-input>
<ion-footer *ngIf="state !== 'chat'" class="ion-no-border">
  <ul class="tab-nav" *ngIf="visibleMenuButtons.length">
    <li
      *ngFor="let menuButton of visibleMenuButtons"
      [class.selected]="menuButton === selectedMenuButton"
      [class.bounce]="
        menuButton !== selectedMenuButton &&
        menuButton === uiStates.bonus &&
        hasBonusRewards
      "
      [class.disabled]="
        (tutorialStep | async) !== null && menuButton !== selectedMenuButton
      "
    >
      <button (click)="goTo(menuButton)">
        <div class="icon">
          <ng-container *ngIf="menuButton === uiStates.bonus">
            <div
              *ngIf="unclaimedRewards$ | async as unclaimedRewards"
              class="rewards-count"
            >
              {{ unclaimedRewards }}
            </div>
          </ng-container>

          <ng-container *ngIf="menuButton !== selectedMenuButton">
            <img
              *ngIf="menuButton === uiStates.gallery"
              src="/assets/img/navbar/gallery.svg"
            />
            <img
              *ngIf="menuButton === uiStates.bonus"
              src="/assets/img/navbar/quests.svg"
            />

            <img
              *ngIf="menuButton === uiStates.chat"
              src="/assets/img/navbar/chats.svg"
              class="heart"
            />
            <img
              *ngIf="menuButton === uiStates.swipe"
              src="/assets/img/navbar/swipe.svg"
            />
            <img
              *ngIf="menuButton === uiStates.shop"
              src="/assets/img/navbar/shop.svg"
            />
            <img
              *ngIf="menuButton === uiStates.settings"
              src="/assets/img/navbar/profile.svg"
            />
          </ng-container>
          <ng-container *ngIf="menuButton === selectedMenuButton">
            <img
              *ngIf="menuButton === uiStates.gallery"
              src="/assets/img/navbar/gallery-active.svg"
            />
            <img
              *ngIf="menuButton === uiStates.bonus"
              src="/assets/img/navbar/quests-active.svg"
            />
            <img
              *ngIf="menuButton === uiStates.chat"
              src="/assets/img/navbar/chats-active.svg"
              class="heart"
            />
            <img
              *ngIf="menuButton === uiStates.swipe"
              src="/assets/img/navbar/swipe-active.svg"
            />
            <img
              *ngIf="menuButton === uiStates.shop"
              src="/assets/img/navbar/shop-active.svg"
            />
            <img
              *ngIf="menuButton === uiStates.settings"
              src="/assets/img/navbar/gallery-active.svg"
            />
          </ng-container>
        </div>

        <span class="title">
          <ng-container *ngIf="menuButton === uiStates.gallery">
            {{ "NAVBAR.GALLERY" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.bonus">
            {{ "NAVBAR.QUESTS" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.chat">
            {{ "NAVBAR.CHATS" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.swipe">
            {{ "NAVBAR.SWIPE" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.shop">
            {{ "NAVBAR.SHOP" | translate }}
          </ng-container>
          <ng-container *ngIf="menuButton === uiStates.settings">
            {{ "NAVBAR.PROFILE" | translate }}
          </ng-container>
        </span>
      </button>
    </li>
  </ul>

  <div class="ios-spacer" *ngIf="isShowBottomSpacer"></div>
</ion-footer>
