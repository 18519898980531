import { TutorialService } from '../../services/communication_services/tutorial.service';
import { UiStateService } from '../../services/communication_services/uiStates.service';
import { DailyQuestsService } from '../../views/daily-quests/daily-quests.service';
import { BonusService } from '../../services/API_services/bonus.service';
import { UnsubscribeHelper } from './../../helpers/unsubscribe.helper';
import { Subscription, combineLatest, filter, map, take } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UiStatesEnum } from './../../enums/ui-states.enum';
import { MessageParser } from '../../helpers/messageParser';
import { environment } from 'src/environments/environment';
import { UiStates } from '../../enums/uiStates';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { NavHelper } from '../../helpers';
import { Platform } from '@ionic/angular';
import { Halloween2023Service } from '../promo/halloween2023/halloween2023.service';
import { ChatStateService } from '../../views/chat/services/chat-state.service';

export interface MenuButtonsOptions {
  name: string;
  btn: AnimationItem;
  clicked: boolean;
  icon: string;
  options: AnimationOptions;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public environment = environment;
  public state: UiStates;
  public selectedMenuButton: UiStates;
  public visibleMenuButtons: UiStates[] = [];
  public uiStates = UiStates;
  public hasBonusRewards = false;
  public readonly unclaimedRewards$ = combineLatest([
    this._dailyQuestService.unclaimedRewardsCount$,
    this._bonusService.unclaimedRewardsCount$,
    this._halloweenService.isAvailiable$,
    this._tutorialService.currentStep$,
  ]).pipe(map((res) => (res[3] ? 0 : res[0] + res[1] + (res[2] ? 1 : 0))));

  private _menuButtons: UiStates[] = [
    UiStates.gallery,
    UiStates.bonus,
    UiStates.chat,
    UiStates.swipe,
    UiStates.shop,
    UiStates.settings,
  ];
  private subscribers: Subscription[] = [];
  isShowBottomSpacer =
    this._platform.is('ios') && !this._platform.is('mobileweb');

  public readonly tutorialStep = this._tutorialService.currentStep$;

  public readonly isChatMessagesOpen$ = this._chatStateService.isMessagesOpen$;

  constructor(
    public messageParser: MessageParser,
    private _halloweenService: Halloween2023Service,
    private _dailyQuestService: DailyQuestsService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _chatStateService: ChatStateService,
    private _tutorialService: TutorialService,
    private _uiStateService: UiStateService,
    private _bonusService: BonusService,
    private _navHelper: NavHelper,
    private _platform: Platform,
  ) {}

  ngOnInit() {
    this._uiStateService.showSwipingCards
      .pipe(
        filter((res) => res !== null),
        take(1),
      )
      .subscribe((showCards) => {
        if (!showCards) {
          this.visibleMenuButtons = this._menuButtons.filter(
            (e) => e !== 'swipe',
          );
        } else {
          this.visibleMenuButtons = this._menuButtons;
        }
      });

    this.state = UiStates.main;

    this.subscribers.push(
      this._uiStateService.updateStateData.subscribe((res) => {
        this.state = res[UiStatesEnum.state];
        this.setCurrentFooterState(this.state);
      }),
    );
  }

  private setCurrentFooterState(state: UiStates): void {
    switch (state) {
      case UiStates.album:
        this.selectedMenuButton = UiStates.gallery;
        break;
      case UiStates.main:
        this.selectedMenuButton = UiStates.chat;
        break;
      case UiStates.questLines:
        this.selectedMenuButton = UiStates.bonus;
        break;
      default:
        this.selectedMenuButton = state;
        break;
    }
  }

  public goTo(state: UiStates): void {
    if (this.tutorialStep.value !== null) {
      return;
    }

    switch (state) {
      case UiStates.gallery:
        return this._navHelper.goToGallery();
      case UiStates.chat:
        return this._navHelper.goToAllChats();
      case UiStates.settings:
        return this._navHelper.goToSettings();
      case UiStates.bonus:
        return this._navHelper.goToBonusDailyReweards();
      case UiStates.shop:
        return this._navHelper.goToShopSubscription();
      case UiStates.swipe:
        return this._navHelper.goToCards();
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this.subscribers);
  }
}
