<div class="modal">
  <div class="modal-head modal-head--info">
    {{ "CHATS_PAGE.HAS_MORE_PHOTO" | translate: { name: this.data.name } }}
    <button class="close" (click)="close()">
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d7)">
          <rect
            x="4"
            y="5.12134"
            width="3"
            height="25"
            rx="1.5"
            transform="rotate(-45 4 5.12134)"
            fill="currentColor"
          />
          <rect
            width="3"
            height="25"
            rx="1.5"
            transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 24 5.12134)"
            fill="currentColor"
          />
        </g>
        <defs>
          <filter
            id="filter0_d7"
            x="0.621338"
            y="0.621338"
            width="26.7573"
            height="26.5563"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.333333 0 0 0 0 0.333333 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="text">
      {{
        "CHATS_PAGE.HAS_MORE_PHOTOS_AND_VIDEOS"
          | translate: { name: this.data.name }
      }}
      <b>{{ this.data.cost }}</b> tokens.
    </div>
    <button
      class="btn btn-primary"
      style="width: 202px; margin: 0 auto"
      (click)="buy()"
      [innerHTML]="'CHATS_PAGE.BUY' | translate"
      [disabled]="pending"
    >
      <img
        [src]="environment.rootPath + '/assets/img/gem.png'"
        alt=""
        width="33px"
        style="margin: 0 4px"
      />
      <span
        style="color: #ffdf72; text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25)"
        >{{ this.data.cost }}</span
      >
    </button>
  </div>
</div>
