import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { SettingsService } from 'src/app/shared/services/API_services/settings.service';
import { Injectable } from '@angular/core';
import { SettingsDataService } from './communication_services/settingsData.service';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { UserMyResponse } from '../types/user-my-response.interface';

export enum PromptsInChatType {
  ageOver18 = 'ageOver18',
  agreement = 'agreement',
}
@Injectable({
  providedIn: 'root',
})
export class PromptsInChatService {
  constructor(
    private _settingsDataService: SettingsDataService,
    private _settingsService: SettingsService,
    private _analyticsService: AnalyticsService,
  ) {}

  setPrompt(type: PromptsInChatType): Observable<void> {
    let request: Observable<boolean>;
    let settingsDataProp: keyof UserMyResponse;
    switch (type) {
      case PromptsInChatType.ageOver18:
        request = this._settingsService.updateClientAge();
        settingsDataProp = 'isAgeConfirmed';
        this._analyticsService.ageIsSet(true);
        break;
      case PromptsInChatType.agreement:
        request = this._settingsService.updateClientAgreement();
        settingsDataProp = 'isAgreementAccepted';
        this._analyticsService.agreementIsSet();
        break;
    }
    this._settingsDataService.changeData(settingsDataProp, true);
    return request.pipe(
      switchMap(() => this._settingsDataService.updateSettingsData$),
      take(1),
      map((data) => {
        this._settingsDataService.changeData('isAgeConfirmed', true);
        if (data.isAgreementAccepted && data.isAgeConfirmed) {
          localStorage.setItem('read_tncs', 'true');
        }
        return;
      }),
    );
  }
}
