<div class="modal-wrapper">
  <div class="modal-content">
    <button (click)="closeModal(null)" class="close-btn">
      <img src="/assets/img/modals/close-btn.svg" alt="" />
    </button>

    <h2 class="title price-text-size-16">
      <img src="/assets/img/quests-page/daily-icons/gift.png" alt="" /> Presents
    </h2>
    <p class="description main-information-title-14-medium">
      Give presents to get a new level of relationships and intimacy
    </p>

    <ul class="gifts">
      <li class="gift" *ngFor="let gift of giftsList$ | async">
        <div class="gift-wrapper">
          <div class="gift-image-wrapper">
            <img [src]="gift.imageUrl" alt="" />
            <div class="gift-rating">+{{ gift.rating }}</div>
          </div>
          <h4 class="main-information-title-14">{{ gift.name }}</h4>

          <app-button
            (click)="sendGift(gift)"
            [pending]="sendGiftLoader"
            class="gift-btn price-text-size-12"
          >
            {{ gift.count ? "Send" : "Buy" }}
          </app-button>
        </div>

        <p *ngIf="gift.count" class="gift-count">{{ gift.count }} available</p>
      </li>
    </ul>
  </div>
</div>
