import {
  PromptsInChatService,
  PromptsInChatType,
} from 'src/app/shared/services/prompts-in-chat.service';
import {
  NextMessageAnswer,
  NextMessage,
} from 'src/app/shared/types/next-message.interface';
import {
  BehaviorSubject,
  throwError,
  Observable,
  catchError,
  Subject,
  map,
  of,
} from 'rxjs';
import {
  SplitTestCasesService,
  SplitTestCase,
} from 'src/app/shared/services/split-test-cases.service';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { ChooseAnswerResponse } from 'src/app/shared/services/API_services/chat-api-methods.service';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { ChatMessageType } from 'src/app/shared/enums/chat-message-type.enum';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { NicknameService } from 'src/app/shared/services/nickname.service';
import { ChatStateService } from './chat-state.service';
import { NavHelper } from 'src/app/shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChatAnswersService {
  public readonly answerSent = new Subject();
  public readonly clickAnswerWithoutAiTokens = new Subject();
  public readonly maxMessageLength = 120;

  public readonly answerMessage$: BehaviorSubject<NextMessage> =
    new BehaviorSubject(null);

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _splitTestCaseService: SplitTestCasesService,
    private _promptsInChatService: PromptsInChatService,
    private _settingsDataService: SettingsDataService,
    private _chatStateService: ChatStateService,
    private _analyticsService: AnalyticsService,
    private _inventoryService: InventoryService,
    private _nicknameService: NicknameService,
    private _navHelper: NavHelper,
  ) {}

  public showAnswers(nextMessage: NextMessage) {
    if (this._chatStateService.isAiChat$.value && !nextMessage.answers.length) {
      nextMessage.answers.push({
        type: 'input',
        id: 0,
        costInGems:
          this._settingsDataService.updateSettingsData$.value
            .messageCostInTokens,
        messageText: '',
        localizedMessageText: null,
      });
    }

    this.answerMessage$.next(nextMessage);
  }

  public clickOnAnswer(answer: NextMessageAnswer): Observable<boolean> {
    if (
      this._chatStateService.isAiChat &&
      this._splitTestCaseService.hasCase(SplitTestCase.gamifyAi)
    ) {
      return this.sendAiAnswer(answer);
    }

    if (answer.id === null) {
      return of(false);
    }

    const message = this.answerMessage$.value;

    this.answerMessage$.next(null);

    const enoughGems = this._inventoryService.gems >= answer.costInGems;

    if (answer.costInGems) {
      this._analyticsService.onPaidAnswerClick({
        messageId: message.id,
        enoughGems,
        answerId: answer.id,
      });
    }

    if (!enoughGems) {
      this._navHelper.goToShopGems();
      return of(false);
    }

    this._chatStateService.addMessagePartial({
      id: message.id,
      messageText: answer.messageText,
      localizedMessageText: answer.localizedMessageText,
      orderIdx: message.orderIdx,
    });

    return this._websocketSignalRService
      .invoke<ChooseAnswerResponse>(WebsocketCommandType.chooseAnswer, {
        chatId: message.chatId,
        answerId: answer.id,
      })
      .pipe(
        map((res) => {
          this._inventoryService.setGems(res.coins);

          if (!this._splitTestCaseService.hasCase(SplitTestCase.gamifyAi)) {
            this._inventoryService.setGems(res.coins);

            this._chatStateService.messages$.value[
              this._chatStateService.messages$.value.length - 1
            ].emoji = res.emoji;
          }

          this.answerSent.next(null);
          return true;
        }),
        catchError((res) => {
          if (res.error.status === 'notEnoughCoins') {
            this._navHelper.goToShopGems();
          } else {
            // хак, чтобы чат в лок не встал при ошибке
            this.answerSent.next(null);
          }
          return of(false);
        }),
      );
  }

  private sendAiAnswer(answer: NextMessageAnswer): Observable<boolean> {
    if (answer.id === null) {
      return of(false);
    }

    if (this._inventoryService.items.gem$.value < answer.costInGems) {
      this.clickAnswerWithoutAiTokens.next(null);
      return of(false);
    }

    const message = this.answerMessage$.value;
    const answerIndex = message.answers.findIndex((a) => a.id === answer.id);

    this._analyticsService.gamifyAnswer({
      chatName: this._chatStateService.modelName$.value,
      rating: answer.ratingInfo.value,
      type: answerIndex === 0 ? 'low' : answerIndex === 1 ? 'middle' : 'high',
    });

    this.answerMessage$.next(null);

    const newMessage = this._chatStateService.addMessagePartial({
      id: message.id,
      messageText: answer.messageText,
      localizedMessageText: answer.localizedMessageText,
      orderIdx: message.orderIdx,
    });

    return this._websocketSignalRService
      .invoke<{ aiTokens: number }>(WebsocketCommandType.aiChooseAnswer, {
        chatId: message.chatId,
        answerId: answer.id,
      })
      .pipe(
        map((res) => {
          this.answerSent.next(null);
          return true;
        }),
        catchError((error) => {
          switch (error.error?.status) {
            case 'textEmpty':
            case 'textTooLong':
            case 'chatNotFound':
            case 'notPlayerTurn':
              this._chatStateService.removeMessage(newMessage);
              break;
            case 'notEnoughAiTokens':
              this._chatStateService.removeMessage(newMessage);
              this.clickAnswerWithoutAiTokens.next(null);
              break;
          }
          return throwError(() => error);
        }),
      );
  }

  public submitNickname(
    nickname: string,
    handWrited: boolean,
  ): Observable<boolean> {
    const message = this.answerMessage$.value;
    this.answerMessage$.next(null);

    return this._nicknameService.set(nickname, handWrited).pipe(
      map(() => {
        this._chatStateService.addMessagePartial({
          id: message.id,
          messageText: nickname,
          localizedMessageText: { en: nickname },
          orderIdx: message.orderIdx,
        });

        this.answerSent.next(null);
        return true;
      }),
    );
  }

  public submitInput(input: string): Observable<boolean> {
    if (!input) {
      return of(false);
    }

    if (
      this.answerMessage$.value.answers[0].costInGems >
      this._inventoryService.gems
    ) {
      this.clickAnswerWithoutAiTokens.next(null);
      return of(false);
    }

    this._analyticsService.aiMessage({
      chatcoinsAmount: this._inventoryService.items.gem$.value,
      messageLength: input.length,
      messageNumber:
        this._chatStateService.messages$.value.filter(
          (m) => m.orderIdx > 0 && m.type === ChatMessageType.hero,
        ).length + 1,
    });

    const message = this.answerMessage$.value;
    this.answerMessage$.next(null);

    const newMessage = this._chatStateService.addMessagePartial({
      id: message.id,
      messageText: input,
      localizedMessageText: { en: input },
      orderIdx: message.orderIdx,
    });

    return this._websocketSignalRService
      .invoke<{ chatRating: number; gems: number }>(
        WebsocketCommandType.userAnswerToAi,
        {
          chatId: message.chatId,
          text: input,
        },
      )
      .pipe(
        map((res) => {
          this._inventoryService.setCount('gem', res.gems);
          this.answerSent.next(null);
          return true;
        }),
        catchError((error) => {
          switch (error.error?.status) {
            case 'textEmpty':
            case 'textTooLong':
            case 'chatNotFound':
            case 'notPlayerTurn':
              this._chatStateService.removeMessage(newMessage);
              break;
            case 'notEnoughCoins':
              this._chatStateService.removeMessage(newMessage);
              this.clickAnswerWithoutAiTokens.next(null);
              this.answerSent.next(null);
              break;
            default:
              return throwError(() => error);
          }
        }),
      );
  }

  public customAnswer(
    promptType: PromptsInChatType,
    answer: NextMessageAnswer,
  ): Observable<boolean> {
    const message = this.answerMessage$.value;
    this.answerMessage$.next(null);

    return this._promptsInChatService.setPrompt(promptType).pipe(
      map(() => {
        this._chatStateService.addMessagePartial({
          id: message.id,
          messageText: answer.messageText,
          localizedMessageText: answer.localizedMessageText,
          orderIdx: message.orderIdx,
        });
        this.answerSent.next(null);
        return true;
      }),
    );
  }

  public showNicknameInput(nextMessage: NextMessage): Observable<boolean> {
    this._analyticsService.showNicknameInput(this._chatStateService.chatId);

    return this._nicknameService.getNicknameFromPlatform().pipe(
      map((nickName) => {
        nickName = nickName ?? 'Pink Bunny';

        nextMessage.answers.push(
          {
            isNicknameInput: true,
            type: 'input',
            id: 0,
            costInGems: 0,
            messageText: '',
            localizedMessageText: null,
          },
          {
            isNicknameInput: true,
            id: 0,
            costInGems: 0,
            messageText: nickName,
            localizedMessageText: { en: nickName },
          },
        );
        this.answerMessage$.next(nextMessage);

        return true;
      }),
    );
  }

  public clear() {
    this.answerMessage$.next(null);
  }
}
