import { Pipe, PipeTransform } from '@angular/core';
import { AuthPayload } from '../enums/auth-payload.enum';

// Fast solution - replace all advertisment links form chats with utm_campaign = utm_source that stores in localstorage

@Pipe({
  name: 'addUtmContentAsUtmSource',
})
export class AddUtmContentAsUtmSourcePipe implements PipeTransform {
  transform(value: string): string {
    // The param was utm_content. Now p2
    let _value = value;
    _value = this.replaceUrlParam(
      _value,
      'p2',
      this.getUtmFromLocalStorage(AuthPayload.utmSource),
    );
    _value = this.replaceUrlParam(
      _value,
      'creativeId',
      this.getUtmFromLocalStorage(AuthPayload.utmContent),
    );
    _value = this.replaceUrlParam(
      _value,
      'p3',
      this.getUtmFromLocalStorage(AuthPayload.utmMedium),
    );

    return _value;
  }

  getUtmFromLocalStorage(source: AuthPayload) {
    const valueFromStorage = localStorage.getItem(source);
    return valueFromStorage || '';
  }

  replaceUrlParam(url, paramName, paramValue) {
    if (paramValue == null) {
      paramValue = '';
    }
    const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/, '');
    return (
      url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
    );
  }
}
