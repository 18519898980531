import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SettingsDataService } from '../../services/communication_services/settingsData.service';
import { UserPreference } from '../../enums/user-preference.enum';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'app-gender-select-modal',
  templateUrl: './gender-select-modal.component.html',
  styleUrls: ['./gender-select-modal.component.scss'],
})
export class GenderSelectModalComponent {
  public isBoys = false;
  public pending = false;

  constructor(
    private _modalController: ModalController,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
  ) {}

  public submit() {
    const preference = this.isBoys ? UserPreference.boy : UserPreference.girl;
    this.pending = true;
    this._settingsDataService.updateUserPreference(preference).subscribe({
      next: () => {
        this._analyticsService.setUserPreference(preference);
        this._modalController.dismiss();
      },
      error: () => (this.pending = false),
    });
  }
}
