import { ModalAnimationsService } from '../animations/modal-animations.service';
import { filter, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private _openedModals: ComponentRef[] = [];
  private _queue: HTMLIonModalElement[] = [];
  private _modalsShown = new Set<ComponentRef>();

  constructor(
    private _modalAnimationsService: ModalAnimationsService,
    private _modalController: ModalController,
    private _router: Router,
  ) {
    _router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap(() => {
          this._openedModals.forEach((m) => _modalController.dismiss(m));
          this._openedModals = [];
        }),
      )
      .subscribe();
  }

  public openModal<T = any>(
    component: ComponentRef,
    componentProps: ComponentProps<ComponentRef> = {},
    queue = false,
    canDismiss = true,
    oneTime = false,
  ): Observable<T> {
    if (
      this._openedModals.includes(component) ||
      this._queue.some((el) => el.component === component) ||
      (oneTime && this._modalsShown.has(component))
    ) {
      return of(null);
    }

    this._modalsShown.add(component);

    return from(
      this._modalController.create({
        component,
        componentProps,
        canDismiss,
        showBackdrop: true,
        cssClass: 'transparent-modal',
      }),
    ).pipe(
      take(1),
      switchMap((modal) => {
        if (queue && this._openedModals.length) {
          this._queue.push(modal);
        } else {
          this._openedModals.push(component);
          modal.present();
        }
        return from(modal.onDidDismiss()).pipe(
          map((res) => {
            this._openedModals = this._openedModals.filter(
              (c) => c !== component,
            );

            if (!this._openedModals.length && this._queue.length) {
              this._queue.shift().present();
            }

            return res.data;
          }),
        );
      }),
    );
  }
}
