import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { SettingsService } from 'src/app/shared/services/API_services/settings.service';
import {
  SplitTestCase,
  SplitTestCasesService,
} from './split-test-cases.service';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EnterNicknameModalComponent } from '../components/misc-alerts/enter-nickname-modal/enter-nickname-modal.component';
import { SettingsDataService } from './communication_services/settingsData.service';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ModalAnimationsService } from '../animations/modal-animations.service';

@Injectable({
  providedIn: 'root',
})
export class NicknameService {
  isInited = false;

  constructor(
    private _modalCtrl: ModalController,
    private _settingsDataService: SettingsDataService,
    private _splitTestCasesService: SplitTestCasesService,
    private _settingsService: SettingsService,
    private _analyticsService: AnalyticsService,
    private _modalAnimationsService: ModalAnimationsService,
  ) {}

  getNicknameFromPlatform(): Observable<string> {
    switch (environment.buildVersion) {
      case BuildVersion.erogames:
      case BuildVersion.nutaku:
        return this._settingsDataService.updateSettingsData$.pipe(
          filter((res) => !!res.nickName),
          take(1),
          map((res) => res.nickName),
        );
      default:
        return of(null);
    }
  }

  set(nickName: string, handWrited: boolean): Observable<void> {
    return this._settingsService.updateNickname(nickName).pipe(
      take(1),
      map(() => {
        this._settingsDataService.changeData('nickName', nickName);
        this._settingsDataService.nicknameIsSet = true;
        this._analyticsService.nicknameIsSet(
          nickName,
          'Footer answer',
          handWrited,
        );
        return;
      }),
    );
  }

  setModal(placeWhereItShows) {
    this._modalCtrl
      .create({
        component: EnterNicknameModalComponent,
        componentProps: {
          placeWhereItShows,
        },
        showBackdrop: true,
        cssClass: 'transparent-modal',
        // enterAnimation: this._modalAnimationsService.enterAnimation,
        // leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
      .then((res) => res.present());
  }

  init(isNickNameSet: boolean) {
    if (this.isInited) {
      return;
    }
    this.isInited = true;
    this._settingsDataService.nicknameIsSet = isNickNameSet;

    if (!isNickNameSet) {
      this.setModal('On Init');
    }
  }
}
