import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription-terms',
  templateUrl: './subscription-terms.component.html',
  standalone: true,
})
export class SubscriptionTermsComponent {
  public readonly environment = environment;
}
