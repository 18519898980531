import {
  NotificationType,
  SubscriptionStatus,
  UserNotification,
} from '../../types/user-my-response.interface';
// eslint-disable-next-line max-len
import { NotEnoughMoneyPopupComponent } from '../../components/payments/payment-modals/not-enough-money-popup/not-enough-money-popup.component';
// eslint-disable-next-line max-len
import { CommonNotificationModalComponent } from '../../components/misc-alerts/common-notification-modal/common-notification-modal.component';
import { PurchaseConfirmComponent } from '../../components/payments/payment-modals/purchase-confirm/purchase-confirm.component';
import { PremiumSuccessModalComponent } from '../../components/premium/success-modal/success-modal.component';
import { AuthMethodsService } from 'src/app/shared/services/auth/auth-methods.service';
import { LocalStorageService } from '../communication_services/localStorage.service';
import { SettingsDataService } from '../communication_services/settingsData.service';
import { PaymentResponeStatus } from '../../enums/payment-respone-status.enum';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';
import { PaymentResponse } from '../../types/payment-response.interface';
import { ModalController, ToastController } from '@ionic/angular';
import { AnalyticsService } from '../analytics/analytics.service';
import { PaymentsStateService } from './payments-state.service';
import { environment } from 'src/environments/environment';
import { OfferType } from '../../enums/offer-type.enum';
import { InventoryService } from '../inventory.service';
import { HttpClient } from '@angular/common/http';
import { ModalsService } from '../modals.service';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { LotType } from '../../enums/lot-type.enum';

@Injectable({
  providedIn: 'root',
})
export class PaymentsCommonMethodsService {
  private _host: string = environment.apiUrl;
  constructor(
    private _paymentsStateService: PaymentsStateService,
    private _localStorageService: LocalStorageService,
    private _settingsDataService: SettingsDataService,
    private _authMethodsService: AuthMethodsService,
    private _inventoryService: InventoryService,
    private _analyticsService: AnalyticsService,
    private _modalsService: ModalsService,
    private _modalCtrl: ModalController,
    private _toastCtrl: ToastController,
    private _http: HttpClient,
  ) {}

  openInCurrentWindow(res: PaymentResponse) {
    window.location.href = res.payment.url;
  }

  openInNewWindow(res: PaymentResponse) {
    window.open(res.payment.url, '_blank');
  }

  showNoFundsModal() {
    this._modalCtrl
      .create({
        component: NotEnoughMoneyPopupComponent,
        showBackdrop: true,
        cssClass: 'transparent-modal',
      })
      .then((res) => {
        res.present();
      });
  }

  async showConfirmationModal(cost) {
    const modal = await this._modalCtrl.create({
      component: PurchaseConfirmComponent,
      componentProps: {
        cost,
      },
      showBackdrop: true,
      cssClass: 'transparent-modal',
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    return res;
  }

  checkTransaction(transactionId: string = null, initialCheck = false): void {
    const id = transactionId || this._localStorageService.getTransactionId();

    if (!id) {
      return;
    }

    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;
    const requestUrl = `${this._host}/api/Shop/${id}`;
    this._http
      .get<PaymentResponse>(requestUrl)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.checkTransactionHandler(res, initialCheck);
        },
        () => {
          this._localStorageService.removeTransactionId();
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
        },
      );
  }

  checkTransactionHandler(res: PaymentResponse, initialCheck = false) {
    switch (res?.payment.status) {
      case PaymentResponeStatus.completed:
      case PaymentResponeStatus.success:
        this._successHandler(res);
        break;

      case PaymentResponeStatus.issued:
        this._issuedHandler(res);
        break;

      case PaymentResponeStatus.notFound:
        if (!initialCheck) {
          this._notFoundHandler(res);
        }
        break;

      case PaymentResponeStatus.redirect:
        this._paymentsStateService.paymentState = null;
        this._localStorageService.removeTransactionId();
        return false;
      default:
        this._localStorageService.removeTransactionId();
        this._paymentsStateService.paymentState = PaymentAnimationStates.error;
        return false;
    }
  }

  private async _notFoundHandler(res) {
    this._paymentsStateService.paymentState = null;
    const toast = await this._toastCtrl.create({
      message: `We didn't find this item in store`,
      duration: 2000,
    });
    await toast.present();
  }

  private _paymentAuth(paymentResponse: PaymentResponse) {
    console.log('_authMethodsService', this._authMethodsService);
    setTimeout(() => {
      this._authMethodsService
        .signByPayment(paymentResponse?.payment?.id)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this._modalCtrl
              .create({
                component: CommonNotificationModalComponent,
                componentProps: {
                  notification: {
                    id: paymentResponse.payment.id,
                    notificationType: NotificationType.paymentAuth,
                    pendingReward: null,
                    title: 'You are signed up!',
                    text: 'Check for your login and password in your email inbox.',
                  } as UserNotification,
                },
                showBackdrop: true,
                cssClass: 'transparent-modal',
                // enterAnimation: this._modalAnimationsService.enterAnimation,
                // leaveAnimation: this._modalAnimationsService.leaveAnimation,
              })
              .then((modal) => {
                modal.present();
              });
          }
        });
    }, 3000);
  }

  private _successHandler(res: PaymentResponse) {
    this._localStorageService.removeTransactionId();

    this._analyticsService.payment(
      res?.payment?.platform,
      res?.payment?.id,
      res?.payment?.cost,
      res?.payment?.productId,
      res?.payment?.lotType,
      res?.payment?.source,
      res?.payment?.meta,
      res?.payment?.offerType,
      res?.payment?.purchaseInfo,
      res?.payment?.status,
      res?.payment?.paymentNumber,
    );

    if (
      res.payment?.lotType === LotType.subscription &&
      this._settingsDataService.updateSettingsData$.value.subscriptionStatus !==
        SubscriptionStatus.active
    ) {
      this._modalsService.openModal(PremiumSuccessModalComponent).subscribe();

      this._settingsDataService.changeData(
        'subscriptionStatus',
        SubscriptionStatus.active,
      );
    }

    if (res?.payment.isAuthorization) {
      this._paymentAuth(res);
    }
    this._inventoryService.setInventory(res.inventory);
    this._paymentsStateService.paymentState = PaymentAnimationStates.success;
  }

  private async _issuedHandler(res: PaymentResponse) {
    this._analyticsService.paymentIssued(
      res?.payment?.id,
      res?.payment?.cost,
      res?.payment?.productId,
      res?.payment?.lotType,
      res?.payment?.source,
      res?.payment?.meta,
    );
    this._paymentsStateService.paymentState = PaymentAnimationStates.error;
    this._paymentsStateService.paymentState = null;
    const toast = await this._toastCtrl.create({
      message:
        'Something went wrong. Developers are working on this issue. You can also write to Bug Reporst thread in our Discord channel',
      duration: 2000,
      position: 'bottom',
    });
    await toast.present();
  }
}
