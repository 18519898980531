import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsubscribeHelper {
  public unsubscribe(subscribtions: Subscription[]): void {
    for (let i = 0; i < subscribtions.length; i++) {
      subscribtions[i]?.unsubscribe();
    }
  }
}
