import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GetChatRewardsResponse } from 'src/app/shared/services/API_services/chat-api-methods.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { AdmobService } from 'src/app/shared/services/admob.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReasonType } from 'src/app/shared/enums/reason-type.enum';
import { ChatService } from '../../services/chat.service';
import { environment } from 'src/environments/environment';
import { delay, take, map } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-is-finished-modal',
  templateUrl: './chat-is-finished-modal.component.html',
  styleUrls: ['./chat-is-finished-modal.component.scss'],
})
export class ChatIsFinishedModalComponent implements OnInit, OnDestroy {
  @Input()
  public askTranslationRating = false;

  public isRewardVideoCompleted = false;
  public environment = environment;
  public buildVersion = BuildVersion;
  form: UntypedFormGroup;
  loading = false;
  commonServerErrors = [];
  sendFormSub: Subscription;
  getRewardsSub: Subscription;
  @Input() chatId: string;
  rewardsData: GetChatRewardsResponse;
  private _subscribers: Subscription[] = [];

  constructor(
    private _unsubscribeHelper: UnsubscribeHelper,
    private _analyticsService: AnalyticsService,
    private formBuilder: UntypedFormBuilder,
    private _modalCtrl: ModalController,
    private _admobService: AdmobService,
    private _chatService: ChatService,
  ) {
    if (this.askTranslationRating) {
      this.form = this.formBuilder.group({
        // email: new FormControl('getthis@from.api', Validators.required),
        comment: new UntypedFormControl('', Validators.required),
        storyRating: new UntypedFormControl(null, Validators.required),
        girlRating: new UntypedFormControl(null, Validators.required),
        translateQuality: new UntypedFormControl(5, Validators.required),
      });
    } else {
      this.form = this.formBuilder.group({
        // email: new FormControl('getthis@from.api'),
        comment: new UntypedFormControl('', Validators.required),
        storyRating: new UntypedFormControl(null, Validators.required),
        girlRating: new UntypedFormControl(null, Validators.required),
        translateQuality: new UntypedFormControl(5),
      });
    }
  }

  ngOnInit() {
    this.getRewardsSub = this._chatService
      .getChatRewards(this.chatId)
      .subscribe((res) => {
        console.log(res);

        this.rewardsData = res;
      });
  }

  sendFeedback() {
    const { form } = this;
    const { value } = form;

    if (this.isRewardVideoCompleted) {
      value.bonusReason = ReasonType.adWatched;
    }

    if (form.valid) {
      this.loading = true;
      this._chatService
        .sendFeedbackForm(this.chatId, value)
        .pipe(delay(200), take(1))
        .subscribe({
          next: () => {
            this._analyticsService.chatFeedbackFormSent({
              chatId: this.chatId,
              storyRating: form.value.storyRating,
              girlRating: form.value.girlRating,
              translateQuality: form.value.translateQuality,
              bonusReason: this.isRewardVideoCompleted
                ? ReasonType.adWatched
                : undefined,
            });
            this.closeModal();
          },
          error: (err) => {
            this.loading = false;
            console.error(err);
          },
        });
    } else {
      this.form.markAllAsTouched();
    }
  }

  public showAd(): void {
    this._subscribers.push(
      this._admobService
        .showRewardVideo('chatFeedback')
        .pipe(
          map((isSuccess) => {
            if (isSuccess) {
              this.isRewardVideoCompleted = true;
            }
          }),
        )
        .subscribe(),
    );
  }

  closeModal() {
    this._modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.sendFormSub?.unsubscribe();
    this.getRewardsSub?.unsubscribe();
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }
}
