import { ButtonModule } from '../controls/button/button.module';
import { CheatMenuComponent } from './cheat-menu.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CheatMenuComponent],
  imports: [CommonModule, ButtonModule],
  exports: [CheatMenuComponent],
})
export class CheatMenuModule {}
