import { Component, HostBinding, Input } from '@angular/core';

type ButtonVariant = 'default' | 'flat' | 'ad' | 'submit';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  @HostBinding('class.disabled')
  @HostBinding('attr.disabled')
  public disabled = false;

  @Input()
  @HostBinding('class.pending')
  @HostBinding('attr.disabled')
  public pending = false;

  @Input()
  @HostBinding('class')
  public variant: ButtonVariant = 'default';
}
