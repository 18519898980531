<button
  *ngIf="variant === 'modal-variant'"
  (click)="close($event)"
  class="close-btn"
>
  <img src="/assets/img/modals/close-btn-black.svg" alt="" />
</button>

<div class="content">
  <img
    src="/assets/img/offers-new/discord/discord.svg"
    alt=""
    class="discord-icon"
  />
  <div class="info">
    Join our discord server and get<br />
    {{ quest.rewardCoins }}
    <img
      src="/assets/img/shop-page/tokens/tokens2.png"
      alt=""
      class="tokens-icon"
    />
    Tokens for FREE
  </div>

  <div class="spacer"></div>
  <div class="discord-btn">Join Discord</div>
</div>
