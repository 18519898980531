import {
  RealCostProperties,
  SoftCostProperties,
  RealPurchaseData,
  SoftPurchaseData,
} from '../../types/purchase-data.interface';
import { SettingsDataService } from '../communication_services/settingsData.service';
import { ErogamesPaymentService } from './../payments/erogames-payment.service';
import { UserDataService } from '../communication_services/userData.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PurchaseSource } from '../../enums/purchase-source.enum';
import { AnalyticsService } from '../analytics/analytics.service';
import { NutakuPaymentService } from './nutaku-payment.service';
import { PaymentMethod } from '../../enums/payment-method.enum';
import { LegalPaymentService } from './legal-payment.service';
import { SoftPaymentsService } from './soft-payments.service';
import { OfferData } from '../../types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { WebPaymentService } from './web-payment.service';
import { InventoryService } from '../inventory.service';
import { Observable, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

type ObjectWithPurchase = (ObjectWithRealPurchase | ObjectWithSoftPurchase) & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

interface ObjectWithRealPurchase {
  purchase: RealCostProperties;
  softPurchase?: never;
}
interface ObjectWithSoftPurchase {
  purchase?: never;
  softPurchase: SoftCostProperties;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(
    private _erogamesPaymentService: ErogamesPaymentService,
    private _nutakuPaymentService: NutakuPaymentService,
    private _legalPaymentService: LegalPaymentService,
    private _settingsDataService: SettingsDataService,
    private _softPaymentService: SoftPaymentsService,
    private _webPaymentService: WebPaymentService,
    private _analyticsService: AnalyticsService,
    private _inventoryService: InventoryService,
    private _userDataService: UserDataService,
    private _platform: Platform,
  ) {}

  public purchaseLot(
    lot: ObjectWithPurchase,
    source: PurchaseSource,
  ): Observable<boolean> {
    console.log('payments service Purchase lot method', lot);

    let paymentObservable: Observable<boolean>;

    if (lot.purchase) {
      paymentObservable = this.realPurchase({
        productId: lot.purchase.productId,
        purchase: lot.purchase,
        source,
        nutakuData: {
          nutakuName: lot.purchase.nutakuName,
          nutakuImageUrl: lot.purchase.nutakuImageUrl,
          nutakuDescription: lot.purchase.nutakuDescription,
        },
      });
    } else {
      paymentObservable = this._softPaymentService.purchaseNew(
        lot.softPurchase,
      );
    }

    return paymentObservable;
  }

  public purchaseOffer(
    offerData: OfferData,
    source: PurchaseSource,
  ): Observable<boolean> {
    return this.realPurchase({
      productId: offerData.purchase.productId,
      purchase: offerData.purchase,
      source,
      nutakuData: {
        nutakuName: 'Offer',
        nutakuDescription: '',
        nutakuImageUrl:
          'https://nutaku.getmynudes.com/assets/img/logo-gmn-for-nutaku.png',
      },
    });
  }

  /**
   * @deprecated Old purchase method
   */
  public purchase(
    data: RealPurchaseData | SoftPurchaseData,
  ): Observable<boolean> {
    console.log('payments service Purchase method', data);

    if (data.purchase) {
      return this.realPurchase(data as RealPurchaseData).pipe(
        switchMap((res) => {
          if (res && data.purchase.lotType === 'Subscription') {
            return this._settingsDataService
              .updateSubscriptionStatus()
              .pipe(map(() => res));
          }
          return of(res);
        }),
      );
    } else {
      return this.softPurchase(data as SoftPurchaseData);
    }
  }

  private realPurchase(data: RealPurchaseData): Observable<boolean> {
    this._analyticsService.clickOnPurchase({
      id: data.purchase.productId,
      source: data.source,
      offerType: data.purchase.offerType,
      lotType: data.purchase.lotType,
    });

    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        return this._erogamesPaymentService.purchase(data);
      case BuildVersion.nutaku:
        if (this._userDataService.nutakuDisplayVersion === 'pc') {
          return this._nutakuPaymentService.purchase(data); // PC version
        }
        if (this._platform.is('hybrid')) {
          return this._webPaymentService.purchase(
            data,
            PaymentMethod.nutakuClient,
          ); // APK version
        }
        return this._webPaymentService.purchase(data, PaymentMethod.nutakuSp); // // SP version
      case BuildVersion.legal:
        return this._legalPaymentService.purchase(data);
      default:
        return this._webPaymentService.purchase(data);
    }
  }

  private softPurchase(data: SoftPurchaseData): Observable<boolean> {
    return this._softPaymentService.purchase(data);
  }
}
