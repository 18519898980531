import { SaveProgressToastComponent } from './save-progress-toast.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [SaveProgressToastComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [SaveProgressToastComponent]
})
export class SaveProgressToastModule { }
