import { Component, OnDestroy, ViewChild, OnInit, Input } from '@angular/core';
import { PremiumUnsubscribeModalComponent } from '../premium-unsubscribe-modal/premium-unsubscribe-modal.component';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { SubscriptionStatus } from 'src/app/shared/types/user-my-response.interface';
import { PaymentsService } from 'src/app/shared/services/payments/payments.service';
import { PurchaseSource } from 'src/app/shared/enums/purchase-source.enum';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';
import { Subscription } from 'rxjs';
import {
  TermsModalComponent,
  TermsType,
} from '../../terms-modal/terms-modal.component';

@Component({
  selector: 'app-premium-modal',
  templateUrl: './premium-modal.component.html',
  styleUrls: ['./premium-modal.component.scss'],
})
export class PremiumModalComponent implements OnInit, OnDestroy {
  @Input() public subscriptionOffers: OfferData[];
  @Input() public callFrom: string; // For Analytics only

  public subscriptionStatus: SubscriptionStatus;
  public subscriptionStatuses = SubscriptionStatus;
  public environment = environment;
  public buildVersion = BuildVersion;

  public step: number;

  @ViewChild(SwiperComponent) paywallSwiper: SwiperComponent;
  private _subscribers: Subscription[] = [];
  private _openTime: number;

  constructor(
    private _settingsDataService: SettingsDataService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _analyticsService: AnalyticsService,
    private _modalController: ModalController,
    private _paymentsService: PaymentsService,
    private _modalsService: ModalsService,
  ) {}

  public ngOnInit(): void {
    console.log('paywall from ' + this.callFrom);

    this._openTime = Date.now();

    this._subscribers.push(
      this._settingsDataService.updateSettingsData$.subscribe((res) => {
        this.subscriptionStatus = res.subscriptionStatus;
        this._analyticsService.showPremiumAccountModalPage(
          this.subscriptionStatus,
          this.callFrom,
        );
      }),
    );
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }

  public purchase(offerData: OfferData): void {
    // prevent random press
    if (Date.now() - this._openTime < 1000) {
      return;
    }

    this._analyticsService.paywallClickTrial(offerData.type);

    this._subscribers.push(
      this._paymentsService
        .purchaseOffer(offerData, PurchaseSource.modal)
        .subscribe((res) => {
          if (res) {
            this.close(true);
          }
        }),
    );
  }

  public showUnsubscribeModal() {
    this._subscribers.push(
      this._modalsService
        .openModal(PremiumUnsubscribeModalComponent)
        .subscribe(),
    );
  }

  public openPolicyModal(): void {
    this._subscribers.push(
      this._modalsService
        .openModal(TermsModalComponent, {
          type: TermsType.privacy,
        })
        .subscribe(),
    );
  }

  public openTermsOfUseModal(): void {
    this._subscribers.push(
      this._modalsService
        .openModal(TermsModalComponent, {
          type: TermsType.termsOfUse,
        })
        .subscribe(),
    );
  }

  public openSubscriptionTermsModal(): void {
    this._subscribers.push(
      this._modalsService
        .openModal(TermsModalComponent, {
          type: TermsType.subscription,
        })
        .subscribe(),
    );
  }

  public close(action: boolean = false): void {
    this._analyticsService.premiumModalClose();
    this._modalController.dismiss(action);
  }
}
