import { PremiumButtonTextModule } from '../button-text/premium-button-text.module';
import { PriceBlockModule } from '../../price-block/price-block.module';
import { ButtonModule } from '../../controls/button/button.module';
import { PremiumBlockComponent } from './premium-block.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [PremiumBlockComponent],
  imports: [
    PremiumButtonTextModule,
    PriceBlockModule,
    TranslateModule,
    CommonModule,
    ButtonModule,
  ],
  exports: [PremiumBlockComponent],
})
export class PremiumBlockModule {}
