import { Component, Input } from '@angular/core';
import { ChatStateService } from '../services/chat-state.service';
import { CommonModule } from '@angular/common';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-ai-rating-counter',
  templateUrl: './ai-rating-counter.component.html',
  styleUrls: ['./ai-rating-counter.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AiRatingCounterComponent {
  @Input() public photosCounterEnabled = true;

  public aiChatRating$ = this._chatStateService.aiChatRating$;
  public aiChatContentInfo$ = this._chatStateService.aiChatContentInfo$;
  public ratingProgress$ = this._chatStateService.aiChatRating$.pipe(
    map((x) => {
      if (x.rating > x.nextPhotoRating) {
        return 100;
      }
      return (
        ((x.rating - x.prevPhotoRating) /
          (x.nextPhotoRating - x.prevPhotoRating)) *
        100
      );
    }),
  );

  constructor(
    private _chatStateService: ChatStateService,
    private _modalsService: ModalsService,
  ) {}
}
