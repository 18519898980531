import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { ChatByIdResponse } from '../../types/chat-by-id-response.interface';
import { OpenNextMessage } from '../../types/open-next-message.interface';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { LocalizedText } from '../../types/localized-text.interface';
import { ChatData } from '../../types/chat-data.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface ChooseAnswerResponse {
  chatRating: number;
  isBanned: boolean;
  messageId: string;
  emoji: number;
  coins: number;
  aiTokens?: number;
}

export interface UseGiftResponse {
  chatRating: number;
  ratingChange: number;
  chatLevel: number;
  chatLevelChange: number;
  coins: number;
}

export interface BuyChatByGemsResponse {
  coins: number;
  chatId: string;
}

export interface GetChatRewardsResponse {
  score: number;
  rewardItems: {
    itemId: string;
    name: string;
    localizedName: LocalizedText;
    count: number;
    imageUrl: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class ChatApiMethodsService {
  private _chooseAnswerInProgress = false;
  constructor(private _websocketSignalRService: WebsocketSignalRService) {}

  public getNextMessagePackFromAPI(
    chatId: string,
  ): Observable<OpenNextMessage[]> {
    // Avoid overlaping this request to response-answer request. @TODO refactor on BE-FE

    if (!this._chooseAnswerInProgress) {
      return this._websocketSignalRService.invoke<OpenNextMessage[]>(
        WebsocketCommandType.nextMessages,
        { chatId },
      );
      // const requestUrl = `${this._host}/api/chats/dialogs/open-next-message`;
      // return this._http.put<OpenNextMessage[]>(requestUrl, { chatId });
    } else {
      return of(null);
    }
  }

  public getAllChats(): Observable<ChatData[]> {
    const command = {};
    return this._websocketSignalRService.invoke<ChatData[]>(
      WebsocketCommandType.myChats,
      command,
    );
    // const requestUrl = `${this._host}/api/chats`;
    // return this._http
    //   .get<GetAllChatsResponse>(requestUrl);
  }

  public useGift(giftId: string, chatId: string): Observable<UseGiftResponse> {
    return this._websocketSignalRService.invoke<UseGiftResponse>(
      WebsocketCommandType.useGift,
      { chatId, giftId },
    );
    // const requestUrl = `${this._host}/api/Chats/use-gift`;
    // return this._http.put<UseGiftResponse>(requestUrl, { chatId, giftId });
  }

  public buyChatByGems(chatId: string): Observable<BuyChatByGemsResponse> {
    return this._websocketSignalRService.invoke<BuyChatByGemsResponse>(
      WebsocketCommandType.buySoftChat,
      { chatId },
    );
    // const requestUrl = `${this._host}/api/Chats/buy-soft-chat`;
    // return this._http.put<BuyChatByGemsResponse>(requestUrl, { chatId });
  }
}
