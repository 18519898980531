export enum OfferType {
  fiveChats = 'FiveChats',
  threeChats = 'ThreeChats',
  oneChatAndGems = 'OneChatAndGems',
  hiddenFiveChats = 'HiddenFiveChats',
  oneCheapChat = 'OneCheapChat',
  weeklySubscription = 'WeeklySubscription',
  monthlySubscription = 'MonthlySubscription',
  yearlySubscription = 'YearlySubscription',
  twoChatsForNewYear = 'TwoChatsForNewYear',
  lifetimeSubscription = 'LifetimeSubscription',
  valentineDaySmall = 'ValentineDaySmall',
  valentineDayBig = 'ValentineDayBig',
  twoHotLesbianChats = 'TwoHotLesbianChats',
  threeGermanChats = 'ThreeGermanChats',
  springBreakSmall = 'SpringBreakSmall',
  springBreakBig = 'SpringBreakBig',
  starterPack = 'StarterPack',
  bigPack = 'BigPack',
  springCleaningOffer = 'SpringCleaningOffer',
  springCleaningOfferBig = 'SpringCleaningOfferBig',
  summerKickoffOffer = 'SummerKickoffOffer',
  summerKickoffOfferBig = 'SummerKickoffOfferBig',
  dominanceEventBigOffer = 'DominanceEventBigOffer',
  dominanceEventTwoChatsOffer = 'DominanceEventTwoChatsOffer',
  sweaterWeatherOffer2023 = 'SweaterWeatherOffer2023',
  monsterGirl = 'MonsterGirlOffer',
  winterOfferNY2024 = 'WinterOfferNY2024',
  christmasOfferNY2024 = 'ChristmasOfferNY2024',
  nutakuBirthday2024 = 'NutakuBirthday2024',
  valentineDay2024 = 'ValentineDay2024',
  discord = 'Discord',
  onlyfans = 'Onlyfans',
  bigBreastsAndFlatChestsEvent = 'BigBreastsAndFlatChestsEvent',
  easter2024 = 'Easter2024',
  goldenWeek = 'GoldenWeek',
}
