import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';
import { WebsocketSignalRService } from './websocket-signalr.service';
import { Injectable } from '@angular/core';

export type InventoryItem = {
  count: number;
  id: InventoryItemId;
  type: InventoryItemType;
};

export type InventoryItemType = 'Gem' | 'Gift' | 'Swipe' | 'SkipOffline';

const inventoryItemId = [
  'Gem',
  'PerfumeGift',
  'FlowerGift',
  'DiamondRingGift',
  'Swipe',
  'SkipOffline',
  'SlotEnergy',
] as const;

export type InventoryItemId = Uncapitalize<(typeof inventoryItemId)[number]>;

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  public readonly items: Record<`${InventoryItemId}$`, BehaviorSubject<number>>;
  public readonly gems$: Observable<number>;

  public get gems() {
    return this.items.gem$.value;
  }

  constructor(private _websocketSignalRService: WebsocketSignalRService) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.items = {} as any;

    inventoryItemId.forEach((id) => {
      const fieldName = `${this.uncapitalize(id)}$`;
      this.items[fieldName] = new BehaviorSubject(0);
    });

    this.gems$ = this.items.gem$.pipe(distinctUntilChanged());
  }

  public setInventory(items: InventoryItem[]) {
    this.resetData();

    items.forEach((item) => {
      this.items[`${this.uncapitalize(item.id)}$`].next(item.count);
    });
  }

  public setCount(type: InventoryItemId, count: number) {
    this.items[`${type}$`].next(count);
  }

  public setGems(value: number) {
    this.items.gem$.next(value);
  }

  private resetData() {
    for (const key in this.items) {
      if (this.items[key]) {
        this.items[key].next(0);
      }
    }
  }

  private uncapitalize(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
}
