import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-18usc',
  templateUrl: './18usc.component.html',
  standalone: true,
})
export class USC18Component {
  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;
  public readonly isIos = this._platform.is('ios');
  public readonly isAndroid = this._platform.is('android');

  constructor(private _platform: Platform) {}
}
