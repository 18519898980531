import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { PaymentsService } from 'src/app/shared/services/payments/payments.service';
import { RealPurchaseData } from 'src/app/shared/types/purchase-data.interface';
import { PurchaseSource } from 'src/app/shared/enums/purchase-source.enum';
import { RealCostProperties } from './../../../types/purchase-data.interface';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PaywallService } from 'src/app/shared/services/paywall.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trial-purchase-modal',
  templateUrl: './trial-purchase-modal.component.html',
  styleUrls: ['./trial-purchase-modal.component.scss'],
})
export class TrialPurchaseModalComponent implements OnInit, OnDestroy {
  show = false;
  loading = false;
  @Input() trialData: RealCostProperties = null;
  @Input() subscriptionPurchaseData: RealCostProperties = null;
  @Input() chatName = '';
  @Input() avatar: string;
  @Input() chatId: string;
  @Input() messagesCount: number;
  private _subscribers: Subscription[] = [];
  environment = environment;
  buildVersion = BuildVersion;
  canClose = true;
  public premiumOfferData: OfferData;

  constructor(
    private _unsubscribeHelper: UnsubscribeHelper,
    private _analyticsService: AnalyticsService,
    private _paymentsService: PaymentsService,
    private _paywallService: PaywallService,
    private _modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this._analyticsService.trialPurchaseModalAppeared(
      this.chatId,
      this.messagesCount,
    );

    this._paywallService
      .getMonthlyPremiumData()
      .subscribe((res) => (this.premiumOfferData = res));
  }

  closeModal(status) {
    this._modalCtrl.dismiss(status);
  }

  onClickBuy() {
    this.canClose = false;
    this.loading = true;
    this._analyticsService.trialPurchaseClickBuy(
      this.chatId,
      this.messagesCount,
    );
    const data: RealPurchaseData = {
      chatId: this.chatId,
      source: PurchaseSource.modal,
      purchase: this.trialData,
      productId: this.trialData.productId,
    };
    if (environment.buildVersion === BuildVersion.nutaku) {
      data.nutakuData = {
        nutakuName: 'Chat with ' + this.chatName,
        nutakuDescription: '',
        nutakuImageUrl: this.avatar,
      };
    }
    this._subscribers.push(
      this._paymentsService.purchase(data).subscribe({
        next: (res) => {
          if (res) {
            this.closeModal(res);
          }
          this.loading = false;
          this.canClose = true;
        },
        error: (err) => {
          this.loading = false;
          this.canClose = true;
          if (err?.status === 403) {
            this.closeModal(false);
          }
        },
      }),
    );
  }

  onClickSubscribe() {
    this._paymentsService
      .purchaseOffer(this.premiumOfferData, PurchaseSource.modal)
      .subscribe((res) => {
        if (res) {
          this.closeModal(res);
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }
}
