import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewPhotoNotificationComponent } from './new-photo-notification.component';

@NgModule({
  declarations: [NewPhotoNotificationComponent],
  imports: [CommonModule],
  exports: [NewPhotoNotificationComponent],
})
export class NewPhotoNotificationModule {}
