import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { AuthStateService } from '../auth/auth-state.service';
import { TutorialService } from './tutorial.service';

@Injectable({
  providedIn: 'root',
})
export class SaveProgressToastService {
  public isShowSaveProgress$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  private _firstSkipped = false;

  constructor(
    private _authStateService: AuthStateService,
    private _tutorialService: TutorialService,
  ) {}

  public showIfIsGuest() {
    if (this._tutorialService.currentStep$.value) {
      return;
    }

    if (!this._firstSkipped) {
      this._firstSkipped = true;
      return;
    }

    this._authStateService.isGuest$.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.isShowSaveProgress$.next(true);
      }
    });
  }
}
