<div
  *ngIf="{
    rating: aiChatRating$ | async,
    contentInfo: aiChatContentInfo$ | async
  } as data"
  class="rating-panel"
>
  <div class="rating">{{ data.rating.rating }}</div>

  <div class="rating-bar">
    <div class="fill" [style.width]="(ratingProgress$ | async) + '%'"></div>
    <img
      src="/assets/img/chat-page/photos-icon.png"
      alt=""
      class="photos-icon"
    />
  </div>

  @if (photosCounterEnabled) {
    <span class="photos-counter">
      <span class="current"> {{ data.contentInfo.unlockedContentCount }}</span>
      / {{ data.contentInfo.totalContentCount }}
    </span>
  }
</div>
