<div
  #popupEl
  (click)="goToProfile()"
  class="levelup-popup"
  [class.visible]="isVisible"
>
  <img src="/assets/img/icon/sparkles.png" alt="" />
  <div>
    <p class="title">{{ title }}</p>
    <p class="description text-gray">Tap to claim your reward</p>
  </div>
</div>
