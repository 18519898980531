import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { UserMyResponse } from '../../types/user-my-response.interface';
import { SubscriptionInfo } from '../../types/subscription-info.interface';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { SettingsService } from '../API_services/settings.service';
import { UserPreference } from '../../enums/user-preference.enum';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { Language } from '../../enums/language.enum';
import { Injectable } from '@angular/core';
import settings from '../../data/settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsDataService {
  private data: UserMyResponse;

  public serverLogs = false;

  private defaultData: UserMyResponse = {
    avatar: '',
    email: '',
    id: '',
    music: false,
    isGuest: null,
    nickName: '',
    isNickNameSet: false,
    isAgeConfirmed: false,
    isAgreementAccepted: false,
    sound: false,
    inventory: [],
    quickMessaging: settings.quickMessaging,
    onlineStreamNotifications: settings.onlineStreamNotifications,
    subscriptionStatus: SubscriptionStatus.none,
    serverPlatform: null,
    ve: 0,
    vd: 0,
    vn: 0,
    vg: 0,
    va: 0,
    tutorialIsPassed: null,
    tutorialStep: null,
    lang: Language.english,
    config: {
      serverLogs: false,
      paywallShowOnCards: false,
      paywallShowOnStart: false,
      paywallShowOnTrial: false,
      paywallShowOnOffline: false,
      paywallShowOnSwipesLimit: false,
    },
    preference: UserPreference.none,
    messageCostInTokens: 30,
  };
  public clientId = '';
  public readonly updateSettingsData$: BehaviorSubject<UserMyResponse>;
  public readonly isPremiumActive$: Observable<boolean>;
  public nicknameIsSet = false;

  public get isPremiumActive() {
    return (
      this.updateSettingsData$.value.subscriptionStatus ===
      SubscriptionStatus.active
    );
  }

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _settingsService: SettingsService,
  ) {
    this.updateSettingsData$ = new BehaviorSubject(this.defaultData);
    this.isPremiumActive$ = this.updateSettingsData$.pipe(
      map((data) => data.subscriptionStatus === SubscriptionStatus.active),
    );
  }

  public changeData(fieldName: keyof UserMyResponse, value: any): void {
    const defaultData = this.setNewData(this.data, fieldName, value);
    this.data = defaultData;
    this.updateSettingsData$.next(defaultData);
    this._settingsService.updateSettings({
      music: this.data.music,
      sound: this.data.sound,
      nickname: this.data.nickName,
      quickMessaging: this.data.quickMessaging,
      onlineStreamNotifications: this.data.onlineStreamNotifications,
      subscriptionStatus: this.data.subscriptionStatus,
    });
  }

  public updateSubscriptionStatus() {
    return this._websocketSignalRService
      .invoke<SubscriptionInfo>(WebsocketCommandType.getSubscriptionStatus, {})
      .pipe(
        map((res) => {
          this.changeData('subscriptionStatus', res.status);
        }),
      );
  }

  public updateUserPreference(preference: UserPreference) {
    return this._websocketSignalRService
      .invoke<SubscriptionInfo>(WebsocketCommandType.setUserPreference, {
        userPreference: preference,
      })
      .pipe(
        tap(() => {
          this.changeData('preference', preference);
        }),
      );
  }

  public setNewData(obj: any, field: string, value: any): any {
    const setPath = (object: any, path: any, val: any) =>
      path
        .split('.')
        .reduce(
          (o: any, p: any, i: any) =>
            (o[p] = path.split('.').length === ++i ? val : o[p] || {}),
          object,
        );
    setPath(obj, field, value);
    return obj;
  }

  public changeAllObject(value: any): void {
    this.data = value;
    this.updateSettingsData$.next(value);
  }

  public cleanData(): void {
    this.data = this.defaultData;
    this.updateSettingsData$.next(this.data);
  }
}
