import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  HostBinding,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';
import { UserPreference } from 'src/app/shared/enums/user-preference.enum';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-subscriptions-catgirl-offer',
  templateUrl: './catgirl.component.html',
  styleUrls: ['./catgirl.component.scss'],
})
export class SubscriptionsCatGirlTemplateComponent implements OnInit {
  @ViewChild('swiper', { static: false }) public swiper?: SwiperComponent;
  @Input() public subscriptionOffers: OfferData[];
  @Input()
  @HostBinding('class.is-modal')
  public isModal = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter();
  @Output() public openPolicy = new EventEmitter();
  @Output() public openTerms = new EventEmitter();
  @Output() public onPurchase = new EventEmitter<OfferData>();

  public subscriptions: OfferData[] = [];

  public selectedSubscription: OfferData;

  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;
  public readonly offerType = OfferType;

  public readonly swiperConfig: SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 3,
    loop: true,
    allowTouchMove: false,
    centeredSlides: true,
    slideToClickedSlide: true,
    on: {
      activeIndexChange: (e) => {
        this.selectSubscription(this.subscriptions[e.activeIndex % 3]);
        this._changeDetection.detectChanges();
      },
    },
  };

  @HostBinding('class.boys')
  public isBoys = false;

  constructor(
    private _settingsDataService: SettingsDataService,
    private _changeDetection: ChangeDetectorRef,
  ) {
    this._settingsDataService.updateSettingsData$.subscribe((data) => {
      this.isBoys = data.preference === UserPreference.boy;
    });
  }

  public clickOnSlide(subscription: OfferData) {
    const selectedIndex = this.subscriptions.findIndex(
      (s) => s === this.selectedSubscription,
    );
    const clickIndex = this.subscriptions.findIndex((s) => s === subscription);

    if (clickIndex === selectedIndex) {
      return;
    }
    if (
      (clickIndex === 1 && selectedIndex === 2) ||
      (clickIndex === 2 && selectedIndex === 0) ||
      (clickIndex === 0 && selectedIndex === 1)
    ) {
      this.swiper.swiperRef.slidePrev();
    } else {
      this.swiper.swiperRef.slideNext();
    }

    // this.selectSubscription(subscription);
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.subscriptionOffers.find(
        (o) => o.type === OfferType.monthlySubscription,
      ),
      this.subscriptionOffers.find(
        (o) => o.type === OfferType.weeklySubscription,
      ),
      this.subscriptionOffers.find(
        (o) => o.type === OfferType.yearlySubscription,
      ),
    );

    this.selectedSubscription = this.subscriptions[1] ?? this.subscriptions[0];
  }

  public selectSubscription(subscription: OfferData): void {
    this.selectedSubscription = subscription;
  }

  public purchase() {
    this.onPurchase.emit(this.selectedSubscription);
  }
}
