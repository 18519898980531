import { DailyRewardsModalComponent } from './daily-rewards-modal.component';
import { DailyRewardComponent } from './daily-reward/daily-reward.component';
import { ButtonModule } from '../controls/button/button.module';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [DailyRewardsModalComponent, DailyRewardComponent],
  imports: [
    TranslateModule,
    CommonModule,
    SwiperModule,
    ButtonModule,
    PipesModule,
  ],
  exports: [DailyRewardsModalComponent],
})
export class DailyRewardsModalModule {}
