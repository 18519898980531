<div class="modals-root">
  <div class="modal_wrapper">
    <app-discord-offer
      (closeModal)="close()"
      variant="modal-variant"
      class="content"
    ></app-discord-offer>

    <div (click)="close()" class="close-area"></div>
  </div>
</div>
