import { UserQuestLinesResponse } from '../../types/user-quest-lines-response.interface';
import { NextBonusTimeService } from '../communication_services/nextBonusTime.service';
import { BonuseDataService } from '../communication_services/bonusData.service';
import { PromoBonusInfoResponse } from '../../types/promo-bonus-info-response';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { UiStateService } from '../communication_services/uiStates.service';
import { JWTTokenService } from '../communication_services/JWTToken.service';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { ReasonType } from '../../enums/reason-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { InventoryService } from '../inventory.service';
import { HttpClient } from '@angular/common/http';
import { ToastsService } from '../toasts.service';
import { UiState } from '../../types/UiState';
import { Injectable } from '@angular/core';
import { ModalsService } from '../modals.service';
import { DiscordModalComponent } from '../../components/discord-modal/discord-modal.component';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class BonusService {
  private _host: string = environment.apiUrl;
  private isGetTime = false;
  public state: UiState = null;
  private _questLines: BehaviorSubject<UserQuestLinesResponse[]> =
    new BehaviorSubject(null);
  private _lastUpdateTime = 0;
  private _isDiscordModalShown = false;
  private _isDiscordRewardRequested = false;

  public readonly unclaimedRewardsCount$ = this._questLines.pipe(
    map((res) =>
      res ? res.filter((q) => q.completed && !q.rewardReceived).length : 0,
    ),
  );

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _nextBonusTimeService: NextBonusTimeService,
    private _bonuseDataService: BonuseDataService,
    private _translateService: TranslateService,
    private _inventoryService: InventoryService,
    private _uiStateService: UiStateService,
    private _tokenService: JWTTokenService,
    private _toastsService: ToastsService,
    private _modalsService: ModalsService,
    private _http: HttpClient,
  ) {
    this._nextBonusTimeService.updateNextBonusTime.subscribe((res) => {
      if (res.numb !== null) {
        this.isGetTime = true;
      } else {
        this.isGetTime = false;
      }
    });

    this._uiStateService.updateStateData.subscribe(async (res: UiState) => {
      this.state = res;
    });

    timer(0, 30000)
      .pipe(switchMap(() => this.getAllBonuses().pipe(take(1))))
      .subscribe();
  }

  public getAllBonuses(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke(WebsocketCommandType.getRewards, {})
      .pipe(
        map((res) => {
          this.saveData(res);
          return true;
        }),
      );
  }

  public getTime(getAnyway: boolean = false): Observable<boolean> {
    if (!this.isGetTime || getAnyway) {
      if (this._tokenService.getToken()) {
        return this._websocketSignalRService
          .invoke<{ time: number }>(WebsocketCommandType.getTimeReward, {})
          .pipe(
            map((res) => {
              this.saveTime(res.time);
              return true;
            }),
          );
      } else {
        return of(false);
      }
    } else {
      return of(true);
    }
  }

  public getBonuse(adWatched: boolean): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<{ coins: number }>(WebsocketCommandType.collectReward, {
        bonusReason: adWatched ? ReasonType.adWatched : undefined,
      })
      .pipe(
        map((res) => {
          this._inventoryService.setGems(res.coins);
          return true;
        }),
      );
  }

  public getPromoBonusInfo(promoCode): Observable<PromoBonusInfoResponse> {
    return this._websocketSignalRService.invoke<PromoBonusInfoResponse>(
      WebsocketCommandType.getPromoCode,
      { promoCode },
    );
  }

  public getPromoBonus(
    promoCode: string,
    promocodeGems: number,
  ): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<{ gems: number }>(WebsocketCommandType.activatePromoCode, {
        promoCode,
      })
      .pipe(
        map((res) => {
          this._inventoryService.setGems(res.gems);

          if (promocodeGems) {
            this._toastsService.showMessage(
              this._translateService.instant('BONUS_PAGE.COINS_RECEIVED', {
                value: promocodeGems,
              }),
            );
          }

          return true;
        }),
      );
  }

  public getRewardsBonus(id: string): Observable<boolean> {
    const requestUrl = `${this._host}/api/PendingReward/collect`;
    return this._http
      .post<{ totalGems: number; addedGems: number }>(requestUrl, { id })
      .pipe(
        tap((res) => {
          this._inventoryService.setGems(res.totalGems);
        }),
        map(() => true),
      );
  }

  get questLines(): Observable<UserQuestLinesResponse[]> {
    return this._questLines.asObservable();
  }

  public fetchQuestLines(force = false): Observable<boolean> {
    if (!force && Date.now() - this._lastUpdateTime < 5000) {
      return of(false);
    }

    this._lastUpdateTime = Date.now();

    return this._websocketSignalRService
      .invoke<UserQuestLinesResponse[]>(WebsocketCommandType.getQuests, {})
      .pipe(
        take(1),
        map((res) => {
          this._questLines.next(res);
          return true;
        }),
      );
  }

  public collectQuestLineReward(questId: string): Observable<{
    addedGems: number;
    totalGems: number;
  }> {
    return this._websocketSignalRService
      .invoke<{
        addedGems: number;
        totalGems: number;
      }>(WebsocketCommandType.takeQuestReward, { questId })
      .pipe(
        take(1),
        switchMap((res) => this.fetchQuestLines(true).pipe(map(() => res))),
        tap((res) => {
          if (res.addedGems) {
            this._toastsService.showMessage(
              this._translateService.instant('BONUS_PAGE.COINS_RECEIVED', {
                value: res.addedGems,
              }),
            );
          }
          this._inventoryService.setGems(res.totalGems);
        }),
      );
  }

  public questLineAcceptation(questId: string): Observable<{
    addedGems: number;
    totalGems: number;
  }> {
    return this._websocketSignalRService
      .invoke<{
        addedGems: number;
        totalGems: number;
      }>(WebsocketCommandType.manualQuestCompletion, { questId })
      .pipe(
        take(1),
        switchMap((res) => this.fetchQuestLines(true).pipe(map(() => res))),
        tap((res) => {
          if (res?.totalGems) {
            this._inventoryService.setGems(res.totalGems);
          }
        }),
      );
  }

  private saveData(data: any): void {
    this._bonuseDataService.setAllData(data);
  }

  private saveTime(time: number): void {
    this._nextBonusTimeService.changeTime(time);
  }

  public getDiscordQuest(): UserQuestLinesResponse {
    const discordQuest = this._questLines.value.find(
      (ql) => ql.name === 'GoToDiscord',
    );

    if (
      !discordQuest ||
      discordQuest.rewardReceived ||
      this._isDiscordRewardRequested
    ) {
      return null;
    }

    if (discordQuest.completed && !discordQuest.rewardReceived) {
      this._isDiscordRewardRequested = true;
      this.collectQuestLineReward(discordQuest.id).pipe(take(1)).subscribe();
      return null;
    }
    return discordQuest;
  }

  public showDiscordModal(): Observable<boolean> {
    const quest = this.getDiscordQuest();
    if (quest && !this._isDiscordModalShown) {
      this._isDiscordModalShown = true;
      return this._modalsService.openModal(DiscordModalComponent, {
        link: quest.action.data,
      });
    } else {
      return of(null);
    }
  }
}
