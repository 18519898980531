import {
  ChangeDetectorRef,
  EventEmitter,
  Component,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SettingsDataService } from '../../services/communication_services/settingsData.service';
import { ChatImagesService } from 'src/app/shared/views/chat/services/chat-images.service';
import { TutorialService } from '../../services/communication_services/tutorial.service';
import { UiStateService } from '../../services/communication_services/uiStates.service';
import { PremiumModalComponent } from '../premium/modal/premium-modal.component';
import { ChatStateService } from '../../views/chat/services/chat-state.service';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { SoundsService } from './../../services/sounds.service';
import { UiStatesEnum } from './../../enums/ui-states.enum';
import { environment } from 'src/environments/environment';
import { ModalController, Platform } from '@ionic/angular';
import { NavHelper } from '../../helpers/nav.helper';
import { SoundsEnum } from '../../enums/sounds.enum';
import { Subscription, filter, take, throwError } from 'rxjs';
import { UiStates } from '../../enums/uiStates';
import { UiState } from '../../types/UiState';
import {
  SplitTestCase,
  SplitTestCasesService,
} from '../../services/split-test-cases.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() public backButtonClick = new EventEmitter();

  public readonly uiStatesEnum = UiStatesEnum;
  public readonly stateEnum = UiStates;
  public readonly environment = environment;
  public readonly subscriptionStatuses = SubscriptionStatus;
  public readonly isIos =
    this._platform.is('ios') && !this._platform.is('mobileweb');
  public readonly isTopSpacer = this.isIos;
  public readonly tutorialStep$ = this._tutorialService.currentStep$;
  public readonly newImagesCount$ = this._chatImagesService.newImagesCount$;
  public readonly isAiChat$ = this.chatStateService.isAiChat$;
  public readonly chatState$ = this.chatStateService.chatState$;

  public state: UiState = null;
  public subscriptionStatus: SubscriptionStatus;
  public isAiGamifyTest = false;

  private _subscribers: Subscription[] = [];
  private _isPremiumModalInstanceOpen = false;

  constructor(
    public chatStateService: ChatStateService,
    private _settingsDataService: SettingsDataService,
    private _splitTestService: SplitTestCasesService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _chatImagesService: ChatImagesService,
    private _tutorialService: TutorialService,
    private _modalController: ModalController,
    private _uiStateService: UiStateService,
    private soundsService: SoundsService,
    private _cdr: ChangeDetectorRef,
    private _navHelper: NavHelper,
    private _platform: Platform,
  ) {}

  public ngOnInit() {
    this._uiStateService.updateStateData.subscribe(async (res: UiState) => {
      this.state = res;
      this._cdr.detectChanges();
    });

    this._splitTestService.splitTestInitialized
      .pipe(
        filter((x) => x),
        take(1),
      )
      .subscribe(() => {
        this.isAiGamifyTest = this._splitTestService.hasCase(
          SplitTestCase.gamifyAi,
        );
      });

    this._subscribers.push(
      this._settingsDataService.updateSettingsData$.subscribe((res) => {
        this.subscriptionStatus = res.subscriptionStatus;
      }),
    );
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }

  public playPing1() {
    this.soundsService.play(SoundsEnum.coins);
  }

  public back(): void {
    this.backButtonClick.emit();

    if (this.state[UiStatesEnum.state] === 'chat') {
      this._navHelper.goToAllChats();
    } else if (this.state[UiStatesEnum.state] === 'album') {
      this._navHelper.goToGallery();
    } else if (this.state[UiStatesEnum.state] === 'profile') {
      this._navHelper.goToChat(this.state[UiStatesEnum.chatId]);
    }
  }

  public gotoProfile(chatId: string) {
    if (!chatId) {
      throwError(() => 'Cannot go to Profile. No ChatID.');
    }

    this._navHelper.goToProfile(chatId);
  }

  public async openPremiumModal(): Promise<void> {
    if (
      this._isPremiumModalInstanceOpen ||
      environment.buildVersion === BuildVersion.legal
    ) {
      return;
    }

    this._isPremiumModalInstanceOpen = true;
    const premiumModalInstance = await this._modalController.create({
      component: PremiumModalComponent,
      showBackdrop: true,
      cssClass: 'transparent-modal',
      componentProps: {
        callFrom: 'header',
      },
    });

    premiumModalInstance.present();

    await premiumModalInstance.onDidDismiss();
    this._isPremiumModalInstanceOpen = false;
  }
}
