import { BuildVersion } from 'src/environments/environment-model.interface';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-confirm',
  templateUrl: './purchase-confirm.component.html',
  styleUrls: ['./purchase-confirm.component.scss'],
})
export class PurchaseConfirmComponent {
  @Input() public cost: number = null;
  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;

  constructor(private _modalCtrl: ModalController) {}

  confirm() {
    this._modalCtrl.dismiss(true);
  }

  cancel() {
    this._modalCtrl.dismiss(false);
  }
}
