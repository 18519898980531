import { Component, Input } from '@angular/core';
import { OfferTemplatesModule } from '../offer-tempaltes/offer-templates.module';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-discord-modal',
  templateUrl: './discord-modal.component.html',
  styleUrls: ['./discord-modal.component.scss'],
  standalone: true,
  imports: [OfferTemplatesModule],
})
export class DiscordModalComponent {
  @Input()
  public link: string;

  constructor(private _modalCtrl: ModalController) {}

  public close() {
    this._modalCtrl.dismiss();
  }
}
