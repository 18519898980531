<div class="offline-modal">
  <button (click)="close()" class="close-btn">&times;</button>

  <h2 class="title">
    <img src="/assets/img/chat-page/ai-offline-modal/emoji.svg" alt="" />
    {{ modelName$ | async }} is offline
  </h2>

  <p class="description text-gray">
    We have some problems with a server.<br />Please try again soon.
  </p>

  <img
    src="/assets/img/chat-page/ai-offline-modal/no-connection.svg"
    alt=""
    class="no-connect-img"
  />

  <app-button (click)="goToSwipes()">
    Chat with others {{ isBoys ? "boys" : "girls" }}
  </app-button>
</div>
