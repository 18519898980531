<div class="game-bg" *ngIf="isDeskTop">
  <div class="circle"></div>

  <div class="left-side"></div>
  <div class="right-side"></div>
</div>

<app-cheat-menu></app-cheat-menu>

<ion-app
  [style]="{
    '--offset-top': offsetTop,
    '--padding-top': paddingTop,
    '--padding-bottom': paddingBottom
  }"
  [class.isDesktop]="isDeskTop"
  [class.loaded]="isShowHeader$ | async"
>
  <div
    class="connection-problems-toast-container"
    [ngClass]="{
      show: websocketSignalRService.connectionProblemToast$ | async
    }"
  >
    <div class="connection-problems-toast">
      <img src="/assets/img/reconnect-icon.svg" alt="" />
      {{ "SHARED.TRYING_TO_RECONNECT" | translate }}
    </div>
  </div>

  <app-header *ngIf="isShowHeader$ | async"></app-header>
  <!-- <div [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  </div> -->
  <ion-content
    id="ion-content"
    overflow-scroll="true"
    [scrollY]="!currentRoute.includes(routeName.swipingChatCards)"
  >
    <!-- <ion-refresher slot="fixed" (ionRefresh)="refreshConnection($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher> -->
    <router-outlet #o="outlet"></router-outlet>
  </ion-content>
  <app-footer
    *ngIf="isShowFooter$ | async"
    style="margin-top: auto"
  ></app-footer>
  <app-loader *ngIf="isShowLoader"></app-loader>
</ion-app>
<app-payments></app-payments>
<app-admob-loader></app-admob-loader>
