import { NextBonusTimeService } from '../services/communication_services/nextBonusTime.service';
import { Injectable } from '@angular/core';
import { BonuseDataService } from '../services/communication_services/bonusData.service';
import { SettingsDataService } from '../services/communication_services/settingsData.service';

@Injectable({
  providedIn: 'root',
})
export class Clean {
  constructor(
    private _bonuseDataService: BonuseDataService,
    private _nextBonusTimeService: NextBonusTimeService,
    private _settingsDataService: SettingsDataService,
  ) {}

  public cleanAllData(): void {
    this._bonuseDataService.cleanData();
    this._nextBonusTimeService.setDefaultData();
    this._settingsDataService.cleanData();
  }

  public cleanSofData(): void {
    this._bonuseDataService.cleanData();
    this._nextBonusTimeService.setDefaultData();
  }
}
