import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';

interface AiChatContentInfo {
  totalContentCount: number;
  unlockedContentCount: number;
  currentRating: number;

  contents: {
    index: number;
    ratingRequired: number;
    url: string;
    contentType: 'Image';
  }[];
}

@Injectable({ providedIn: 'root' })
export class ChatContentService {
  public readonly contentInfo$ = new BehaviorSubject<AiChatContentInfo>(null);

  constructor(private _websocketSignalRService: WebsocketSignalRService) {}

  public updateContentInfo(id: string) {
    this._websocketSignalRService
      .invoke<AiChatContentInfo>(WebsocketCommandType.getAiChatContentInfo, {
        chatId: id,
      })
      .subscribe((res) => {
        this.contentInfo$.next(res);
      });
  }
}
