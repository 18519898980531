import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { WebsocketSignalRService } from './../websocket-signalr.service';
import { AvatarEntity } from '../../types/avatar-entity.interface';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface UpdateSettingsRequestData {
  music: boolean;
  sound: boolean;
  nickname: string;
  quickMessaging: boolean;
  onlineStreamNotifications: boolean;
  subscriptionStatus: SubscriptionStatus;
}
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _host: string = environment.apiUrl;

  constructor(
    private _http: HttpClient,
    private _websocketSignalRService: WebsocketSignalRService,
  ) {}

  public async updateSettings(
    data: UpdateSettingsRequestData,
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${this._host}/api/settings/update`;
      await this._http.put<any>(requestUrl, data).toPromise();
      resolve(true);
      return true;
    });
  }

  public updateNickname(name: string): Observable<boolean> {
    const requestUrl = `${this._host}/api/User/edit-name`;
    return this._http.put<any>(requestUrl, { name }).pipe(map((res) => true));
  }

  public updateClientAge(): Observable<boolean> {
    return this._websocketSignalRService.invoke(
      WebsocketCommandType.confirmAge,
      {},
    );
  }

  public updateClientAgreement(): Observable<boolean> {
    return this._websocketSignalRService.invoke(
      WebsocketCommandType.acceptAgreement,
      {},
    );
  }

  public updateAge(name: string): Observable<boolean> {
    const requestUrl = `${this._host}/api/User/edit-name`;
    return this._http.put<any>(requestUrl, { name }).pipe(map((res) => true));
  }

  public async updateAvatar(file: any): Promise<string> {
    return new Promise<string>(async (resolve) => {
      const formData = new FormData();
      formData.append('file', file);
      const requestUrl = `${this._host}/api/settings/update_avatar`;
      const response = await this._http
        .put<any>(requestUrl, formData)
        .toPromise();

      response.avatar += '?t=' + new Date().getTime();
      resolve(response.avatar);
      return response.avatar;
    });
  }

  public getAvatars(): Observable<AvatarEntity[]> {
    return this._websocketSignalRService.invoke<AvatarEntity[]>(
      WebsocketCommandType.getAvatarUrls,
      {},
    );
  }

  public setAvatar(id: number | null): Observable<boolean> {
    return this._websocketSignalRService.invoke(
      WebsocketCommandType.chooseAvatar,
      { id },
    );
  }

  public sendReport(data: FormData): Observable<boolean> {
    const requestUrl = `${this._host}/api/Support/v2/issue`;
    return this._http.post<any>(requestUrl, data).pipe(map((res) => true));
  }
}
