import { WebsocketSignalRService } from './../../../services/websocket-signalr.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { UserNotification } from './../../../types/user-my-response.interface';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';

@Component({
  selector: 'app-common-notification-modal',
  templateUrl: './common-notification-modal.component.html',
  styleUrls: ['./common-notification-modal.component.scss'],
})
export class CommonNotificationModalComponent implements OnInit {
  @Input() notification: UserNotification;
  constructor(
    private _modalCtrl: ModalController,
    private _analyticsService: AnalyticsService,
    private _websocketSignalRService: WebsocketSignalRService,
  ) {}

  ngOnInit(): void {
    if (this.notification) {
      this._analyticsService.showUserNotification(this.notification);
    }
  }

  close() {
    this._websocketSignalRService
      .invoke(WebsocketCommandType.readNotification, {
        id: this.notification.id,
      })
      .subscribe(() => {
        this._analyticsService.confirmUserNotification(this.notification);
        this._modalCtrl.dismiss();
      });
  }
}
