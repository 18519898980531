<div class="coins-box" (click)="this.goToShop()">
  <img src="/assets/img/icon/tokens.png" alt="" class="cash-icon" />

  <div class="money-value">
    <app-animated-counter [value]="gems$ | async"></app-animated-counter>
  </div>

  <button class="add-btn">
    <img src="/assets/img/icon/plus-btn.svg" />
  </button>
</div>
