import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {
  SplitTestCase,
  SplitTestCasesService,
} from '../split-test-cases.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { SettingsDataService } from './settingsData.service';
import { ChatType } from '../../types/chat-data.interface';
import { RouteName } from '../../enums/route-name.enum';
import { UserDataService } from './userData.service';
import { ModalsService } from '../modals.service';
import { BehaviorSubject, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { OnboardingModalComponent } from '../../components/tutorial/onboarding-modal/onboarding-modal.component';

const tutorialStepsAi = ['chat_name', 'ai_rules'] as const;
const tutorialStepsEpicTutor = [
  'shower_start',
  'enter_name',
  '18_years',
  'policy',
  'my_girls',
  'photos',
  'tokens',
] as const;

type TutorialStepAI = (typeof tutorialStepsAi)[number];
type TutorialStepEpicTutor = (typeof tutorialStepsEpicTutor)[number];

export type TutorialStep = TutorialStepAI | TutorialStepEpicTutor;

export const additionalChatTutorialKey = 'additional_chat_tutorial_completed';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  public currentStep$: BehaviorSubject<TutorialStep> = new BehaviorSubject(
    null,
  );
  public isActive$ = this.currentStep$.pipe(map((step) => !!step));
  public isAnswersVisible$ = new BehaviorSubject(false);

  private readonly _localStorageKey = 'tutorialStep';
  private _initialized: boolean;
  private _tutorialSteps: TutorialStep[] = [];

  private _aiChatId: string;

  public get isActive() {
    return this.currentStep$.value !== null;
  }

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _splitTestCasesService: SplitTestCasesService,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
    private _userDataService: UserDataService,
    private _modalsService: ModalsService,
    private _router: Router,
  ) {}

  public check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this._initialized) {
      this.init();
      this._initialized = true;
    }

    if (!this.currentStep$.value) {
      return true;
    }

    // if (this._splitTestCasesService.hasCase(SplitTestCase.epicTutor)) {
    this._modalsService.openModal(OnboardingModalComponent).subscribe();
    return false;
    // } else {
    //   switch (this.currentStep$.value as TutorialStepAI) {
    //     case 'chat_name':
    //       if (state.url !== `/${RouteName.swipingChatCards}`) {
    //         return this._router.parseUrl(RouteName.swipingChatCards);
    //       }
    //       return true;
    //     case 'ai_rules':
    //       if (!this._aiChatId) {
    //         const aiChat =
    //           this._userDataService.updateUserData.value.chats.find(
    //             (c) => c.type === ChatType.ai,
    //           );
    //         this._aiChatId = aiChat.id;
    //       }
    //       const chatUrl = `${RouteName.allChats}/${this._aiChatId}`;

    //       if (state.url !== `/${chatUrl}`) {
    //         return this._router.parseUrl(chatUrl);
    //       }
    //       return true;
    //   }
    // }
  }

  public setStep(step: TutorialStep, oldStepAdditionalData = '') {
    console.log(`set tutorial step: ${step}`);

    if (this.currentStep$.value !== null) {
      this._analyticsService.tutorialStepCompleted(
        `${this.currentStep$.value}${
          oldStepAdditionalData ? `_${oldStepAdditionalData}` : ''
        }`,
      );
    }

    this.currentStep$.next(step);
    localStorage.setItem(this._localStorageKey, step);
  }

  public after(current: TutorialStep, step: TutorialStep): boolean {
    return (
      this._tutorialSteps.indexOf(current) > this._tutorialSteps.indexOf(step)
    );
  }

  public before(current: TutorialStep, step: TutorialStep): boolean {
    return (
      this._tutorialSteps.indexOf(current) < this._tutorialSteps.indexOf(step)
    );
  }

  public finishTutorial() {
    return this._websocketSignalRService
      .invoke(WebsocketCommandType.setTutorialPassed, {})
      .pipe(
        map(() => {
          this.setStep(null);
          this._analyticsService.tutorialCompleted();
        }),
      );
  }

  public setAiChatId(id: string) {
    this._aiChatId = id;
  }

  private init() {
    const isActive =
      !this._settingsDataService.updateSettingsData$.value.tutorialIsPassed;

    if (!isActive) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // if (this._splitTestCasesService.hasCase(SplitTestCase.epicTutor)) {
    this.setStep('shower_start');
    // } else {
    //   this._tutorialSteps = tutorialStepsAi as any;

    //   let savedStep = localStorage.getItem(
    //     this._localStorageKey,
    //   ) as TutorialStep;

    //   if (!this._tutorialSteps.includes(savedStep)) {
    //     savedStep = null;
    //   }
    //   this.setStep(savedStep ?? this._tutorialSteps[0]);
    // }
  }
}
