import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UiStateService } from 'src/app/shared/services/communication_services/uiStates.service';
import { ChatStateService } from 'src/app/shared/views/chat/services/chat-state.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { PaymentsService } from 'src/app/shared/services/payments/payments.service';
import { PurchaseSource } from 'src/app/shared/enums/purchase-source.enum';
import { RealPurchaseData } from 'src/app/shared/types/purchase-data.interface';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { ShopLot } from 'src/app/shared/types/shop-lot-new.interface';
import { LotType } from 'src/app/shared/enums/lot-type.enum';
import { TimerComponent } from '../../timer/timer.component';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { map } from 'rxjs';

@Component({
  selector: 'app-dual-offer',
  templateUrl: './dual-offer.component.html',
  styleUrls: ['./dual-offer.component.scss'],
})
export class DualOfferComponent implements OnInit, AfterViewInit {
  @Input() public offers: ShopLot[];
  @ViewChild(TimerComponent) public timerComponent: TimerComponent;

  public readonly name$ = this._uiStateService.updateStateData.pipe(
    map((res) => res.characterName),
  );
  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;
  public newbieOffer: ShopLot;
  public eliteOffer: ShopLot;

  constructor(
    private _analyticsService: AnalyticsService,
    private _chatStateService: ChatStateService,
    private _modalController: ModalController,
    private _paymentsService: PaymentsService,
    private _uiStateService: UiStateService,
  ) {}

  public ngOnInit(): void {
    this.newbieOffer = this.offers.find(
      (o) => o.lotType === LotType.starterDualOfferPack,
    );
    this.eliteOffer = this.offers.find(
      (o) => o.lotType === LotType.starterDualOfferSubscription,
    );
  }

  public ngAfterViewInit(): void {
    this._analyticsService.dualOffers({
      eventName: 'is_shown',
      productName: this.newbieOffer.lotType,
      timerLeft: this.timerComponent.timerString,
    });
    this._analyticsService.dualOffers({
      eventName: 'is_shown',
      productName: this.eliteOffer.lotType,
      timerLeft: this.timerComponent.timerString,
    });
  }

  public close(): void {
    this._analyticsService.dualOffers({
      eventName: 'is_closed',
      productName: this.newbieOffer.lotType,
      timerLeft: this.timerComponent.timerString,
    });
    this._analyticsService.dualOffers({
      eventName: 'is_closed',
      productName: this.eliteOffer.lotType,
      timerLeft: this.timerComponent.timerString,
    });

    this._modalController.dismiss(false);
  }

  public purchase(lot: ShopLot) {
    this._analyticsService.dualOffers({
      eventName: 'clicked_on',
      productName: lot.lotType,
      timerLeft: this.timerComponent.timerString,
    });

    const data: RealPurchaseData = {
      source: PurchaseSource.modal,
      purchase: lot.purchase,
      productId: lot.purchase.productId,
      chatId: this._chatStateService.chatId,
    };
    if (environment.buildVersion === BuildVersion.nutaku) {
      data.nutakuData = {
        nutakuName: 'Offer',
        nutakuDescription: '',
        nutakuImageUrl:
          'https://nutaku.getmynudes.com/assets/img/logo-gmn-for-nutaku.png',
      };
    }
    this._paymentsService
      .purchase(data)
      .subscribe((res) => this._modalController.dismiss(res));
  }
}
