<ng-container>
  <div class="modal-container animate-bottom">
    <div class="modal modal-vertical modal-no-padding modal-auto-height">
      <button (click)="this.close()" class="close-modal-btn">
        <img src="/assets/img/close-transparent.png" />
      </button>
      <div class="modal-header">
        <h2>Get Special BONUS</h2>
      </div>

      <div class="modal-body">
        <img class="gems-pic" src="/assets/img/gems.png" />
        <span class="gems-text">100 gems</span>
        <button
          [class.spinner]="disableButton"
          [disabled]="disableButton"
          (click)="downloadApk()"
          class="download-app-btn"
        >
          Download APP
        </button>
      </div>
    </div>
  </div>
</ng-container>
