import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  standalone: true,
})
export class TermsOfUseComponent {
  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;
}
