<div class="dual-offer-modal">
  <button (click)="close()" class="close-btn">&times;</button>

  <div class="modal-head">
    <h4>The trial period with {{ name$ | async }} is over</h4>
  </div>

  <div class="newbie-pack">
    <h3 class="pack-title">Newbie’s pack</h3>
    <div class="items">
      <div class="item">
        <img src="/assets/img/shop-page/tokens/tokens2.png" alt="" />
        <span>777 Tokens</span>
      </div>
      <div class="item">
        <img src="/assets/img/quests-page/daily-icons/gift.png" alt="" />
        <span>10 Gifts</span>
      </div>
      <div class="item">
        <img src="/assets/img/offers/dual-offer/lock-icon.png" alt="" />
        <span>Unlock girls chat</span>
      </div>
    </div>

    <button
      (click)="purchase(newbieOffer)"
      class="btn btn-gradient-green price-text-size-14 price-btn"
    >
      <app-price-block [purchase]="newbieOffer.purchase"></app-price-block>
    </button>
  </div>

  <div class="separator">
    <div class="or-block">or</div>
  </div>

  <div class="elite-pack">
    <h3 class="pack-title">Elite’s pack</h3>
    <div class="content">
      <div class="left-col">
        <img
          src="/assets/imgx/offers/dual-offer/girl.png"
          alt=""
          class="girl"
        />
        <div class="chat-label">+Unique <span>Chat</span></div>
      </div>
      <div class="right-col">
        <img src="/assets/img/premium/success/crown.png" alt="" class="crown" />
        <h4 class="premium-title">+Premium</h4>
        <p
          *ngIf="environment.buildVersion === buildVersion.default"
          class="premium-description"
        >
          7 days free (then 30$/month)
        </p>
        <p
          *ngIf="
            environment.buildVersion === buildVersion.nutaku ||
            environment.buildVersion === buildVersion.erogames
          "
          class="premium-description"
        >
          7-days Premium
        </p>

        <ul>
          <li>+300 of unique AI photos</li>
          <li>+30 AI generated stories</li>
        </ul>

        <img
          src="/assets/img/shop-page/tokens/tokens4.png"
          alt=""
          class="tokens-img"
        />
        <p class="tokens-text">+3500 Tokens</p>

        <button
          (click)="purchase(eliteOffer)"
          class="btn btn-gradient-green price-btn"
        >
          <app-price-block [purchase]="eliteOffer.purchase"></app-price-block>

          <div class="profit-badge">500%<br />Profit</div>
        </button>
      </div>
    </div>
  </div>

  <div class="timer">
    <img src="/assets/img/offers/dual-offer/timer-icon.svg" alt="" />
    <app-timer [initialTime]="newbieOffer.time"></app-timer>
  </div>
  <p class="timer-description">Time limited offer</p>
</div>
