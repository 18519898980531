import { AnalyticsService } from './../../services/analytics/analytics.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { TimeConverter } from '../../helpers/timeConverter';
import { AnimationOptions } from 'ngx-lottie';
import { ModalController } from '@ionic/angular';

import SwiperCore, {
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Swiper,
  SwiperOptions,
  Zoom,
} from 'swiper';
import { SwiperPhoto } from '../../types/swiper-photo.interface';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { AnimationItem } from 'lottie-web';
import { ChatService } from '../../views/chat/services/chat.service';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class FullscreenComponent implements OnInit, AfterViewInit, OnDestroy {
  public isPlay = false;
  public isPlayVisible = true;
  public isMute = false;
  public timerId: ReturnType<typeof setTimeout>;
  @ViewChild('swiper') swiper: Swiper;
  @ViewChildren('videos') videos: QueryList<ElementRef>;
  @ViewChild('playButton') private playButton: ElementRef;
  @ViewChild('muteButton') private muteButton: ElementRef;
  @ViewChildren('seekBars') seekBars: QueryList<ElementRef>;
  @Input() contentData: SwiperPhoto[] = [];
  @Input() isGallery = false;
  @Input() startWith = 0;
  public inputStyle: string;
  private subscribers: Subscription[] = [];
  saveToGalleryAnimationOptions: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/save-to-gallery.json`,
    loop: false,
  };
  environment = environment;
  constructor(
    private _chatService: ChatService,
    private _unsubscribeHelper: UnsubscribeHelper,
    public timeConverter: TimeConverter,
    private _analyticsService: AnalyticsService,
    private _modalCtrl: ModalController,
  ) {
    this.inputStyle =
      'linear-gradient(to right, #ffffff 0%, #ffffff 0%, #7f7f7f 0%, #7f7f7f 100%)';
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this._modalCtrl.dismiss();
  }

  config: SwiperOptions = {
    initialSlide: 3,
    slidesPerView: 1,
    spaceBetween: 0,
    zoom: true,
  };

  ngOnInit() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this.subscribers);

    if (window.history.state.modal) {
      history.back();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.videos.forEach((item, index) => {
        this.initVideoFoActiveIndex(item, index);
      });
      // console.log('this.videos', this.videos);
    });
  }

  initVideoFoActiveIndex(video, index) {
    const seekBar = this.seekBars.toArray()[index];
    {
      seekBar.nativeElement.ontouchstart = () => {
        video.nativeElement.pause();
      };
    }
    seekBar.nativeElement.ontouchend = () => {
      if (this.isPlay) {
        video.nativeElement.play();
      }
    };
    seekBar.nativeElement.addEventListener('mouseup', () => {
      video.nativeElement.play();
    });
    seekBar.nativeElement.addEventListener('change', () => {
      const time =
        video.nativeElement.duration * (seekBar.nativeElement.value / 100);
      video.nativeElement.currentTime = time;
      this.inputStyle = `linear-gradient(to right, #ffffff 0%, #ffffff ${seekBar.nativeElement.value}%,
                     #7f7f7f ${seekBar.nativeElement.value}%, #7f7f7f 100%)`;
    });
    seekBar.nativeElement.addEventListener('input', () => {
      this.inputStyle = `linear-gradient(to right, #ffffff 0%, #ffffff ${seekBar.nativeElement.value}%,
                     #7f7f7f ${seekBar.nativeElement.value}%, #7f7f7f 100%)`;
    });

    video.nativeElement.addEventListener('timeupdate', () => {
      const value =
        (100 / video.nativeElement.duration) * video.nativeElement.currentTime;
      seekBar.nativeElement.value = value;
      this.inputStyle = `linear-gradient(to right, #ffffff 0%, #ffffff ${seekBar.nativeElement.value}%,
                     #7f7f7f ${seekBar.nativeElement.value}%, #7f7f7f 100%)`;
    });
  }

  animationCreated(animationItem: AnimationItem): void {}

  out(): void {
    this.dismissModal();
  }

  public playPause(url): void {
    const matchedVideo = this.videos.filter((item) =>
      item.nativeElement.currentSrc.includes(url),
    )[0];
    if (matchedVideo.nativeElement.paused === true) {
      matchedVideo.nativeElement.play();
      this.isPlay = true;
    } else {
      matchedVideo.nativeElement.pause();
      this.isPlay = false;
    }
    this.checkVisible();
  }

  // public openInFullScreen(url): void {
  //   const elem = this.video.nativeElement;
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) {
  //     /* Safari */
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) {
  //     /* IE11 */
  //     elem.msRequestFullscreen();
  //   }
  // }

  public buy(item: SwiperPhoto) {
    this.subscribers.push(
      this._chatService
        .saveToGalery(item.chatId, item.url, item.contentType, item.id)
        .pipe(take(1))
        .subscribe(),
    );
    item.isSave = true;

    if (item.contentType === 'Video') {
      this._analyticsService.saveVideo(item.chatId, item.id);
    } else {
      this._analyticsService.savePhoto(item.chatId, item.id);
    }
  }

  public mute(url): void {
    const matchedVideo = this.videos.filter((item) =>
      item.nativeElement.currentSrc.includes(url),
    )[0];
    if (matchedVideo.nativeElement.muted === false) {
      matchedVideo.nativeElement.muted = true;
      this.isMute = true;
    } else {
      matchedVideo.nativeElement.muted = false;
      this.isMute = false;
    }
  }

  private checkVisible(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    if (!this.isPlay) {
      this.isPlayVisible = true;
    } else {
      this.timerId = setTimeout(() => {
        this.isPlayVisible = false;
      }, 1200);
    }
  }

  public notNullable(arr) {
    return arr.filter((v) => v !== null);
  }
}
