export enum PurchaseSource {
  shop = 'shop',
  modal = 'modal',
  offline = 'offline',
  swipe = 'swipe',
  chats = 'chats',
  unban = 'unban',
}
// export enum PurchaseSource {
//   gemsFromShop = 'gems_from_shop',
//   chatFromShop = 'chatFromShop',
//   chatFromChats = 'chatFromChats',
//   chatFromCards = 'chatFromCards',
//   pack = 'pack',
//   packfromChats = 'packFromchats',
//   unBan = 'unBan',
//   trialPurchase = 'trailPurchase',
//   offerModal = 'offerModal',
//   offerBanner = 'offerBanner',
//   packFromShop = 'pack_from_shop',
//   paywall = 'paywall',
//   subscriptionInTrial = 'subscriptionInTrial',
//   subscriptionFromAiPaywall = 'subscription_from_ai_paywall',
//   subscriptionFromSwipesEnd = 'subscription_from_swipes_end',
//   starterDualOffer = 'StarterDualOffer',
//   aiTokensFromPaywall = 'ai_tokens_from_paywall',
//   aiTokensFromModal = 'ai_tokens_from_modal',
//   aiTokensFromShop = 'ai_tokens_from_shop',
//   giftFromShop = 'gift_from_shop',
//   boosterFromShop = 'booster_from_shop',
// }
