import {
  GlobalRatingResponse,
  RatingReward,
} from '../types/global-rating.interface';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';
import { WebsocketSignalRService } from './websocket-signalr.service';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { AdCollectCoins } from '../types/ads.interface';
import { InventoryService } from './inventory.service';
import { Injectable } from '@angular/core';

export interface ToastProps {
  duration?: number;
  position?: 'bottom' | 'top';
  closeButton?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalRatingService {
  public rewards: BehaviorSubject<RatingReward[]> = new BehaviorSubject([]);
  public currentRating = new BehaviorSubject(0);

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _inventoryService: InventoryService,
  ) {}

  public fetchRewards(): Observable<number> {
    return this._websocketSignalRService
      .invoke<GlobalRatingResponse>(
        WebsocketCommandType.getGlobalRatingRewards,
        {},
      )
      .pipe(
        take(1),
        map((res) => {
          const step = 100 / (res.ratingRewards.length + 1);

          for (let i = 0; i < res.ratingRewards.length; i++) {
            res.ratingRewards[i].progress = (i + 1) * step;
          }
          this.currentRating.next(res.currentRating);
          this.rewards.next(res.ratingRewards);

          return res.currentRating;
        }),
      );
  }

  public claimReward(reward: RatingReward): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<AdCollectCoins>(WebsocketCommandType.takeGlobalRatingReward, {
        id: reward.id,
      })
      .pipe(
        take(1),
        map((res) => {
          reward.canTake = false;
          reward.taken = true;
          this._inventoryService.setGems(res.coins);
          return true;
        }),
      );
  }
}
