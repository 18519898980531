<p>
  <span>
    Premium subscription offers $9.99 weekly subscription (with no trial) for
    unlocking secret content and features.</span
  >
</p>
<h2>
  <span>Subscription renewal</span>
</h2>
<p>
  <span>
    This price is for United States customers. Pricing in other countries may
    vary and actual charges may be converted to your local currency depending on
    the country of residence. Payment will be charged to iTunes Account at
    confirmation of purchase. Subscription automatically renews unless
    auto-renew is turned off at least 24-hours before the end of the current
    period. Account will be charged for renewal within 24-hours prior to the end
    of the current period, and identify the cost of the renewal. Subscriptions
    may be managed by the user and auto-renewal may be turned off by going to
    the user’s Account Settings after purchase. Any unused portion of a free
    trial period, if offered, will be forfeited when the user purchases a
    subscription to that publication, where applicable.
  </span>
</p>

<h2>
  <span>Canceling trial or subscription</span>
</h2>
<p>
  <span>
    You can turn off the auto-renew for the subscription whenever you want to
    through iTunes. Check https://support.apple.com/HT202039. When your current
    trial/subscription period expires, you will be unsubscribed. The current
    active subscription period can not be canceled. After your subscription
    expires, you will no longer be able to use the Premium content.
  </span>
  <br />
  <br />
</p>
<p>
  <span>
    If you have any questions or comments, feel free to contact us at
    <a [href]="'mailto:' + environment.policy.email">{{
      environment.policy.email
    }}</a
    >.
  </span>
</p>
