import { ChatModule } from '../../views/chat/chat.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { LottieModule } from 'ngx-lottie';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    TranslateModule,
    CommonModule,
    LottieModule,
    IonicModule,
    FormsModule,
    ChatModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
