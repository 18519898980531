import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LocalStorageService } from './shared/services/communication_services/localStorage.service';
import { JWTTokenService } from './shared/services/communication_services/JWTToken.service';
import { AuthorizeGuard } from './shared/guards/autorize.guard';
import { RouteName } from './shared/enums/route-name.enum';
import { TutorialGuard } from './shared/guards/tutorial.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'launch',
  },
  {
    path: RouteName.allChats,
    loadChildren: () =>
      import('src/app/pages/chats/chats.module').then((m) => m.MainModule),
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: RouteName.allChats,
    loadChildren: () =>
      import('src/app/pages/chat/chat-page.module').then(
        (m) => m.ChatPageModule,
      ),
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: RouteName.launch,
    loadChildren: () =>
      import('src/app/pages/launch/launch.module').then((m) => m.LaunchModule),
  },
  {
    path: RouteName.gallery,
    loadChildren: () =>
      import('src/app/pages/gallery/gallery.module').then(
        (m) => m.GalleryModule,
      ),
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: RouteName.comics,
    loadChildren: () =>
      import('src/assets/img/promo-platforms/comics/comics.module').then(
        (m) => m.ComicsModule,
      ),
  },
  {
    path: RouteName.settings,
    loadChildren: () =>
      import('src/app/pages/settings/settings.module').then(
        (m) => m.SettingsModule,
      ),
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: RouteName.bonus,
    loadChildren: () =>
      import('src/app/pages/quests/quests-page.module').then(
        (m) => m.QuestsPageModule,
      ),
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: RouteName.shop,
    loadChildren: () =>
      import('src/app/pages/shop/shop-page.module').then(
        (m) => m.ShopPageModule,
      ),
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: RouteName.swipingChatCards,
    loadChildren: () =>
      import('src/app/pages/swiping-chat-cards/swiping-chat-cards.module').then(
        (m) => m.SwipingChatCardsModule,
      ),
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: RouteName.profile,
    loadChildren: () =>
      import('src/app/pages/profile/profile.module').then(
        (m) => m.ProfileModule,
      ),
    data: {
      state: 'profile',
    },
    canLoad: [AuthorizeGuard],
    canActivate: [TutorialGuard],
  },
  {
    path: '**',
    redirectTo: 'allChats',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      errorHandler: (error) => {
        console.error('err hand', error);
      },
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [JWTTokenService, LocalStorageService],
})
export class AppRoutingModule {}
