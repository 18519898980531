import {
  catchError,
  switchMap,
  concatMap,
  retry,
  tap,
  map,
} from 'rxjs/operators';
import { PaymentAnimationStates } from 'src/app/shared/enums/payment-animation-states';
import { PaymentsCommonMethodsService } from './payments-common-methods.service';
import { PaymentResponeStatus } from '../../enums/payment-respone-status.enum';
import { AuthErogamesService } from '../auth/auth-erogames.service.ts.service';
import { JWTTokenService } from '../communication_services/JWTToken.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PaymentResponse } from '../../types/payment-response.interface';
import { RealPurchaseData } from '../../types/purchase-data.interface';
import { PurchaseSource } from '../../enums/purchase-source.enum';
import { AnalyticsService } from '../analytics/analytics.service';
import { PaymentsStateService } from './payments-state.service';
import { environment } from 'src/environments/environment';
import { InventoryService } from '../inventory.service';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErogamesPaymentService {
  private _host: string = environment.apiUrl;
  constructor(
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService,
    private _paymentsStateService: PaymentsStateService,
    private _authErogamesService: AuthErogamesService,
    private _analyticsService: AnalyticsService,
    private _inventoryService: InventoryService,
    private _tokenService: JWTTokenService,
    private _http: HttpClient,
  ) {}

  private _getToken(): Observable<string> {
    return of(this._tokenService.erogamesToken);
  }

  private _afterPurchase(
    source: PurchaseSource,
    productId: string = null,
    chatId = '',
  ) {
    const requestUrl = `${this._host}/api/Shop/buy`;
    const paymentMethod = BuildVersion.erogames;

    return of({
      productId,
      paymentMethod,
      chatId,
      source,
    }).pipe(
      switchMap((params) =>
        this._getToken().pipe(map((token) => ({ ...params, token }))),
      ),
      concatMap((params) =>
        this._http.post<PaymentResponse>(requestUrl, params),
      ),
      switchMap((res) => {
        // Check if unauthorised - refreshToken and try again
        if (res.payment.status === PaymentResponeStatus.unauthorized) {
          this._analyticsService.notAuthorizedInPurchase(productId, source);
          return this._authErogamesService.erogamesRefreshToken().pipe(
            map(() => {
              throw new Error('Not authorized');
            }),
          );
        } else {
          return of(res);
        }
      }),
      retry(2),
      tap((res) => {
        const idForAnalytic = productId;
        if (res.payment.status === PaymentResponeStatus.insufficientFunds) {
          this._paymentsCommonMethodsService.showNoFundsModal();
          this._analyticsService.notEnoughFunds(
            idForAnalytic,
            res?.payment?.cost,
            res?.payment?.productId,
          );
        }

        if (
          res.payment.status === PaymentResponeStatus.completed ||
          res.payment.status === PaymentResponeStatus.success
        ) {
          this._analyticsService.payment(
            res?.payment.platform,
            idForAnalytic,
            res.payment.cost,
            res?.payment?.productId,
            res?.payment?.lotType,
            res?.payment?.source,
            res?.payment?.meta,
            res?.payment?.offerType,
            res?.payment?.purchaseInfo,
            res?.payment?.status,
            res?.payment?.paymentNumber,
          );
        }
      }),
    );
  }

  purchase(data: RealPurchaseData) {
    return from(
      this._paymentsCommonMethodsService.showConfirmationModal(
        data.purchase.erogamesCost,
      ),
    ).pipe(
      switchMap((isSubmited) => {
        if (isSubmited.data) {
          this._analyticsService.confirmPurchaseModal(data);
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.progress;
          return this._afterPurchase(
            data.source,
            data && data.purchase.productId,
            data?.chatId,
          ).pipe(
            map((res) => {
              if (
                res.payment.status === PaymentResponeStatus.completed ||
                res.payment.status === PaymentResponeStatus.success
              ) {
                this._paymentsStateService.paymentState =
                  PaymentAnimationStates.success;
                this._inventoryService.setInventory(res.inventory);
                return true;
              } else {
                this._paymentsStateService.paymentState = null;
                return false;
              }
            }),
          );
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        this._paymentsStateService.paymentState = PaymentAnimationStates.error;
        return of(false);
      }),
    );
  }
}
