<div *ngIf="rewardsData" class="chat-finished-modal">
  <div class="chat-finished-modal__header">
    {{ "CHATS_PAGE.CHAT_COMPLETED" | translate }}

    <button (click)="closeModal()" class="close-btn">
      <img src="/assets/img/modals/close-btn.svg" alt="" />
    </button>
  </div>
  <div class="chat-finished-modal__body">
    <p class="greeting-message main-information-description-12">
      <ng-container *ngIf="rewardsData.score <= 55">{{
        "CHATS_PAGE.GREETING_FEEDBACK_MESSAGE_1" | translate
      }}</ng-container>
      <ng-container *ngIf="rewardsData.score > 55 && rewardsData.score <= 65">{{
        "CHATS_PAGE.GREETING_FEEDBACK_MESSAGE_2" | translate
      }}</ng-container>
      <ng-container *ngIf="rewardsData.score > 65 && rewardsData.score <= 75">{{
        "CHATS_PAGE.GREETING_FEEDBACK_MESSAGE_3" | translate
      }}</ng-container>
      <ng-container *ngIf="rewardsData.score > 75">{{
        "CHATS_PAGE.GREETING_FEEDBACK_MESSAGE_4" | translate
      }}</ng-container>
    </p>

    <h4 class="your-rewards">{{ "CHATS_PAGE.YOU_REWARDS" | translate }}</h4>

    <div class="gifts-block">
      <div *ngFor="let reward of rewardsData.rewardItems" class="gift-box">
        <img [src]="reward.imageUrl" />
        <span class="main-information-title-14-medium">
          {{ reward.localizedName | localize }}:
          {{ isRewardVideoCompleted ? reward.count * 2 : reward.count }}
        </span>
      </div>
    </div>

    <ng-container
      *ngIf="
        !isRewardVideoCompleted &&
        environment.buildVersion === buildVersion.legal
      "
    >
      <p class="ads-description main-information-description-12">
        {{ "CHATS_PAGE.YOU_CAN_DOUBLE_REWARDS" | translate }}
      </p>

      <app-button variant="ad" (click)="showAd()" class="ad-btn">
        <img src="/assets/img/shop-page/ads_tv.png" alt="" class="ad-icon" />
        {{ "SHOP_PAGE.ADS.WATCH_ADS" | translate }}
      </app-button>
    </ng-container>

    <h3 class="earned-points main-information-title-14-medium">
      <span
        [innerHTML]="
          'CHATS_PAGE.YOU_EARNED_POINTS'
            | translate : { count: rewardsData?.score }
        "
        class="points-text"
      >
      </span
      ><img
        src="/assets/img/settings-page/rating-bar/info-icon.svg"
        alt=""
        class="info-icon"
      />
      <div class="tooltip">
        {{ "CHATS_PAGE.EARNED_TIP" | translate }}
      </div>
    </h3>

    <form [formGroup]="form">
      <div class="rating-section">
        <div class="rating-box">
          <p class="main-information-title-14-medium">
            {{ "CHATS_PAGE.HOW_WAS_STORY" | translate }}
          </p>
          <app-rating-bar
            name="storyRating"
            formControlName="storyRating"
          ></app-rating-bar>
        </div>

        <div class="rating-box">
          <p class="main-information-title-14-medium">
            {{ "CHATS_PAGE.HOW_WAS_THIS_GIRL" | translate }}
          </p>
          <app-rating-bar
            name="girlRating"
            formControlName="girlRating"
          ></app-rating-bar>
        </div>

        <div *ngIf="askTranslationRating" class="rating-box">
          <p class="main-information-title-14-medium">
            {{ "CHATS_PAGE.HOW_WAS_TRANSLATE" | translate }}
          </p>
          <app-rating-bar
            name="translateQuality"
            formControlName="translateQuality"
          ></app-rating-bar>
        </div>
      </div>

      <textarea
        class="comment-textarea"
        name="comment"
        formControlName="comment"
        cols="15"
        rows="3"
        [placeholder]="'CHATS_PAGE.LEAVE_FEEDBACK' | translate"
      ></textarea>

      <div
        class="validation-error-block main-information-description-8"
        *ngIf="form.touched && !form.valid"
        [innerHTML]="'CHATS_PAGE.PLEASE_PUT_RATING_COMMENT' | translate"
      ></div>

      <div
        class="validation-error-block main-information-description-8"
        *ngFor="let err of commonServerErrors"
      >
        {{ err }}
      </div>

      <app-button
        (click)="sendFeedback()"
        [pending]="loading"
        class="continue-btn price-text-size-14"
      >
        {{ "SHARED.CONTINUE" | translate }}
      </app-button>
    </form>
  </div>
</div>
