import { PremiumDescriptionTextModule } from '../../components/premium/description-text/premium-description-text.module';
import { AdditionalContentModalComponent } from './modals/additional-content-modal/additional-content-modal.component';
import { NewPhotoNotificationModule } from '../../components/new-photo-notification/new-photo-notification.module';
import { ChatIsFinishedModalComponent } from './modals/chat-is-finished-modal/chat-is-finished-modal.component';
import { SaveProgressToastModule } from '../../components/save-progress-toast/save-progress-toast.module';
import { PremiumButtonTextModule } from '../../components/premium/button-text/premium-button-text.module';
import { AudioMessageComponent } from './message-templates/audio-message/audio-message.component';
import { PhotoMessageComponent } from './message-templates/photo-message/photo-message.component';
import { VideoMessageComponent } from './message-templates/video-message/video-message.component';
import { AnimatedCounterModule } from '../../components/animated-counter/animated-counter.module';
import { PremiumBlockModule } from '../../components/premium/premium-block/premium-block.module';
import { TextMessageComponent } from './message-templates/text-message/text-message.component';
import { AiOfflineModalComponent } from './modals/ai-offline-modal/ai-offline-modal.component';
import { AiRatingCounterComponent } from './ai-rating-counter/ai-rating-counter.component';
import { AdMessageComponent } from './message-templates/ad-message/ad-message.component';
import { SlotRewardModalComponent } from './catsino/reward-modal/reward-modal.component';
import { EnergyModalComponent } from './catsino/energy-modal/energy-modal.component';
import { PriceBlockModule } from '../../components/price-block/price-block.module';
import { ChatAnswerInputComponent } from './answer-input/answer-input.component';
import { GiftsModalComponent } from './modals/gifts-modal/gifts-modal.component';
import { RatingBarModule } from '../../components/rating-bar/rating-bar.module';
import { ChatModalComponent } from './modals/chat-modal/chat-modal.component';
import { ButtonModule } from '../../components/controls/button/button.module';
import { AiPaywallComponent } from './modals/ai-paywall/ai-paywall.component';
import { ChatPreviewComponent } from './chat-preview/chat-preview.component';
import { TutorialModule } from '../../components/tutorial/tutorial.module';
import { DirectivesModule } from '../../directives/directives.module';
import { HeaderModule } from '../../components/header/header.module';
import { ChatGalleryComponent } from './gallery/gallery.component';
import { EmojiModule } from '../../components/emoji/emoji.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatGiftComponent } from './gifts/gift/gift.component';
import { OfflineComponent } from './offline/offline.component';
import { CatsinoComponent } from './catsino/catsino.component';
import { ChatGiftsComponent } from './gifts/gifts.component';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { BanComponent } from './ban/ban.component';
import { ChatComponent } from './chat.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    AdditionalContentModalComponent,
    ChatIsFinishedModalComponent,
    SlotRewardModalComponent,
    ChatAnswerInputComponent,
    AiOfflineModalComponent,
    PhotoMessageComponent,
    AudioMessageComponent,
    VideoMessageComponent,
    ChatGalleryComponent,
    EnergyModalComponent,
    ChatPreviewComponent,
    TextMessageComponent,
    GiftsModalComponent,
    ChatModalComponent,
    AiPaywallComponent,
    AdMessageComponent,
    ChatGiftsComponent,
    ChatGiftComponent,
    OfflineComponent,
    CatsinoComponent,
    ChatComponent,
    BanComponent,
  ],
  imports: [
    PremiumDescriptionTextModule,
    NewPhotoNotificationModule,
    AiRatingCounterComponent,
    SaveProgressToastModule,
    PremiumButtonTextModule,
    AnimatedCounterModule,
    ReactiveFormsModule,
    PremiumBlockModule,
    DirectivesModule,
    PriceBlockModule,
    TranslateModule,
    RatingBarModule,
    TutorialModule,
    CommonModule,
    HeaderModule,
    RouterModule,
    ButtonModule,
    SwiperModule,
    FormsModule,
    PipesModule,
    IonicModule,
    EmojiModule,
    FormsModule,
  ],
  exports: [
    ChatAnswerInputComponent,
    ChatGalleryComponent,
    ChatGiftsComponent,
    CatsinoComponent,
    ChatComponent,
  ],
})
export class ChatModule {}
