import { GenderSelectModalComponent } from './gender-select-modal.component';
import { ButtonModule } from '../controls/button/button.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [GenderSelectModalComponent],
  imports: [CommonModule, ButtonModule],
  exports: [GenderSelectModalComponent],
})
export class GenderSelectModalModule {}
