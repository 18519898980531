@if (step >= 0) {
  <div class="bg bg-1" [class.hidden]="step > 0"></div>
}
@if (step >= 1) {
  <div class="bg bg-2" [class.hidden]="step > 3"></div>
}
@if (step >= 4) {
  <div class="bg bg-3" [class.hidden]="step > 4">
    <img
      src="/assets/img/tutorial/onboarding-modal/girls3.png"
      alt=""
      class="girls"
    />
  </div>
}
@if (step >= 5) {
  <div class="bg bg-4">
    <div class="cards-wrapper" [class.blur]="step === 6">
      <img
        src="/assets/img/tutorial/onboarding-modal/cards.png"
        alt=""
        class="cards visible"
      />
      <img
        src="/assets/img/tutorial/onboarding-modal/cards2.png"
        alt=""
        class="cards"
        [class.visible]="step === 6"
      />
    </div>
    @if (step === 6) {
      <img src="/assets/img/icon/tokens.png" alt="" class="token-img" />
    }
  </div>
}

@if (step >= 0) {
  <div class="messages-wrapper messages-1" [class.hidden]="step > 0">
    <div class="message top-tail" [class.fade]="step > 0">
      Hey, glad to see you! Sorry for my appearance, just finished my shower
    </div>
  </div>
}
@if (step >= 1) {
  <div class="messages-wrapper messages-2" [class.hidden]="step > 3">
    @if (step >= 1) {
      <div class="message-wrapper">
        <div class="message top-tail" [class.fade]="step > 1">
          Nice to meet you, I'm Cameron!
        </div>
      </div>
    }
    @if (step >= 2 && environment.buildVersion !== buildVersion.legal) {
      <div class="message-wrapper">
        <div class="message top-tail" [class.fade]="step > 2">
          How old are you?
        </div>
      </div>
    }
    @if (step >= 3) {
      <div class="message-wrapper">
        <div class="message top-tail" [class.fade]="step > 3">
          Please, read carefully.
          <span (click)="openTermsModal()">Terms of Use</span>,
          @if (environment.buildVersion === buildVersion.legal) {
            <span (click)="openPrivacyModal()">Privacy Policy</span>
          } @else {
            <span (click)="openPrivacyModal()">Privacy Policy</span>,
            <span (click)="openCookiesModal()">Cookie Policy</span>,
            <span (click)="open18UscModal()">18 U.S.C. 2257</span>
          }
        </div>
      </div>
    }
  </div>
}
@if (step >= 4) {
  <div class="messages-wrapper messages-3" [class.hidden]="step > 4">
    <div class="message bottom-tail" [class.fade]="step > 4">
      Me and my hot friends DESIRE to chat with you
    </div>
  </div>
}
@if (step >= 5) {
  <div class="messages-wrapper messages-4" [class.hidden]="step > 5">
    <div class="message top-tail" [class.fade]="step > 5">
      Also, we love having fun and exchange INTRIGUING photos
    </div>
  </div>
}
@if (step >= 6) {
  <div class="messages-wrapper messages-4">
    <div class="message top-tail">
      Let's play - every message will cost you some Tokens. Like in real life,
      but I'm much better 😉💋
    </div>
  </div>
}

<div class="input-block">
  @if (step === 1) {
    <p class="enter-name-label">Enter your name:</p>

    <form (submit)="submitNickname($event)" class="answer-message-button">
      <div class="answer-content">
        <input
          [(ngModel)]="inputField"
          name="input"
          type="text"
          class="name-input"
        />
        <div class="placeholder" [class.hidden]="inputField.length">
          {{ defaultNickname }}
          <img src="/assets/img/chat-page/input-icon.svg" alt="" />
        </div>
      </div>
      <div class="vertical-line"></div>
      <button type="submit" class="answer-send-area">
        <img
          src="/assets/img/chat-page/send-btn.svg"
          alt=""
          class="send-icon"
        />
      </button>
    </form>
  } @else {
    @for (answer of steps[step].answers; track $index) {
      <div class="answer-message-button pointer" (click)="answer.action()">
        <div class="answer-content">
          <div class="message-text">{{ answer.text }}</div>
        </div>
        <div class="vertical-line"></div>
        <div class="answer-send-area">
          <img
            src="/assets/img/chat-page/send-btn.svg"
            alt=""
            class="send-icon"
          />
        </div>
      </div>
    }
  }
</div>
