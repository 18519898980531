import { PremiumDescriptionTextModule } from '../premium/description-text/premium-description-text.module';
import { PremiumButtonTextModule } from '../premium/button-text/premium-button-text.module';
import { SubscriptionsCatGirlTemplateComponent } from './catgirl/catgirl.component';
import { SubscriptionsOfferTemplateComponent } from './default/default.component';
import { ButtonModule } from '../controls/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    SubscriptionsCatGirlTemplateComponent,
    SubscriptionsOfferTemplateComponent,
  ],
  imports: [
    PremiumDescriptionTextModule,
    PremiumButtonTextModule,
    TranslateModule,
    CommonModule,
    ButtonModule,
    SwiperModule,
  ],
  exports: [
    SubscriptionsCatGirlTemplateComponent,
    SubscriptionsOfferTemplateComponent,
  ],
})
export class SubscriptionTemplatesModule {}
