import {
  BuyChatByGemsResponse,
  GetChatRewardsResponse,
  UseGiftResponse,
} from '../../../services/API_services/chat-api-methods.service';
import {
  Subscription,
  Observable,
  catchError,
  switchMap,
  timer,
  take,
  map,
  of,
} from 'rxjs';
import {
  SplitTestCasesService,
  SplitTestCase,
} from 'src/app/shared/services/split-test-cases.service';
import { ChatIsFinishedModalComponent } from 'src/app/shared/views/chat/modals/chat-is-finished-modal/chat-is-finished-modal.component';
import { AdditionalContentModalComponent } from '../modals/additional-content-modal/additional-content-modal.component';
import { DownloadAppModalComponent } from '../../../components/download-app-modal/download-app-modal.component';
import { SaveProgressToastService } from '../../../services/communication_services/save-progress-toast.service';
import { GiftsModalComponent } from 'src/app/shared/views/chat/modals/gifts-modal/gifts-modal.component';
import { SettingsDataService } from '../../../services/communication_services/settingsData.service';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { ChatImagesService } from 'src/app/shared/views/chat/services/chat-images.service';
import { TutorialService } from '../../../services/communication_services/tutorial.service';
import { FullscreenComponent } from '../../../components/fullscreen/fullscreen.component';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ChatByIdResponse } from '../../../types/chat-by-id-response.interface';
import { AppVisibilityService } from '../../../services/app-visibility.service';
import { OpenNextMessage } from '../../../types/open-next-message.interface';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PaywallService } from 'src/app/shared/services/paywall.service';
import { ChatMessageType } from '../../../enums/chat-message-type.enum';
import { ChatType } from 'src/app/shared/types/chat-data.interface';
import { SoundsService } from '../../../services/sounds.service';
import { ModalsService } from '../../../services/modals.service';
import { ChatOfflineService } from './chat-offline.service';
import { ChatAnswersService } from './chat-answers.service';
import { ChatContentService } from './chat-content.service';
import { environment } from 'src/environments/environment';
import { ChatStateService } from './chat-state.service';
import { Language } from '../../../enums/language.enum';
import { SoundsEnum } from '../../../enums/sounds.enum';
import { ChatTrialService } from './chat-trial.service';
import settings from '../../../data/settings';
import { NavHelper } from '../../../helpers';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AiPaywallComponent } from '../modals/ai-paywall/ai-paywall.component';

@Injectable({ providedIn: 'root' })
export class ChatService {
  private readonly _aiChatRequestDelay = 1000;
  private _chatLanguage: Language;
  private _feedbackModalShown = false;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _saveProgressToastService: SaveProgressToastService,
    private _websocketSignalRService: WebsocketSignalRService,
    private _splitTestCasesService: SplitTestCasesService,
    private _appVisibilityService: AppVisibilityService,
    private _settingsDataService: SettingsDataService,
    private _chatOfflineService: ChatOfflineService,
    private _chatAnswersService: ChatAnswersService,
    private _chatContentService: ChatContentService,
    private _chatImagesService: ChatImagesService,
    private _analyticsService: AnalyticsService,
    private _chatTrialService: ChatTrialService,
    private _chatStateService: ChatStateService,
    private _inventoryService: InventoryService,
    private _tutorialService: TutorialService,
    private _paywallService: PaywallService,
    private _modalsService: ModalsService,
    private _soundService: SoundsService,
    private _navHelper: NavHelper,
    private _platform: Platform,
  ) {}

  public openChat(chatId: string): Observable<boolean> {
    this.clear();
    this._subscriptions.push(
      this._chatAnswersService.answerSent
        .pipe(
          switchMap(() => this.getNextMessages(this._chatStateService.chatId)),
        )
        .subscribe(),

      this._chatOfflineService.offlineCanceled
        .pipe(
          switchMap(() => this.getNextMessages(this._chatStateService.chatId)),
        )
        .subscribe(),

      this._chatTrialService.trialCanceled
        .pipe(
          switchMap(() => this.getNextMessages(this._chatStateService.chatId)),
        )
        .subscribe(),

      this._chatAnswersService.clickAnswerWithoutAiTokens
        .pipe(
          switchMap(() => {
            if (!localStorage.getItem('catsino_visited')) {
              this._navHelper.goToCatsino(this._chatStateService.chatId);
              return of(null);
            }
            return this._modalsService.openModal(AiPaywallComponent);
          }),
        )
        .subscribe(),
    );

    return this._websocketSignalRService
      .invoke<ChatByIdResponse>(WebsocketCommandType.myChat, { chatId })
      .pipe(
        map((res) => {
          if (environment.mode === 'test') {
            console.log(res, 'myChat');
          }

          this._analyticsService.openChat(res.id, res.characterName);
          this._chatLanguage = res.locale ?? Language.english;

          if (res.isOffline) {
            if (res.type === ChatType.ai) {
              this._chatOfflineService.setAiOffline(
                res.messages[res.messages.length - 1]?.orderIdx ?? 0,
              );
            } else {
              this._chatOfflineService.setOffline({
                time: res.offlineTime,
                premiumTime: res.premiumOfflineTime,
                offlineSkipCost: res.offlineSkipCost,
                offlineSkipTokens: res.offlineSkipTokens,
                canSkipOfflineForAd: res.canSkipOfflineForAd,
                skipOfflinePackPurchase: res.skipOfflinePackPurchase,
                messageIndex: res.messages[res.messages.length - 1].orderIdx,
              });
            }
          }

          if (!res.isOffline) {
            this._chatStateService.setState('normal');
          }

          this._chatStateService.init(res);
          this.updateGamifyContent(res.id);
          this._chatImagesService.initChat(res);
          return true;
        }),
        catchError((res) => {
          if (res.error?.status === 'chatNotFound.') {
            this._navHelper.goToAllChats();
          }
          return of(false);
        }),
      );
  }

  public getNextMessages(chatId: string): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<OpenNextMessage[]>(WebsocketCommandType.nextMessages, { chatId })
      .pipe(
        switchMap((res) => {
          if (environment.mode === 'test') {
            console.log([...res], 'getNextMessages');
          }
          if (environment.mode === 'prod') {
            res = res.filter((m) => !m.nextMessage?.isDebug);
          }

          if (!res.length && this._chatStateService.isAiChat$.value) {
            this._chatStateService.isModelTyping$.next(true);

            return timer(this._aiChatRequestDelay).pipe(
              switchMap(() => this.getNextMessages(chatId)),
            );
          }

          if (
            res.find((m) => m.nextMessage?.type === ChatMessageType.modelImage)
          ) {
            this.updateGamifyContent(this._chatStateService.chatId);
          }

          this._chatStateService.chatLevel$.next(res[0].chatLevel);
          this._chatStateService.chatLevelProgress$.next(
            res[0].chatLevelProgress,
          );
          this._chatStateService.chatRating$.next(res[0].chatRating);
          this._chatStateService.hasAccessToSexting$.next(
            res[0].hasAccessToSexting ||
              this._settingsDataService.isPremiumActive,
          );

          if (res[0].nextMessage) {
            this._analyticsService.fetchMessagesPack({
              name: this._chatStateService.modelName$.value,
              messageIndex: res[0].nextMessage.orderIdx,
              chatId: res[0].nextMessage.chatId,
            });
          }

          return this.processNextMessages(res);
        }),
      );
  }

  public buyAdditionalContent(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<{ coins: number }>(WebsocketCommandType.buyAddOns, {
        chatId: this._chatStateService.chatId,
      })
      .pipe(
        map((res) => {
          this._inventoryService.setGems(res.coins);
          this._soundService.play(SoundsEnum.bonus2);
          this._analyticsService.buyAdditionalContent(
            'buy',
            this._chatStateService.modelName$.value,
          );
          this._subscriptions.push(
            this.getNextMessages(this._chatStateService.chatId).subscribe(),
          );
          return true;
        }),
      );
  }

  private processNextMessages(
    messages: OpenNextMessage[],
  ): Observable<boolean> {
    const message = messages.shift();

    if (
      (message.trialType === 'Time' && message.trialTime === 0) ||
      (message.trialType === 'Count' && message.trialCounterFilled)
    ) {
      this._chatStateService.setState('trialBlocked');
      this._chatTrialService.setTrial(
        {
          trialPurchase: message.trialPurchase,
          subscriptionPurchase: message.subscriptionPurchase,
        },
        message.splitTestVariant,
      );
    }

    if (message.aiChatRating) {
      this._chatStateService.aiChatRating$.next(message.aiChatRating);
    }
    if (message.aiChatContentInfoShort) {
      this._chatStateService.aiChatContentInfo$.next(
        message.aiChatContentInfoShort,
      );
    }

    const nextMessage = message.nextMessage;

    if (
      (message.isFinished && !message.hasAdditionalContent) ||
      (message.isFinished &&
        message.isAdditionalPurchased &&
        message.isAdditionalFinished)
    ) {
      this._chatStateService.setState('finished');
    }

    if (
      message.isFinished &&
      !message.isFeedbackSent &&
      !this._tutorialService.currentStep$.value
    ) {
      this.showFeedBackForm()
        .pipe(
          switchMap(() => {
            if (
              message.hasAdditionalContent &&
              !message.isAdditionalPurchased
            ) {
              return this.showAdditionalContentModal({
                chatId: this._chatStateService.chatId,
                cost: message.additionalContentCost,
                name: this._chatStateService.modelName$.value,
              });
            }
            return of(null);
          }),
        )
        .subscribe();
    }

    if (message.isBanned) {
      return this.enableBan(message);
    }

    if (!nextMessage) {
      return of(true);
    }

    if (this._chatStateService.isAiChat$.value && message.isOffline) {
      this._chatOfflineService.setAiOffline(nextMessage.orderIdx);
      return of(true);
    }

    if (nextMessage.type === ChatMessageType.offline) {
      this._chatOfflineService.setOffline({
        time: message.offlineTime,
        premiumTime: message.premiumOfflineTime,
        messageIndex: nextMessage.orderIdx,
        canSkipOfflineForAd: message.canSkipOfflineForAd,
        offlineSkipCost: message.offlineSkipCost,
        offlineSkipTokens: message.offlineSkipTokens,
        skipOfflinePackPurchase: message.skipOfflinePackPurchase,
      });
      return of(true);
    } else {
      this._chatStateService.setState('normal');
    }

    if (nextMessage.chatId?.length < 15) {
      // legacy hack?
      return of(false);
    }

    if (nextMessage.showNickNameInput) {
      return this._chatAnswersService.showNicknameInput(nextMessage);
    }

    if (
      nextMessage.answers?.length ||
      (!nextMessage.answers?.length &&
        !nextMessage.messageText &&
        nextMessage.type === ChatMessageType.hero)
    ) {
      this._chatAnswersService.showAnswers(nextMessage);
      return of(true);
    }

    this._chatStateService.isModelTyping$.next(true);

    let responseTime =
      nextMessage.type === ChatMessageType.model
        ? settings.messageAwait + nextMessage.messageText?.length * 20
        : 0;

    if (this._settingsDataService.updateSettingsData$.value.quickMessaging) {
      responseTime = 0;
    }

    if (responseTime > 3000) {
      responseTime = 3000;
    }

    if (this._chatStateService.isAiChat) {
      responseTime = 0;
    }

    return timer(responseTime).pipe(
      switchMap(() => {
        this._chatStateService.isModelTyping$.next(false);
        nextMessage.displayTime = this._chatStateService.getCurrentDateTime();
        nextMessage.isNewMessage = true;

        this._chatStateService.addMessage(nextMessage);

        if (nextMessage.type !== ChatMessageType.model) {
          this._soundService.play(SoundsEnum.sentMessage2);
        } else {
          this._soundService.play(SoundsEnum.sentMessage1);
        }

        if (nextMessage.type === ChatMessageType.modelImage) {
          const photosCount = this._chatStateService.messages$.value.filter(
            (m) => m.type === ChatMessageType.modelImage,
          ).length;

          this._analyticsService.getPhoto({
            name: nextMessage.characterId,
            messageIndex: nextMessage.orderIdx,
            chatId: nextMessage.chatId,
            photo_n: photosCount,
          });
        }

        if (
          nextMessage.type === ChatMessageType.modelImage ||
          nextMessage.type === ChatMessageType.modelVideo
        ) {
          this.shopSaveProgressModal();
        }

        this._analyticsService.openMessage({
          messageId: nextMessage.id,
          chatId: nextMessage.chatId,
          contentLink: nextMessage.contentLink,
        });

        if (messages.length) {
          return timer(700).pipe(
            switchMap(this._appVisibilityService.waitForAppVisibility),
            switchMap(() => this.processNextMessages(messages)),
          );
        }

        return this.getNextMessages(this._chatStateService.chatId);
      }),
    );
  }

  private updateGamifyContent(chatId: string) {
    if (this._chatStateService.isAiChat) {
      this._chatContentService.updateContentInfo(chatId);
    }
  }

  private enableBan(message: OpenNextMessage): Observable<boolean> {
    const lastMessageIndex =
      this._chatStateService.messages$.value[
        this._chatStateService.messages$.value.length - 1
      ].orderIdx;

    this._analyticsService.isBanned(
      this._chatStateService.chatId,
      this._chatStateService.modelName$.value,
      lastMessageIndex,
    );

    this._chatStateService.unbanCost$.next(message.unbanCost);
    this._chatStateService.setState('banned');
    return of(true);
  }

  public unban(): Observable<boolean> {
    const lastMessageIndex =
      this._chatStateService.messages$.value[
        this._chatStateService.messages$.value.length - 1
      ].orderIdx;

    this._analyticsService.clickOnUnban(
      this._chatStateService.chatId,
      this._chatStateService.modelName$.value,
      lastMessageIndex,
    );

    return this._websocketSignalRService
      .invoke<BuyChatByGemsResponse>(WebsocketCommandType.unban, {
        chatId: this._chatStateService.chatId,
      })
      .pipe(
        map((res) => {
          this._inventoryService.setGems(res.coins);

          this._analyticsService.unbanBoughtBySoft(
            this._chatStateService.chatId,
            this._chatStateService.unbanCost$.value,
          );
          this.getNextMessages(this._chatStateService.chatId).subscribe();
          this._chatStateService.setState('normal');
          return true;
        }),
        catchError((error) => {
          if (error.error.status === 'notEnoughCoins') {
            this._navHelper.goToShopGems();
          }
          return of(false);
        }),
      );
  }

  public saveToGalery(
    chatId: string,
    url: string,
    contentType: string,
    messageId: string,
  ): Observable<boolean> {
    // COST is rudiment. Kill on Server side
    return this._websocketSignalRService
      .invoke<{ coins: number }>(WebsocketCommandType.addToGallery, {
        chatId,
        url,
        cost: 0,
        contentType,
        messageId,
      })
      .pipe(map(() => true));
  }

  //#region //* MODALS
  public openMediaModal(messageId: string): Observable<void> {
    const contentData = this._chatStateService.messages$.value
      .filter((m) => {
        if (!m.contentLink) {
          return false;
        }
        if (this._tutorialService.currentStep$.value) {
          return true;
        }
        if (m.hiddenInTrial) {
          return false;
        }
        if (m.ratingRequired > this._chatStateService.chatRating$.value) {
          return false;
        }

        return true;
      })

      .map((m) => ({
        contentType: m.type === ChatMessageType.modelImage ? 'Image' : 'Video',
        url: m.contentLink,
        isHide: m.isHide,
        isSave: m.isSave,
        id: m.id,
        chatId: m.chatId,
        cost: 0,
      }));

    const startWith = contentData.findIndex((el) => el.id === messageId);
    return this.openMediaModalByIndex(startWith);
  }

  public openMediaModalByIndex(index: number): Observable<void> {
    const contentData = this._chatStateService.messages$.value
      .filter((m) => {
        if (!m.contentLink) {
          return false;
        }
        if (this._tutorialService.currentStep$.value) {
          return true;
        }
        if (m.hiddenInTrial) {
          return false;
        }
        if (m.ratingRequired > this._chatStateService.chatRating$.value) {
          return false;
        }

        return true;
      })

      .map((m) => ({
        contentType: m.type === ChatMessageType.modelImage ? 'Image' : 'Video',
        url: m.contentLink,
        isHide: m.isHide,
        isSave: m.isSave,
        id: m.id,
        chatId: m.chatId,
        cost: 0,
      }));

    return this._modalsService.openModal(FullscreenComponent, {
      contentData,
      startWith: index,
    });
  }

  //TODO
  public sendFeedbackForm(chatId: string, data: any): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<{ coins: number }>(WebsocketCommandType.chatFeedback, {
        chatId,
        ...data,
      })
      .pipe(
        map((res) => {
          if (res) {
            this._inventoryService.setGems(res.coins);
            return true;
          }
        }),
      );
  }

  public getChatRewards(chatId: string): Observable<GetChatRewardsResponse> {
    return this._websocketSignalRService.invoke<GetChatRewardsResponse>(
      WebsocketCommandType.getChatRewardInfo,
      { chatId },
    );
  }

  public openGiftsModal(from: string): Observable<void> {
    return this._modalsService
      .openModal<UseGiftResponse | Error>(GiftsModalComponent, {
        chatId: this._chatStateService.chatId,
        modelName: this._chatStateService.modelName$.value,
        openFrom: from,
      })
      .pipe(
        map((res) => {
          if (res) {
            if (res instanceof Error) {
              if (res.message === 'Not enough gifts') {
                this._navHelper.goToShopGifts();
              }
            } else {
              this._chatStateService.chatLevel$.next(res.chatLevel);
              this._chatStateService.chatLevelProgress$.next(
                res.chatLevelChange,
              );
              this._chatStateService.chatRating$.next(res.chatRating);

              this._inventoryService.setGems(res.coins);
            }
          }
        }),
      );
  }

  private shopSaveProgressModal() {
    if (environment.buildVersion === BuildVersion.default) {
      setTimeout(() => {
        if (this._platform.is('android') && this._platform.is('mobileweb')) {
          this._modalsService
            .openModal(
              DownloadAppModalComponent,
              {
                showedPlace: `Chat: ${this._chatStateService.modelName$.value}`,
              },
              true,
            )
            .pipe(take(1))
            .subscribe();
        } else {
          this._saveProgressToastService.showIfIsGuest();
        }
      }, 1000);
    }
  }

  private showFeedBackForm(): Observable<void> {
    if (this._feedbackModalShown) {
      return of(null);
    }

    this._feedbackModalShown = true;

    return this._modalsService.openModal(ChatIsFinishedModalComponent, {
      askTranslationRating: this._chatLanguage !== Language.english,
      chatId: this._chatStateService.chatId,
    });
  }

  private showAdditionalContentModal(data: {
    name: string;
    cost: number;
    chatId: string;
  }): Observable<void> {
    return this._modalsService.openModal(AdditionalContentModalComponent, {
      data,
    });
  }

  //#endregion

  private clear() {
    this._subscriptions.forEach((s) => s.unsubscribe());

    this._chatStateService.clear();
    this._chatOfflineService.clear();
    this._chatAnswersService.clear();
    this._chatTrialService.clear();

    this._feedbackModalShown = false;
  }
}
