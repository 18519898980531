import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavHelper } from '../../helpers';
import { UiStateService } from '../../services/communication_services/uiStates.service';
import { Subscription, exhaustMap, switchMap, tap, timer } from 'rxjs';
import { ChatImagesService } from 'src/app/shared/views/chat/services/chat-images.service';

@Component({
  selector: 'app-new-photo-notification',
  templateUrl: './new-photo-notification.component.html',
  styleUrls: ['./new-photo-notification.component.scss'],
})
export class NewPhotoNotificationComponent implements OnInit, OnDestroy {
  public isOpen = false;
  public enabled = false;

  private _subscription: Subscription;

  constructor(
    private _navHelper: NavHelper,
    private _uiStateService: UiStateService,
    private _chatImagesService: ChatImagesService,
  ) {}

  public ngOnInit(): void {
    this._subscription = this._chatImagesService.newPhotoEvent
      .pipe(exhaustMap(() => this.show()))
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public show() {
    this.enabled = true;

    return timer(1).pipe(
      switchMap(() => {
        this.isOpen = true;
        return timer(3000).pipe(
          switchMap(() => {
            this.isOpen = false;
            return timer(500).pipe(tap(() => (this.enabled = false)));
          }),
        );
      }),
    );
  }

  public openProfile() {
    this._navHelper.goToProfile(
      this._uiStateService.updateStateData.value.chatId,
    );
  }
}
