// import { LoggingServiceConfiguration } from 'ionic-logging-service';

export enum BuildVersion {
  erogames = 'erogames',
  legal = 'legal',
  default = 'default',
  nutaku = 'nutaku',
}

export interface EnvironmentModel {
  appId: string;
  appName: string;
  gameName: string;
  production: boolean;
  apiUrl: string;
  webSiteUrl: string;
  buildVersion: BuildVersion;
  mode: 'test' | 'prod';
  showTncs: boolean;
  allowRegistrationForm: boolean;
  allowPaymentIframe: boolean;
  isPromoWidgetChat: boolean;
  showAdmobAds: boolean;
  splashScreenImgSrc: string;
  showOnlineWebcamNotification: boolean;
  rootPath: string;
  discordLink: string;
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  policy: {
    name: string;
    url: string;
    site: string;
    docsBaseUrl: string;
    email: string;
  };
  nutakuConfig?: {
    parentUrl: string;
    buyGoldUrl: string;
  };
  // logging?: LoggingServiceConfiguration;
  promoCodeIsAvailable: boolean;
  banners: any[];
  admob?: {
    bannerId: string;
    interstitial: string;
    rewardVideoId: string;
  };
  appMetricaKey: string;
}
