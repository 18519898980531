import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { environment } from 'src/environments/environment';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription, take, tap } from 'rxjs';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { PaymentsService } from 'src/app/shared/services/payments/payments.service';
import { PurchaseSource } from 'src/app/shared/enums/purchase-source.enum';
import { ShopLot } from 'src/app/shared/types/shop-lot-new.interface';
import {
  TermsModalComponent,
  TermsType,
} from 'src/app/shared/components/terms-modal/terms-modal.component';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { PaywallService } from 'src/app/shared/services/paywall.service';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { ShopTokensService } from '../../../shop-tokens/shop-tokens.service';

@Component({
  selector: 'app-ai-paywall',
  templateUrl: './ai-paywall.component.html',
  styleUrls: ['./ai-paywall.component.scss'],
})
export class AiPaywallComponent implements OnInit, OnDestroy {
  @ViewChild('swiper', { static: false }) public swiper?: SwiperComponent;

  public readonly swiperConfig: SwiperOptions = {
    initialSlide: this._settingsDataService.isPremiumActive ? 1 : 0,
    spaceBetween: 0,
    slidesPerView: 3,
    autoplay: { delay: 10000, disableOnInteraction: true },
    loop: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      hideOnClick: false,
      type: 'bullets',
    },
    on: {
      activeIndexChange: (e) => {
        this.selectSubscription(this.subscriptions[e.activeIndex % 3]);
        this._changeDetection.detectChanges();
      },
    },
  };

  public subscriptions: OfferData[] = [];
  public selectedSubscription: OfferData;

  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;
  public readonly offerType = OfferType;
  public readonly messageCost: number;
  public readonly tokenLots$ = this._shopTokensService.lots$;
  public readonly isPremiumActive$ = this._settingsDataService.isPremiumActive$;

  private _subscribers: Subscription[] = [];

  constructor(
    private _settingsDataService: SettingsDataService,
    private _shopTokensService: ShopTokensService,
    private _changeDetection: ChangeDetectorRef,
    private _analyticsService: AnalyticsService,
    private _paymentsService: PaymentsService,
    private _modalController: ModalController,
    private _paywallService: PaywallService,
    private _modalsService: ModalsService,
  ) {
    this.messageCost =
      _settingsDataService.updateSettingsData$.value.messageCostInTokens;
    this._analyticsService.showChatcoinsOffer('ai_paywall');
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this._paywallService.premiumData.find(
        (o) => o.type === OfferType.monthlySubscription,
      ),
      this._paywallService.premiumData.find(
        (o) => o.type === OfferType.weeklySubscription,
      ),
      this._paywallService.premiumData.find(
        (o) => o.type === OfferType.yearlySubscription,
      ),
    );

    this.selectedSubscription = this.subscriptions[1] ?? this.subscriptions[0];
  }

  public purchaseSubscription(subscription: OfferData) {
    this._paymentsService
      .purchaseOffer(subscription, PurchaseSource.modal)
      .subscribe((res) => {
        if (res) {
          this.close();
        }
      });
  }

  public purchaseLot(lot: ShopLot) {
    this._paymentsService
      .purchaseLot(lot, PurchaseSource.modal)
      .subscribe((res) => {
        if (res) {
          this.close();
        }
      });
  }

  public clickOnSlide(subscription: OfferData) {
    const selectedIndex = this.subscriptions.findIndex(
      (s) => s === this.selectedSubscription,
    );
    const clickIndex = this.subscriptions.findIndex((s) => s === subscription);

    if (clickIndex === selectedIndex) {
      return;
    }
    if (
      (clickIndex === 1 && selectedIndex === 2) ||
      (clickIndex === 2 && selectedIndex === 0) ||
      (clickIndex === 0 && selectedIndex === 1)
    ) {
      this.swiper.swiperRef.slidePrev();
    } else {
      this.swiper.swiperRef.slideNext();
    }
  }

  public selectSubscription(subscription: OfferData): void {
    this.selectedSubscription = subscription;
  }

  public openPolicy(): void {
    this._subscribers.push(
      this._modalsService
        .openModal(TermsModalComponent, {
          type: TermsType.privacy,
        })
        .subscribe(),
    );
  }

  public openTerms(): void {
    this._subscribers.push(
      this._modalsService
        .openModal(TermsModalComponent, {
          type: TermsType.termsOfUse,
        })
        .subscribe(),
    );
  }

  public close(): void {
    this._modalController.dismiss();
  }

  public ngOnDestroy(): void {
    this._subscribers.forEach((s) => s?.unsubscribe());
  }
}
