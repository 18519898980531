<div class="modal-wrapper">
  <div (click)="close()" class="close-bg"></div>
  <div class="modal-content">
    <div class="modal-head">
      <h3 class="title">
        {{ notification.title || "Notification" }}
      </h3>
    </div>

    <div class="text-block">
      {{ notification.text }}
    </div>

    <app-button (click)="close()">
      {{ "SHARED.CLOSE" | translate }}
    </app-button>
  </div>
</div>
