import { PremiumSuccessModalComponent } from './success-modal.component';
import { ButtonModule } from '../../controls/button/button.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [PremiumSuccessModalComponent],
  imports: [CommonModule, ButtonModule],
  exports: [PremiumSuccessModalComponent],
})
export class PremiumSuccessModalModule {}
