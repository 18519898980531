import {
  ChatProfile,
  ChatType,
} from 'src/app/shared/types/chat-data.interface';
import { UiStateService } from 'src/app/shared/services/communication_services/uiStates.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import {
  AiChatContentInfo,
  AiChatRating,
  ChatByIdResponse,
} from 'src/app/shared/types/chat-by-id-response.interface';
import { ChatMessageType } from 'src/app/shared/enums/chat-message-type.enum';
import { NextMessage } from 'src/app/shared/types/next-message.interface';
import { UiStatesEnum } from 'src/app/shared/enums/ui-states.enum';
import { BehaviorSubject, Subject, map, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { SubscriptionStatus } from 'src/app/shared/types/user-my-response.interface';

export type ChatState =
  | 'notLoaded'
  | 'normal'
  | 'trialBlocked'
  | 'offline'
  | 'aiOffline'
  | 'banned'
  | 'finished';

@Injectable({ providedIn: 'root' })
export class ChatStateService {
  public readonly chatState$: BehaviorSubject<ChatState> = new BehaviorSubject(
    'normal',
  );
  public readonly isOffline$ = this.chatState$.pipe(
    map((state) => state === 'aiOffline' || state === 'offline'),
  );
  public readonly messages$: BehaviorSubject<NextMessage[]> =
    new BehaviorSubject([]);
  public readonly modelProfile$: BehaviorSubject<ChatProfile> =
    new BehaviorSubject(null);
  public readonly isModelTyping$ = new BehaviorSubject(false);

  public readonly isMessagesOpen$ = new BehaviorSubject(false);
  public readonly isAiChat$ = new BehaviorSubject(false);
  public readonly chatType$ = new BehaviorSubject<ChatType>(null);
  public readonly hasAccessToSexting$ = new BehaviorSubject(false);
  public readonly unbanCost$ = new BehaviorSubject(0);
  public readonly chatRating$ = new BehaviorSubject(0);
  public readonly chatLevel$ = new BehaviorSubject(0);
  public readonly chatLevelProgress$ = new BehaviorSubject(0);
  public readonly modelName$ = new BehaviorSubject('');
  public readonly modelAvatar$ = new BehaviorSubject('');

  public readonly aiChatRating$ = new BehaviorSubject<AiChatRating>(null);
  public readonly aiChatContentInfo$ = new BehaviorSubject<AiChatContentInfo>(
    null,
  );

  public readonly onAddNewMessage$ = new Subject();

  private _chatId: string;
  private _bigCharacterAvatar: string;

  public get chatId() {
    return this._chatId;
  }

  public get isAiChat() {
    return this.isAiChat$.value;
  }

  public get bigCharacterAvatar() {
    return this._bigCharacterAvatar;
  }

  constructor(
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
    private _uiStateService: UiStateService,
  ) {
    this._settingsDataService.updateSettingsData$.subscribe((data) => {
      if (data?.subscriptionStatus === SubscriptionStatus.active) {
        this.hasAccessToSexting$.next(true);
      }
    });
  }

  public init(data: ChatByIdResponse) {
    this._chatId = data.id;
    this._bigCharacterAvatar = data.bigCharacterAvatar;
    this.setMessages(data.messages);
    this.modelName$.next(data.characterName);
    this.modelAvatar$.next(data.characterAvatar);
    this.modelProfile$.next(data.profile);
    this._uiStateService.changeData(UiStatesEnum.chatId, data.id);
    this._uiStateService.changeData(
      UiStatesEnum.avatarUrl,
      data.characterAvatar,
    );
    this._uiStateService.changeData(
      UiStatesEnum.characterName,
      data.characterName,
    );
    this.chatType$.next(data.type);
    this.isAiChat$.next(data.type === ChatType.ai);

    if (data.aiChatRating) {
      this.aiChatRating$.next(data.aiChatRating);
    }
    if (data.aiChatContentInfoShort) {
      this.aiChatContentInfo$.next(data.aiChatContentInfoShort);
    }

    this.hasAccessToSexting$.next(
      data.hasAccessToSexting || this._settingsDataService.isPremiumActive,
    );
  }

  public setChatId(id: string) {
    this._chatId = id;
  }

  public setState(state: ChatState) {
    this.chatState$.next(state);
  }

  public setMessages(messages: NextMessage[]) {
    if (environment.mode === 'prod') {
      messages = messages.filter((m) => !m.isDebug);
    }
    messages = messages.filter((m) => m.type !== ChatMessageType.offline);
    messages.forEach((m) => (m.displayTime = ''));

    if (messages.length === 1) {
      this.isModelTyping$.next(true);
      timer(2000).subscribe(() => {
        this.messages$.next(messages);
        this.isModelTyping$.next(false);
      });
    } else {
      this.messages$.next(messages);
    }
  }

  public addMessagePartial(message: Partial<NextMessage>): NextMessage {
    const defaultMessage: NextMessage = {
      chatId: this.chatId,
      duration: null,
      answers: [],
      displayTime: this.getCurrentDateTime(),
      emoji: 0,
      id: '',
      isSave: false,
      messageText: '',
      localizedMessageText: null,
      localizedContentLink: null,
      orderIdx: 0,
      type: ChatMessageType.hero,
      ratingRequired: 0,
      visibleForRegistered: false,
      hiddenInTrial: false,
      isAgeQuestion: null,
      isAgreementQuestion: null,
      advertisement: null,
      isDebug: false,
      isSexting: false,
    };
    const newMessage: NextMessage = { ...defaultMessage, ...message };
    this.addMessage(newMessage);

    return newMessage;
  }

  public addMessage(message: NextMessage): NextMessage {
    if (
      this.isAiChat$.value &&
      message.type !== ChatMessageType.hero &&
      message.orderIdx > 0
    ) {
      this._analyticsService.aiGirlMessage({
        messageNumber:
          this.messages$.value.filter(
            (m) => m.orderIdx > 0 && m.type !== ChatMessageType.hero,
          ).length + 1,
        isSexting: message.isSexting,
        type: message.type,
        messageLength: (
          message.messageText ??
          message.localizedMessageText.en ??
          ''
        ).length,
        rating: this.aiChatRating$.value.rating,
        maxRelationships: this.aiChatRating$.value.ratingCap,
      });
    }

    this.messages$.next([...this.messages$.value, message]);
    this.onAddNewMessage$.next(null);
    return message;
  }

  public removeMessage(message: NextMessage) {
    const index = this.messages$.value.findIndex((m) => message.id === m.id);
    this.messages$.value.splice(index, 1);
    this.onAddNewMessage$.next(null);
  }

  public clear() {
    this.chatState$.next('notLoaded');
    this._chatId = '';
    this.isModelTyping$.next(false);
    this.chatRating$.next(0);
    this.modelProfile$.next(null);
    this.modelName$.next('');
    this.messages$.next([]);
    this.unbanCost$.next(0);
    this._bigCharacterAvatar = '';
    this.isAiChat$.next(false);

    this._uiStateService.changeData(UiStatesEnum.avatarUrl, '');
    this._uiStateService.changeData(UiStatesEnum.characterName, '');
  }

  public getCurrentDateTime() {
    const date = new Date();

    return date.toLocaleString('eu', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }
}
