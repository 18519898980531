<div class="info-modal">
  <div class="head">
    Offer information
    <button (click)="close()" class="close-btn">&times;</button>
  </div>
  <ul class="rules">
    <li>There are only 9 rewards in the event.</li>
    <li>
      To receive any reward, you need to click on the cell, spend tokens and
      erase the pumpkin drawing.
    </li>
    <li>
      After erasing the drawing, the reward must be collected using the Get
      button.
    </li>
    <li>After receiving all the rewards, the event window will disappear.</li>
    <li>
      If at the end of the event you have unclaimed rewards, they will
      disappear.
    </li>
    <li>
      In the list of rewards there are: Tokens, Swipes, Offline Skips and Unique
      Girl Chat.
    </li>
  </ul>
</div>
