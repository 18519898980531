<h2 class="title">I want to chat with</h2>

<div class="gender-selector">
  <app-button
    (click)="isBoys = false"
    [class.selected]="isBoys === false"
    [disabled]="pending"
  >
    Girls
  </app-button>
  <app-button
    (click)="isBoys = true"
    [class.selected]="isBoys === true"
    [disabled]="pending"
  >
    Boys
  </app-button>
</div>

<app-button (click)="submit()" [pending]="pending" class="continue-btn">
  Continue
</app-button>
