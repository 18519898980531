import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { Gift, UseGiftResponse } from '../../types/gift.interface';

@Injectable({
  providedIn: 'root',
})
export class GiftsApiService {
  constructor(private _websocketSignalRService: WebsocketSignalRService) {}

  public getGifts(): Observable<Gift[]> {
    return this._websocketSignalRService.invoke<any>(
      WebsocketCommandType.getMyGifts,
      {}
    );
  }

  public useGift(giftId: string, chatId: string): Observable<UseGiftResponse> {
    return this._websocketSignalRService.invoke<any>(
      WebsocketCommandType.useGiftFromChat,
      { giftId, chatId }
    );
  }
}
