<div (click)="toogleCollapse()" class="hide-btn">>></div>

<ng-container *ngIf="!collapsed">
  <div (click)="copyId()" class="id">{{ id$ | async }}</div>

  <div *ngIf="splitTests$ | async as splitTests" class="split-tests">
    <span> Split tests: </span>
    <ul>
      <li *ngFor="let splitTest of splitTests">{{ splitTest }}</li>
    </ul>
  </div>

  <div class="inventory">
    <span> Inventory: </span>
    <ul>
      <ng-container *ngFor="let item of inventory">
        <li *ngIf="item[1] | async as count">
          <span>{{ item[0] }}:</span>
          <span>{{ count }}</span>
        </li>
      </ng-container>
    </ul>
  </div>

  <app-button (click)="wipe()" variant="flat"> Delete account </app-button>

  <app-button (click)="addTokens()" variant="flat">
    Add 1000 tokens
  </app-button>

  <app-button
    *ngIf="(hasSubscription$ | async) === false"
    (click)="addSubscription()"
    variant="flat"
  >
    Add subscription
  </app-button>

  <app-button
    *ngIf="(hasSubscription$ | async) === true"
    (click)="removeSubscription()"
    variant="flat"
  >
    Remove subscription
  </app-button>

  <app-button
    *ngIf="(userPreference$ | async) !== 'None'"
    (click)="toggleUserPreferences()"
    variant="flat"
  >
    Switch to
    <ng-container *ngIf="(userPreference$ | async) === 'Girl'">
      Boys
    </ng-container>
    <ng-container *ngIf="(userPreference$ | async) === 'Boy'">
      Girls
    </ng-container>
  </app-button>

  <app-button (click)="getChat()" variant="flat">Get chat</app-button>

  <app-button *ngIf="isChatPage" (click)="copyChatHistory()" variant="flat">
    Copy chat history
  </app-button>

  <app-button (click)="toggleAnalyticsLogging()" variant="flat">
    @if (isAnalyticsLoggingEnabled) {
      Disable analytics logging
    } @else {
      Enable analytics logging
    }
  </app-button>

  <app-button
    *ngIf="(isTutorialActive$ | async) === true"
    (click)="skipTutorial()"
    variant="flat"
  >
    Skip tutorial
  </app-button>

  <!-- <app-button (click)="setAiChatOffline(true)" variant="flat">
    Ai chat offline enable
  </app-button>

  <app-button (click)="setAiChatOffline(false)" variant="flat">
    Ai chat offline disable
  </app-button> -->
</ng-container>
