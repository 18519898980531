/* eslint-disable */

import { registerPlugin, Plugin } from '@capacitor/core';

export interface User {
  id: number;
  email?: string;
  username: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  avatarUrl?: string;
  language?: string;
  balance: number;
}

export interface Token {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  accessToken: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  refreshToken: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  tokenType: string;
  scope: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expiresIn: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  createdAt: number;
}

export interface Whitelabel {
  slug: string;
  name: string;
  url: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  logoUrl?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  authorizeUrl: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  tokenUrl: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  profileUrl: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  buyErogoldUrl: object; //Map<string, string>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  supportUrl: object; //Map<string, string>
}

export interface PaymentInfo {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  paymentId: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  debitAmount: number;
}

export interface QuestData {
  quest: Quest;
  leaders: Leader[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  userClanAttempt: UserClanAttempt | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  userScore: UserScore;
}

interface Quest {
  id: number;
  title: string;
  description: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  coverUrl: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  finished_At: string;
}

interface Leader {
  position: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  coverPictureUrl: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  clanLeaderName: string | null;
  score: number;
}

interface UserClanAttempt {
  position: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  coverPictureUrl: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  clanLeaderName: string;
  score: number;
}

interface UserScore {
  total: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  earnedPoints: EarnedPoint[];
}

interface EarnedPoint {
  points: number;
  title: string;
}

export interface ErogamesAuthPlugin extends Plugin {
  init(options: { clientId: string });

  login(options: { locale: string });

  signup(options: { locale: string });

  logout();

  getUser(): Promise<{ user: string; error: string }>;

  reloadUser(): Promise<any>;

  getToken(): Promise<{ token: string; error: string }>;

  refreshToken(): Promise<any>;

  getWhitelabel(): Promise<{ whitelabel: string; error: string }>;

  loadWhitelabel(): Promise<any>;

  proceedPayment(options: { paymentId: string; amount: number }): Promise<any>;

  loadPaymentInfo(options: { paymentId: string }): Promise<any>;

  loadCurrentQuest(): Promise<any>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const ErogamesAuthPlugin = registerPlugin<ErogamesAuthPlugin>('ErogamesAuth');

type AuthCallback = (user: User | null, error: Error | null) => any;

export class ErogamesAuthImpl {
  private readonly erogamesAuthPlugin = ErogamesAuthPlugin;

  private readonly onAuthEvent = 'onAuthEvent';
  private readonly onReloadUserEvent = 'onReloadUserEvent';
  private readonly onRefreshTokenEvent = 'onRefreshTokenEvent';
  private readonly onLoadWhitelabelEvent = 'onLoadWhitelabelEvent';
  private readonly onProceedPaymentEvent = 'onProceedPaymentEvent';
  private readonly onCurrentQuestEvent = 'onCurrentQuestEvent';
  private readonly onPaymentInfoEvent = 'onPaymentInfoEvent';

  onAuth(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.erogamesAuthPlugin.addListener(this.onAuthEvent, (info: any) => {
        resolve(info);
      });
    });
  }

  init(pClientId: string) {
    this.erogamesAuthPlugin.init({ clientId: pClientId });
  }

  login(pLocale: string = null) {
    this.erogamesAuthPlugin.login({ locale: pLocale });
  }

  signup(pLocale: string = null) {
    this.erogamesAuthPlugin.signup({ locale: pLocale });
  }

  logout() {
    this.erogamesAuthPlugin.logout();
  }

  async getUser(): Promise<User> {
    const userResp = await this.erogamesAuthPlugin.getUser();
    if (userResp.user) {
      return Promise.resolve(JSON.parse(userResp.user));
    }
    throw userResp.error;
  }

  async reloadUser(): Promise<User> {
    const p: Promise<User> = new Promise(async (resolve, reject) => {
      const listener = await this.erogamesAuthPlugin.addListener(
        this.onReloadUserEvent,
        (info: any) => {
          if (info.user) {
            resolve(info.user);
          } else {
            reject(info.error);
          }
          listener.remove();
        },
      );
    });
    this.erogamesAuthPlugin.reloadUser();
    return p;
  }

  async getToken(): Promise<Token> {
    const tokenResp = await this.erogamesAuthPlugin.getToken();
    if (tokenResp.token) {
      return Promise.resolve(JSON.parse(tokenResp.token));
    }
    throw tokenResp.error;
  }

  refreshToken() {
    const p: Promise<Token> = new Promise(async (resolve, reject) => {
      const listener = await this.erogamesAuthPlugin.addListener(
        this.onRefreshTokenEvent,
        (info: any) => {
          if (info.token) {
            resolve(info.token);
          } else {
            reject(info.error);
          }
          listener.remove();
        },
      );
    });
    this.erogamesAuthPlugin.refreshToken();
    return p;
  }

  async getWhitelabel(): Promise<Whitelabel> {
    const whitelabelResp = await this.erogamesAuthPlugin.getWhitelabel();
    if (whitelabelResp.whitelabel) {
      return Promise.resolve(JSON.parse(whitelabelResp.whitelabel));
    }
    throw whitelabelResp.error;
  }

  loadWhitelabel() {
    const p: Promise<Whitelabel> = new Promise(async (resolve, reject) => {
      const listener = await this.erogamesAuthPlugin.addListener(
        this.onLoadWhitelabelEvent,
        (info: any) => {
          if (info.whitelabel) {
            resolve(info.whitelabel);
          } else {
            reject(info.error);
          }
          listener.remove();
        },
      );
    });
    this.erogamesAuthPlugin.loadWhitelabel();
    return p;
  }

  proceedPayment(pPaymentId: string, pAmount: number) {
    const p: Promise<boolean> = new Promise(async (resolve, reject) => {
      const listener = await this.erogamesAuthPlugin.addListener(
        this.onProceedPaymentEvent,
        (info: any) => {
          if (info.proceedPayment) {
            resolve(info.proceedPayment);
          } else {
            reject(info.error);
          }
          listener.remove();
        },
      );
    });
    this.erogamesAuthPlugin.proceedPayment({
      paymentId: pPaymentId,
      amount: pAmount,
    });
    return p;
  }

  loadPaymentInfo(pPaymentId: string) {
    const p: Promise<PaymentInfo> = new Promise(async (resolve, reject) => {
      const listener = await this.erogamesAuthPlugin.addListener(
        this.onPaymentInfoEvent,
        (info: any) => {
          if (info.paymentInfo) {
            resolve(info.paymentInfo);
          } else {
            reject(info.error);
          }
          listener.remove();
        },
      );
    });
    this.erogamesAuthPlugin.loadPaymentInfo({ paymentId: pPaymentId });
    return p;
  }

  loadCurrentQuest() {
    const p: Promise<QuestData> = new Promise(async (resolve, reject) => {
      const listener = await this.erogamesAuthPlugin.addListener(
        this.onCurrentQuestEvent,
        (info: any) => {
          if (info.currentQuest) {
            resolve(info.currentQuest);
          } else {
            reject(info.error);
          }
          listener.remove();
        },
      );
    });
    this.erogamesAuthPlugin.loadCurrentQuest();
    return p;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const ErogamesAuth = new ErogamesAuthImpl();
export default ErogamesAuth;
