import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedText } from '../types/localized-text.interface';
import { TranslateConfigService } from '../services/translate-config.service';
import { Language } from '../enums/language.enum';

@Pipe({
  name: 'localize',
})
export class LocalizePipe implements PipeTransform {
  private _currentLanguage: Language;

  constructor(private _translateConfigService: TranslateConfigService) {
    _translateConfigService.language.subscribe(
      (lang) => (this._currentLanguage = lang)
    );
  }

  public transform(localizedText: LocalizedText) {
    if (!localizedText) {
      return '';
    }

    return localizedText[this._currentLanguage] ?? localizedText.en;
  }
}
