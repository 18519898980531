import { FormClassNameEnum } from './../../../enums/form-class-name.enum';
import { Component, Input, OnChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnChanges {
  // @Input() form: FormGroup = null;
  @Input() errors: ValidationErrors;
  @Input() className: FormClassNameEnum = null;
  getErrors: string[];
  getServerErrors: string[];
  // @Input() fieldType: FormFieldTypeEnum = null;
  // @Input() placeHolder: string = null;
  // @Input() controlName: string = null;

  ngOnChanges() {
    if (this.errors) {
      this.getErrors = [];
      Object.entries(this.errors).forEach((error) => {
        this.getErrors.push(error[0]);
      });
    }
  }
}
