import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  Validator,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';

const RATING_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RatingBarComponent),
  multi: true,
};

const RATING_VALUE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RatingBarComponent),
  multi: true,
};

@Component({
  selector: 'app-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.scss'],
  providers: [RATING_VALUE_ACCESSOR, RATING_VALUE_VALIDATOR],
})
export class RatingBarComponent
  implements ControlValueAccessor, Validator, OnChanges
{
  @Input() max = 5;
  @Input() stars = 0;
  @Input() required = false;
  @Output()
  starsChange = new EventEmitter<number>();
  disabled: boolean;

  get starsArray(): number[] {
    return [...new Array(this.max).keys()];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stars) {
      this.update(this.stars);
    }
  }

  select(index: number): void {
    this.update(index + 1);
  }

  update(newStars: number, internalChange: boolean = true): void {
    if (!this.disabled && this.stars !== newStars) {
      this.stars = newStars;
      this.starsChange.emit(this.stars);
    }
    if (internalChange) {
      this.onChange(this.stars);
      this.onTouched();
    }
  }

  writeValue(value: number): void {
    this.update(value, false);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(c: UntypedFormControl): { required: boolean } | null {
    return this.required && !c.value ? { required: true } : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  onChange(_: any): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched(): void {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }
}
