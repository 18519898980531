@if ((isAiChat$ | async) === true) {
  <app-ai-rating-counter></app-ai-rating-counter>
}

<footer
  *ngIf="
    (chatState$ | async) !== 'offline' && (chatState$ | async) !== 'finished'
  "
>
  <app-new-photo-notification></app-new-photo-notification>
  @for (answer of answers$ | async; track answer.id; let index = $index) {
    @if (answer.type === "input") {
      <form
        (submit)="submitInput(answer, $event)"
        class="answer-message-button"
      >
        @if (!answer.isNicknameInput) {
          <span class="characters-count">
            {{ inputField.length }}/{{ maxMessageLength }}
          </span>
        }

        <div class="answer-content">
          <div class="textarea-wrapper">
            <div class="size-helper">{{ inputField + " " }}</div>
            <textarea
              [(ngModel)]="inputField"
              rows="1"
              name="input"
              [maxlength]="maxMessageLength"
              (keydown.enter)="submitInput(answer, $event)"
            ></textarea>

            <div class="placeholder" [class.hidden]="inputField.length">
              @if (answer.isNicknameInput) {
                {{ "CHATS_PAGE.WRITE_YOUR_NICKNAME" | translate }}
              } @else {
                Write a message
              }
              <img src="/assets/img/chat-page/input-icon.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="vertical-line"></div>
        <button type="submit" class="answer-send-area btn-with-cost">
          @if (answer.costInGems) {
            <div class="message-cost">
              <img src="/assets/img/icon/tokens.png" alt="" />
              {{ answer.costInGems }}
            </div>
          }
          <img
            src="/assets/img/chat-page/send-btn.svg"
            alt=""
            class="send-icon"
          />
        </button>
      </form>
    } @else {
      <div
        (click)="sendAnswer(answer)"
        class="answer-message-button predefined-answer"
      >
        <div class="answer-content">
          <span
            [innerHTML]="
              (answer.localizedMessageText | parseEmoji) ||
              (answer.messageText | parseEmoji)
            "
            class="message-text"
          ></span>
        </div>

        <div class="vertical-line"></div>

        <div class="answer-send-area">
          @if (answer.costInGems) {
            <app-button class="price-btn">
              <div class="btn-content">
                <img src="/assets/img/icon/tokens.png" alt="" />
                {{ answer.costInGems }}
              </div>
            </app-button>
          } @else {
            <img
              src="/assets/img/chat-page/send-btn.svg"
              alt=""
              class="send-icon"
            />
          }
        </div>
      </div>
    }
  } @empty {
    <div (click)="clickEmpty()" class="answer-message-button empty-message">
      <div class="answer-content"></div>
      <div class="vertical-line"></div>
      <div class="answer-send-area">
        <img
          src="/assets/img/chat-page/send-btn.svg"
          alt=""
          class="send-icon"
        />
      </div>
    </div>
  }
</footer>
