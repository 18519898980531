import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthResponse } from '../../types/auth-response.interface';
import { DiscordRequestData } from '../../types/discord-request-data.interface';
import { TwitterSignInRequest } from '../../types/twitter-signIn-request.interface';
import { UserMyResponse } from '../../types/user-my-response.interface';
import { ResetPasswordRequestData } from '../../types/ResetPasswordRequestData';
import { SignInRequestData } from '../../types/sign-in-request-data.interface';
import { SignUpRequestData } from '../../types/sign-up-request-data.interface';
import { SignWithGoogleRequestData } from '../../types/sign-with-google-request-data.interface';
import { AuthPayloadData } from '../../types/auth-payload-data.interface';
declare const windowRawHref;

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private _host: string = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  public signWithErogames(
    token: string,
    whiteLabel: string,
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/user/erogames-signin`;
    return this._http
      .post<AuthResponse>(requestUrl, { token, whiteLabel })
      .pipe(
        take(1),
        catchError((error) => {
          throw error;
        }),
      );
  }

  public signWithNutakuSp(): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/user/nutaku-sp-signin`;
    console.log('Signin with nutaku. windowRawHref: ', windowRawHref);
    return this._http
      .get<AuthResponse>(requestUrl, {
        params: {
          // eslint-disable-next-line max-len
          url: windowRawHref,
        },
      })
      .pipe(
        take(1),
        catchError((error) => {
          throw error;
        }),
      );
  }

  public signWithNutakuApkTry(): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/user/nutaku-try-signin`;
    return this._http.get<AuthResponse>(requestUrl).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signWithNutakuApk(
    data: SignInRequestData & { payload: AuthPayloadData },
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/user/nutaku-by-creads-signin`;
    return this._http.post<AuthResponse>(requestUrl, data).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signIn(
    data: SignInRequestData & { payload: AuthPayloadData },
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/auth/signin`;
    return this._http.post<AuthResponse>(requestUrl, data).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signUp(
    data: SignUpRequestData & { payload: AuthPayloadData },
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/auth/signup`;
    return this._http.post<AuthResponse>(requestUrl, data).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signUpWithGoogle(
    data: SignWithGoogleRequestData & { payload: AuthPayloadData },
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/auth/user/google-signin`;
    return this._http.post<AuthResponse>(requestUrl, data).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signUpWithApple(
    // data: SignWithGoogleRequestData & { payload: AuthPayloadData }
    data: any,
  ): Observable<AuthResponse> {
    // const requestUrl = `${this._host}/api/auth/user/google-signin`;
    const requestUrl = `${this._host}/api/auth/user/apple-signin`;

    return this._http.post<AuthResponse>(requestUrl, data).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signUpWithDiscord(
    data: DiscordRequestData & { payload: AuthPayloadData },
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/user/discord-signin`;
    return this._http.post<AuthResponse>(requestUrl, data).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public getTwitterAuthToken(): Observable<string> {
    const requestUrl = `${this._host}/api/Auth/twitter-auth-token?callbackUrl=${window.location.origin}`;
    return this._http.get<{ oauthToken: string }>(requestUrl, {}).pipe(
      take(1),
      map((response) => response.oauthToken),
    );
  }

  public signWithTwitter(
    data: TwitterSignInRequest & { payload: AuthPayloadData },
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/twitter-signin`;
    return this._http.post<AuthResponse>(requestUrl, data).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public trySignupAsGuest(payload: AuthPayloadData): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/try-signup-as-guest`;
    return this._http.post<AuthResponse>(requestUrl, payload).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signBySwitchTokenApk(
    payload: AuthPayloadData,
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/signup-by-switch-token-apk`;
    return this._http.post<AuthResponse>(requestUrl, payload).pipe(
      take(1),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public signByPayment(
    payload: AuthPayloadData,
    paymentId: string,
  ): Observable<AuthResponse> {
    const requestUrl = `${this._host}/api/Auth/signup-by-payment`;
    return this._http
      .post<AuthResponse>(requestUrl, { ...payload, paymentId })
      .pipe(
        take(1),
        catchError((error) => {
          throw error;
        }),
      );
  }

  public getUser(): Observable<UserMyResponse> {
    const requestUrl = `${this._host}/api/User/my`;
    return this._http.get<UserMyResponse>(requestUrl).pipe(take(1));
  }

  public forgotPassword(email: string): Observable<boolean> {
    const requestUrl = `${this._host}/api/Auth/forgot-password`;
    return this._http.post(requestUrl, { email }).pipe(
      take(1),
      map(() => true),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public resetPassword(data: ResetPasswordRequestData): Observable<boolean> {
    const requestUrl = `${this._host}/api/Auth/reset-password`;
    return this._http.post(requestUrl, { ...data }).pipe(
      take(1),
      map(() => true),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public resendEmail(): Observable<boolean> {
    const requestUrl = `${this._host}/api/Auth/resend-email`;
    return this._http.post(requestUrl, {}).pipe(
      take(1),
      map(() => true),
      catchError((error) => {
        throw error;
      }),
    );
  }

  public wipeOut(): Observable<any> {
    const requestUrl = `${this._host}/api/Auth/user/delete`;
    return this._http.delete<any>(requestUrl);
  }
}
