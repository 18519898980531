import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService } from './analytics/analytics.service';

export enum SplitTestCase {
  onlyChats = 'TinderVsChatList|OnlyChatList',
  gamifyAi = 'gamify_ai_2|yes',
}

@Injectable({
  providedIn: 'root',
})
export class SplitTestCasesService {
  public splitTestInitialized = new BehaviorSubject(false);

  public splitTests$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  private _splitTestCases: SplitTestCase[] = [];

  constructor(private _analyticsService: AnalyticsService) {}

  public init(splitTests: string[]) {
    if (!splitTests) {
      return;
    }
    this.splitTests$.next(splitTests);

    this._analyticsService.setSplitTests(splitTests);

    splitTests.forEach((testCaseRaw) => {
      this._splitTestCases.push(testCaseRaw as SplitTestCase);
    });

    this.splitTestInitialized.next(true);
  }

  public hasCase(testCase: SplitTestCase) {
    return !!this._splitTestCases.find((_testCase) => _testCase === testCase);
  }
}
