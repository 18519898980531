<ng-container
  *ngIf="
    environment.buildVersion === buildVersion.default ||
    environment.buildVersion === buildVersion.legal
  "
>
  <ng-container *ngIf="period === 'year'">
    After the 365 days this subscription automatically renews for
    <app-premium-button-text
      [offerData]="offerData"
      fullTemplate="{nextCost} per {period}"
      shortTemplate="{cost} per {period}"
    ></app-premium-button-text>
    unless it is cancelled at least 24 hours before the end of subscription
    period
  </ng-container>

  <ng-container *ngIf="period !== 'year'">
    After the first
    <app-premium-button-text
      [offerData]="offerData"
      fullTemplate="{period} for {cost}"
      shortTemplate="{period} for {cost}"
    ></app-premium-button-text>
    this subscription automatically renews for
    <app-premium-button-text
      [offerData]="offerData"
      fullTemplate="{nextCost} per {period}"
      shortTemplate="{cost} per {period}"
    ></app-premium-button-text>
    unless it is cancelled at least 24 hours before the end of the month.
  </ng-container>
</ng-container>

<!-- <ng-container *ngIf="environment.buildVersion === buildVersion.default">
  After the 3 days free trial this subscription automatically renews for&nbsp;
  <app-price-block [purchase]="offerData.purchase"></app-price-block
  >&nbsp;per&nbsp;month unless it is cancelled at least 24 hours before the end
  of the trial period.
</ng-container>

<ng-container *ngIf="environment.buildVersion === buildVersion.legal">
  <ng-container *ngIf="phasesLength === 1">
    This subscription automatically renews for&nbsp;
    <app-price-block [purchase]="offerData.purchase"></app-price-block>
    unless it is cancelled at least 24 hours before the end of the trial period.
  </ng-container>

  <ng-container *ngIf="phasesLength === 2">
    After the
    <app-price-block [purchase]="offerData.purchase"></app-price-block> trial
    this subscription automatically renews for&nbsp;
    <app-price-block [purchase]="offerData.purchase"></app-price-block>
    unless it is cancelled at least 24 hours before the end of the trial period.
  </ng-container>
</ng-container> -->

<!-- <ng-container
  *ngIf="
    environment.buildVersion === buildVersion.default ||
    environment.buildVersion === buildVersion.legal
  "
>
  After the trial period this subscription automatically renews for a given cost
  per period unless it is cancelled at least 24 hours before the end of the
  trial period.
</ng-container> -->
