import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { SubscriptionStatus } from 'src/app/shared/types/user-my-response.interface';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, take, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-premium-unsubscribe-modal',
  templateUrl: './premium-unsubscribe-modal.component.html',
  styleUrls: ['./premium-unsubscribe-modal.component.scss'],
})
export class PremiumUnsubscribeModalComponent implements OnInit, OnDestroy {
  @Input() public offerData: OfferData;

  public subscriptionStatus: SubscriptionStatus;
  public subscriptionStatuses = SubscriptionStatus;
  public environment = environment;
  public isUnsubscribeSuccess = false;
  public pending = false;

  private _subscribers: Subscription[] = [];

  constructor(
    private _settingsDataService: SettingsDataService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _modalController: ModalController,
    private _http: HttpClient,
  ) {}

  public ngOnInit(): void {
    this._subscribers.push(
      this._settingsDataService.updateSettingsData$.subscribe((res) => {
        this.subscriptionStatus = res.subscriptionStatus;
      }),
    );
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }

  public unsubscribe(): void {
    this.pending = true;

    const requestUrl = `${this.environment.apiUrl}/api/Shop/cancel-subscription`;
    this._http
      .post(requestUrl, {})
      .pipe(
        take(1),
        map(() => {
          this.pending = false;
          this.isUnsubscribeSuccess = true;
        }),
        catchError((error) => {
          this.pending = false;
          throw error;
        }),
      )
      .subscribe();
  }

  public close(action: boolean = false): void {
    this._modalController.dismiss(action);
  }
}
