<ion-content *ngIf="data" class="custom-scrollbar">
  <div *ngIf="data.avatarLink" class="avatar">
    <img [attr.src]="data.avatarLink" alt="" />
  </div>
  <h2 class="name">
    {{ data.name }} <img src="/assets/img/chat-page/verify.svg" alt="" />
  </h2>
  <p *ngIf="data.distance || data.occupation" class="girl-info text-gray">
    <img src="/assets/img/chat-page/location.svg" alt="" />
    {{ data.distance }} | {{ data.occupation }}
  </p>

  <div *ngIf="!isAi" class="rating-block">
    <div>
      <div class="rating-value">
        <img src="/assets/img/star.svg" /> {{ rating?.girl | number: "1.0-1" }}
      </div>
      <div class="text-gray main-information-description-10">
        Profile page rating
      </div>
    </div>
    <div>
      <div class="rating-value">
        <img src="/assets/img/star.svg" />
        {{ rating?.story | number: "1.0-1" }}
      </div>
      <div class="text-gray main-information-description-10">
        Story’s rating
      </div>
    </div>
  </div>

  <div *ngIf="data.description || data.links?.length" class="about-block">
    <ng-container *ngIf="data.description">
      <p class="label">About me:</p>
      <p class="description text-gray" [innerHTML]="data.description"></p>
    </ng-container>
    <ng-container *ngIf="data.links?.length">
      <p class="label">Follow me:</p>
      <div class="links-block">
        <ng-container *ngFor="let link of data.links">
          <a
            *ngIf="link.linkType"
            class="promo-link"
            [class.onlyfans]="link.linkType === promoBrand.onlyfans"
            appEnterTheViewPort
            [showPromoOptions]="
              getShowLinkAnalyticOptions(link.linkType, 'Link in bio')
            "
            (click)="clickOnLinkInBio(link.linkType, 'Link in bio')"
            target="_blank"
            [href]="link.link | addUtmContentAsUtmSource"
          >
            <img
              [src]="
                '/assets/img/promo-platforms/' +
                link.linkType?.toLowerCase() +
                '.svg'
              "
            />
          </a>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="environment.showOnlineWebcamNotification && !isAi"
    class="webcam-notification-block"
  >
    <div>
      <p class="title main-information-description-12">
        {{ "PROFILE_PAGE.WEBCAM_NOTIFICATIONS" | translate }}
      </p>
      <p class="description main-information-description-10 text-gray">
        {{
          "PROFILE_PAGE.NOTIFICATIONS_DESCRIPTION"
            | translate: { name: this.data?.name }
        }}
      </p>
    </div>

    <ion-toggle
      legacy
      [checked]="switchNotificationsOn"
      [disabled]="false"
      (ionChange)="onlineStreamNotifications()"
    ></ion-toggle>
  </div>

  <app-profile-gallery
    *ngIf="profileGalerryData"
    [data]="profileGalerryData"
  ></app-profile-gallery>

  <app-banner-slider
    *ngIf="data.chatId !== '38c62168-f1c9-467c-bd79-c7008870dcad'"
    [bannerAnalyticOptions]="
      getShowLinkAnalyticOptions(promoBrand.bannerSlider, 'End of page')
    "
  ></app-banner-slider>

  <app-discord-link
    appEnterTheViewPort
    [showPromoOptions]="
      getShowLinkAnalyticOptions(promoBrand.discord, 'End of page')
    "
  ></app-discord-link>
</ion-content>
