<div class="bg">
  <img [src]="avatar" alt="" />
</div>

<div class="trial-modal-content">
  <div class="close-btn" (click)="closeModal(false)">
    <img src="/assets/img/bonus-page/modal/back-btn.svg" alt="" /> Back
  </div>

  <h1 class="title">The trial period<br />with {{ chatName }} is over.</h1>

  <p class="description">Get Premium access to all the features:</p>

  <ul class="features">
    <li>Unlimited swipes with your <span>AI lover</span></li>
    <li>+300 of unique <span>AI photos</span></li>
    <li>+30 AI generated <span>romantic stories</span></li>
  </ul>

  <button
    class="btn btn-gradient-green subscription-btn"
    (click)="onClickSubscribe()"
  >
    <span class="btn-title"> Subscribe </span>
    <app-premium-button-text
      *ngIf="premiumOfferData"
      [offerData]="premiumOfferData"
      class="price"
    >
    </app-premium-button-text>
  </button>

  <div class="separator">or</div>

  <button class="purchase-chat-btn" (click)="onClickBuy()" [disabled]="loading">
    <img
      src="/assets/img/quests-page/icons/email.png"
      alt=""
      class="mail-icon"
    />

    <div class="text-block">
      <h5>Purchase a chat</h5>
      <p class="text-gray">to continue communication:</p>
    </div>

    <div class="btn btn-gradient-green price-text-size-14">
      <app-price-block [purchase]="trialData"></app-price-block>
    </div>
  </button>
</div>
