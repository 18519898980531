import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval, startWith, takeWhile } from 'rxjs';

@Component({
  selector: 'app-timer',
  template: '{{ timerString }}',
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() public initialTime: number;
  public timerString = '00:00:00';

  private _timerSub: Subscription;

  public ngOnInit(): void {
    this._timerSub = interval(1000)
      .pipe(
        startWith(0),
        takeWhile(() => !!this.initialTime),
      )
      .subscribe(() => {
        this.initialTime--;
        const seconds = Math.floor(this.initialTime % 60);
        const minutes = Math.floor((this.initialTime / 60) % 60);
        const hours = Math.floor((this.initialTime / (60 * 60)) % 24);

        this.timerString = `${hours > 9 ? hours : `0${hours}`}:${
          minutes > 9 ? minutes : `0${minutes}`
        }:${seconds > 9 ? seconds : `0${seconds}`}`;
      });
  }

  public ngOnDestroy(): void {
    this._timerSub?.unsubscribe();
  }
}
