import { AiRatingCounterComponent } from '../../views/chat/ai-rating-counter/ai-rating-counter.component';
import { CircleDangerLevelModule } from './../circle-danger-level/circle-danger-level.module';
import { ChatLevelUpPopupModule } from '../chat-levelup-popup/chat-levelup-popup.module';
import { AnimatedCounterModule } from '../animated-counter/animated-counter.module';
import { ChatRatingBarModule } from './../chat-rating-bar/chat-rating-bar.module';
import { PremiumModalModule } from '../premium/modal/premium-modal.module';
import { MoneyCounterModule } from './money-counter/money-counter.module';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    AiRatingCounterComponent,
    CircleDangerLevelModule,
    ChatLevelUpPopupModule,
    ChatLevelUpPopupModule,
    AnimatedCounterModule,
    ChatRatingBarModule,
    PremiumModalModule,
    PremiumModalModule,
    MoneyCounterModule,
    TranslateModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    IonicModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
