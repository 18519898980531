import { GetHostnamePipe } from './getHostname.pipe';
import { SafePipe } from './safe.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUtmContentAsUtmSourcePipe } from './add-utm-content-as-utm-source.pipe';
import { RemoveSeconds } from './removeSeconds';
import { GetStorePricePipe } from './get-store-price';
import { LocalizePipe } from './localize.pipe';
import { SoftPurchaseCostPipe } from './softPurchaseCost.pipe';
import { ParseEmojiPipe } from './parse-emoji.pipe';

@NgModule({
  declarations: [
    SafePipe,
    GetHostnamePipe,
    AddUtmContentAsUtmSourcePipe,
    RemoveSeconds,
    GetStorePricePipe,
    LocalizePipe,
    SoftPurchaseCostPipe,
    ParseEmojiPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafePipe,
    GetHostnamePipe,
    AddUtmContentAsUtmSourcePipe,
    RemoveSeconds,
    GetStorePricePipe,
    LocalizePipe,
    SoftPurchaseCostPipe,
    ParseEmojiPipe,
  ],
})
export class PipesModule {}
