import { BuildVersion } from 'src/environments/environment-model.interface';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-premium-description-text',
  templateUrl: './premium-description-text.component.html',
  styles: [':host{display: block; text-align: center}'],
})
export class PremiumDescriptionTextComponent implements OnChanges {
  @Input() public offerData: OfferData;

  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;

  public period: 'week' | 'month' | 'year';

  public ngOnChanges(changes: SimpleChanges): void {
    switch (this.offerData.type) {
      case OfferType.weeklySubscription:
        this.period = 'week';
        break;
      case OfferType.monthlySubscription:
        this.period = 'month';
        break;
      case OfferType.yearlySubscription:
        this.period = 'year';
        break;
    }
  }
}
