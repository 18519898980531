import { EmojiComponent } from './emoji.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [EmojiComponent],
  imports: [CommonModule],
  exports: [EmojiComponent],
})
export class EmojiModule {}
