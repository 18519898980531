<p class="photos-label">
  Photos {{ data.contentArray.length + "/" + data.maxContentCount }}
</p>

<div class="content-grid">
  <div
    *ngFor="let el of images; let i = index"
    (click)="openPreview(i)"
    class="album-el"
  >
    <img [src]="el.url" alt="" class="album-photo" />

    <div *ngIf="el.isNew" class="new-label">New</div>
  </div>

  <div
    *ngIf="data.contentArray.length < data.maxContentCount"
    class="album-el locked"
  >
    <img
      src="/assets/img/profile-page/album/lock.png"
      alt=""
      class="locked-icon"
    />
    <p>Keep sexting<br />to see more pics</p>
  </div>
</div>
