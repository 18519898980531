import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { SettingsService } from 'src/app/shared/services/API_services/settings.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-enter-nickname-modal',
  templateUrl: './enter-nickname-modal.component.html',
  styleUrls: ['./enter-nickname-modal.component.scss'],
})
export class EnterNicknameModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() placeWhereItShows = '';
  loading = false;
  @ViewChild('searchElement') searchElement: ElementRef;
  public nickName = '';
  private _nicknameSub: Subscription = null;
  constructor(
    private _settingsService: SettingsService,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
    private _modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this._analyticsService.nicknameModalAppeared(this.placeWhereItShows);

    switch (environment.buildVersion) {
      case BuildVersion.erogames:
      case BuildVersion.nutaku:
        this._nicknameSub = this._settingsDataService.updateSettingsData$
          .pipe(
            filter((res) => !!res.nickName),
            take(1),
          )
          .subscribe((res) => {
            this.nickName = res.nickName;
            this._settingsDataService.changeData('nickName', res.nickName);
          });
        break;
      default:
        this.nickName = 'Guest';
        this._settingsDataService.changeData('nickName', 'Guest');
        break;
    }
  }

  ngAfterViewInit() {
    this.searchElement?.nativeElement.focus();
  }

  saveName() {
    this.loading = true;
    this._settingsService
      .updateNickname(this.nickName)
      .pipe(take(1))
      .subscribe(
        () => {
          this._settingsDataService.changeData('nickName', this.nickName);
          this._settingsDataService.nicknameIsSet = true;
          this._analyticsService.nicknameIsSet(
            this.nickName,
            this.placeWhereItShows,
            true,
          );
          this.loading = false;
          this._modalCtrl.dismiss();
        },
        () => {
          this.loading = false;
        },
      );
  }

  ngOnDestroy() {
    this._nicknameSub?.unsubscribe();
  }
}
