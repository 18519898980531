import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';

@Injectable({
  providedIn: 'root',
})
export class PaymentsStateService {
  private _paymentState: BehaviorSubject<PaymentAnimationStates> =
    new BehaviorSubject(null);
  private _isPurchasedAction: BehaviorSubject<'chatAll' | 'adsdNewTypesHere'> =
    new BehaviorSubject(null);

  set paymentState(state: PaymentAnimationStates) {
    this._paymentState.next(state);
  }

  get paymentState$(): Observable<PaymentAnimationStates> {
    return this._paymentState.asObservable();
  }

  get isPurchasedAction$(): Observable<'chatAll' | 'adsdNewTypesHere'> {
    return this._isPurchasedAction.asObservable();
  }

  isPurchased(action: 'chatAll' | 'adsdNewTypesHere') {
    this._isPurchasedAction.next(action);
  }
}
