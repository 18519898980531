import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-premium-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class PremiumSuccessModalComponent {
  constructor(private _modalController: ModalController) {}

  public close(): void {
    this._modalController.dismiss();
  }
}
