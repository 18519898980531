import { MiscAlertsModule } from './shared/components/misc-alerts/misc-alerts.module';
import { PaymentsModule } from './shared/components/payments/payments.module';
import { ProfileModule } from './pages/profile/profile.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FooterModule } from './shared/components/footer/footer.module';
import { HeaderModule } from './shared/components/header/header.module';
import { HttpConfigInterceptor } from './shared/interceptors/httpConfig.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationModule } from './shared/components/registration/registration.module';
import { RouteReuseStrategy, Router } from '@angular/router';
import { LoaderModule } from './shared/components/loader/loader.module';
import { ReactiveFormsModule } from '@angular/forms';
// import { LoggingService, LoggingServiceModule } from 'ionic-logging-service';
// import {
//   SocialLoginModule,
//   SocialAuthServiceConfig,
//   GoogleLoginProvider
// } from '@abacritt/angularx-social-login';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
// import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import * as Sentry from '@sentry/angular-ivy';
import { ToastrModule } from 'ngx-toastr';

import {
  TranslateModule,
  TranslateLoader,
  TranslateCompiler,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { LanguageSelectorModalModule } from './shared/components/language-selector-modal/language-selector-modal.module';
import { AdmobLoaderModule } from './shared/components/admob-loader/admob-loader.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
// import { Appmetrica } from 'capacitor-appmetrica';
import { TutorialModule } from './shared/components/tutorial/tutorial.module';
import { DailyRewardsModalModule } from './shared/components/daily-rewards-modal/daily-rewards-modal.module';
import { OffersModalModule } from './shared/components/offers-modal/offers-modal.module';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { GenderSelectModalModule } from './shared/components/gender-select-modal/gender-select-modal.module';
import { CheatMenuModule } from './shared/components/cheat-menu/cheat-menu.module';
import { PremiumSuccessModalModule } from './shared/components/premium/success-modal/success-modal.module';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function playerFactory() {
  return player;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RegistrationModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FooterModule,
    HeaderModule,
    IonicModule.forRoot(),
    LoaderModule,
    ReactiveFormsModule,
    // SocialLoginModule,
    ProfileModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    MiscAlertsModule,
    OffersModalModule,
    GenderSelectModalModule,
    CheatMenuModule,
    PremiumSuccessModalModule,
    // NgxMetrikaModule.forRoot({
    //   id: 86176143,
    //   defer: true,
    //   webvisor: false,
    //   clickmap: false,
    //   trackLinks: true,
    //   accurateTrackBounce: true
    // }),
    LottieModule.forRoot({ player: playerFactory }),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    PaymentsModule,
    AdmobLoaderModule,
    ToastrModule.forRoot(),
    // LoggingServiceModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    TranslateModule,
    AngularSvgIconModule.forRoot(),
    TutorialModule,
    DailyRewardsModalModule,
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    // {
    //   provide: InAppBrowser,
    //   useClass: InAppBrowser,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    // {
    //   deps: [LoggingService],
    //   multi: true,
    //   provide: APP_INITIALIZER,
    //   useFactory: (loggingService: LoggingService) => () => {
    //     loggingService.configure(environment.logging);
    //   },
    // },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '262025504103-9tiutjq0hvf97p39g8f9qjfhsjnajfve.apps.googleusercontent.com'
    //           //'422350541625-cad89bssaqtb2eda36sqm6fqaj3b1u26.apps.googleusercontent.com'
    //         ),
    //       },
    //     ],
    //   } as SocialAuthServiceConfig,
    // },
    GooglePlus,
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    // Appmetrica,
    ScreenOrientation,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
