import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { Observable, take, map, BehaviorSubject, of, catchError } from 'rxjs';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { ToastsService } from 'src/app/shared/services/toasts.service';
import { ChatStateService } from '../chat-state.service';
import { UseGiftResponse } from './use-gift.interface';
import { TranslateService } from '@ngx-translate/core';
import { NavHelper } from 'src/app/shared/helpers';
import { ChatGift } from './chat-gift.interface';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChatGiftsService {
  public readonly gifts$ = new BehaviorSubject<ChatGift[]>([]);

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _chatStateService: ChatStateService,
    private _inventoryService: InventoryService,
    private _translateService: TranslateService,
    private _analyticsService: AnalyticsService,
    private _toastsService: ToastsService,
    private _navHelper: NavHelper,
  ) {}

  public fetchGifts(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<ChatGift[]>(WebsocketCommandType.getMaxRatingGifts)
      .pipe(
        take(1),
        map((res) => {
          this.gifts$.next(res);
          return true;
        }),
      );
  }

  sendGift(gift: ChatGift) {
    if (gift.cost > this._inventoryService.gems) {
      this._navHelper.goToShopGems();
      this._toastsService.showError(
        this._translateService.instant('SHOP_PAGE.NOT_ENOUGH_TOKENS'),
      );

      return of(null);
    }

    return this._websocketSignalRService
      .invoke<UseGiftResponse>(WebsocketCommandType.useMaxRatingGiftForChat, {
        chatId: this._chatStateService.chatId,
        giftId: gift.id,
      })
      .pipe(
        map((res) => {
          this._analyticsService.giftBuy({
            chatId: this._chatStateService.chatId,
            giftName: gift.name,
          });

          this._chatStateService.aiChatRating$.next({
            ...this._chatStateService.aiChatRating$.value,
            rating: res.rating,
            ratingCap: res.ratingCap,
          });
          this._inventoryService.setGems(res.gems);
          return true;
        }),
        catchError((error) => {
          switch (error.error.status) {
            case 'notEnoughCoins':
              this._navHelper.goToShopGems();
              this._toastsService.showError(
                this._translateService.instant('SHOP_PAGE.NOT_ENOUGH_TOKENS'),
              );
              break;
          }
          console.error(error);
          return of(null);
        }),
      );
  }
}
