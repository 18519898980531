import { SummerKickoffSmallComponent } from './templates/summer-kickoff-small/summer-kickoff-small.component';
import { DominanceTwoChatsComponent } from './templates/dominance-two-chats/dominance-two-chats.component';
import { SummerKickoffBigComponent } from './templates/summer-kickoff-big/summer-kickoff-big.component';
import { PremiumButtonTextModule } from '../premium/button-text/premium-button-text.module';
import { DominanceBigComponent } from './templates/dominance-big/dominance-big.component';
import { StarterPackComponent } from './templates/starter-pack/starter-pack.component';
import { ThreeChatsComponent } from './templates/three-chats/three-chats.component';
import { OfferItemsListModule } from '../offer-items-list/offer-items-list.module';
import { FiveChatsComponent } from './templates/five-chats/five-chats.component';
import { OfferTemplatesModule } from '../offer-tempaltes/offer-templates.module';
import { PremiumOfferComponent } from './templates/premium/premium.component';
import { BigPackComponent } from './templates/big-pack/big-pack.component';
import { PriceBlockModule } from '../price-block/price-block.module';
import { ButtonModule } from '../controls/button/button.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { OffersModalComponent } from './offers-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { TimerModule } from '../timer/timer.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    SummerKickoffSmallComponent,
    DominanceTwoChatsComponent,
    SummerKickoffBigComponent,
    PremiumOfferComponent,
    DominanceBigComponent,
    OffersModalComponent,
    StarterPackComponent,
    ThreeChatsComponent,
    FiveChatsComponent,
    BigPackComponent,
  ],
  imports: [
    PremiumButtonTextModule,
    OfferItemsListModule,
    OfferTemplatesModule,
    PriceBlockModule,
    TranslateModule,
    ButtonModule,
    CommonModule,
    TimerModule,
    PipesModule,
  ],
  exports: [],
})
export class OffersModalModule {}
