import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedText } from '../types/localized-text.interface';
import { MessageParser } from '../helpers/messageParser';

@Pipe({
  name: 'parseEmoji',
})
export class ParseEmojiPipe implements PipeTransform {
  constructor(private _messageParser: MessageParser) {}

  public transform(text: string | LocalizedText) {
    return this._messageParser.parseV2(text);
  }
}
