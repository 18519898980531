import { CommonNotificationModalComponent } from './common-notification-modal/common-notification-modal.component';
import { RewardsReceivedModalComponent } from './rewards-received-modal/rewards-received-modal.component';
import { UpdateVersionModalComponent } from './update-version-modal/update-version-modal.component';
import { EnterNicknameModalComponent } from './enter-nickname-modal/enter-nickname-modal.component';
import { TrialPurchaseModalComponent } from './trial-purchase-modal/trial-purchase-modal.component';
import { NutakuLoginModalComponent } from './nutaku-login-modal/nutaku-login-modal.component';
import { ChatDetailsModalComponent } from './chat-details-modal/chat-details-modal.component';
import { MaintenanceModalComponent } from './maintenance-modal/maintenance-modal.component';
import { PremiumButtonTextModule } from '../premium/button-text/premium-button-text.module';
import { PremiumBlockModule } from '../premium/premium-block/premium-block.module';
import { MatchModalComponent } from './match-modal/match-modal.component';
import { PriceBlockModule } from '../price-block/price-block.module';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LottieModule } from 'ngx-lottie';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../controls/button/button.module';

@NgModule({
  declarations: [
    CommonNotificationModalComponent,
    RewardsReceivedModalComponent,
    UpdateVersionModalComponent,
    EnterNicknameModalComponent,
    TrialPurchaseModalComponent,
    ChatDetailsModalComponent,
    MaintenanceModalComponent,
    NutakuLoginModalComponent,
    MatchModalComponent,
  ],
  imports: [
    PremiumButtonTextModule,
    PremiumBlockModule,
    PriceBlockModule,
    TranslateModule,
    CommonModule,
    LottieModule,
    ButtonModule,
    FormsModule,
    IonicModule,
    PipesModule,
  ],
  exports: [
    CommonNotificationModalComponent,
    RewardsReceivedModalComponent,
    UpdateVersionModalComponent,
    EnterNicknameModalComponent,
    TrialPurchaseModalComponent,
    ChatDetailsModalComponent,
    NutakuLoginModalComponent,
    MatchModalComponent,
  ],
})
export class MiscAlertsModule {}
