<div class="modal-wrapper">
  <div class="modal-content">
    <div class="modal-head">
      <h3 class="title">
        {{ "MODALS.PAYMENTS.CONFIRM.CONFIRMATION" | translate }}
      </h3>
    </div>

    <div>
      @if (environment.buildVersion === buildVersion.erogames) {
        <div class="confirm-text">
          {{ "MODALS.PAYMENTS.CONFIRM.BUYING_ITEM_FOR" | translate }}
          <span>
            <img src="/assets/img/erogold.svg" alt="" />
            {{ cost }}
          </span>
        </div>
      } @else if (environment.buildVersion === buildVersion.nutaku) {
        <div class="confirm-text">
          {{ "MODALS.PAYMENTS.CONFIRM.BUYING_ITEM_FOR" | translate }}
          <span>
            <img src="/assets/img/nutaku-gold-small.png" alt="" />
            {{ cost }}
          </span>
        </div>
      }

      <div class="buttons">
        <app-button (click)="cancel()">
          {{ "SHARED.CANCEL" | translate }}
        </app-button>

        <app-button type="submit" (click)="confirm()" variant="submit">
          {{ "SHARED.CONFIRM" | translate }}
        </app-button>
      </div>
    </div>
  </div>
</div>
