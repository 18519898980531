import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

// if (environment.production) {
//   Sentry.init({
//     dsn: 'https://07c3c277e13647e99ce8526602caf5d6@o4505193368584192.ingest.sentry.io/4505193409282048',
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.routingInstrumentation,
//       }),
//       new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
