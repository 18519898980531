import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenComponent } from './fullscreen.component';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from 'src/app/app.module';
import { SwiperModule } from 'swiper/angular';
import { IonicModule } from '@ionic/angular';
@NgModule({
  declarations: [FullscreenComponent],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    SwiperModule,
    IonicModule,
    TranslateModule,
  ],
  exports: [FullscreenComponent],
  providers: [],
})
export class FullscreenModule {}
