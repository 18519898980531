import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  standalone: true,
})
export class CookiesPolicyComponent {
  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;
  public readonly isIos = this._platform.is('ios');
  public readonly isAndroid = this._platform.is('android');

  constructor(private _platform: Platform) {}
}
