<div class="modal-wrapper">
  <div (click)="close()" class="close-bg"></div>
  <div class="modal-content">
    <div class="modal-head">
      <h3 class="title">
        @switch (type) {
          @case (TermsType.termsOfUse) {
            Terms of use
          }
          @case (TermsType.privacy) {
            Privacy policy
          }
          @case (TermsType.subscription) {
            Subscription options
          }
          @case (TermsType.cookies) {
            Cookies policy
          }
          @case (TermsType.usc18) {
            18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
          }
        }
      </h3>

      <button (click)="close()" class="close-btn">&times;</button>
    </div>

    <div class="text-block">
      @switch (type) {
        @case (TermsType.termsOfUse) {
          <app-terms-of-use></app-terms-of-use>
        }
        @case (TermsType.privacy) {
          <app-privacy-policy></app-privacy-policy>
        }
        @case (TermsType.subscription) {
          <app-subscription-terms></app-subscription-terms>
        }
        @case (TermsType.cookies) {
          <app-cookies-policy></app-cookies-policy>
        }
        @case (TermsType.usc18) {
          <app-18usc></app-18usc>
        }
      }
    </div>
  </div>
</div>
