import { Component, Input, OnInit } from '@angular/core';
import { ProfileGaleryData } from './profile-gallery-data.interface';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { FullscreenComponent } from 'src/app/shared/components/fullscreen/fullscreen.component';
import { ChatImagesService } from '../../../shared/views/chat/services/chat-images.service';

@Component({
  selector: 'app-profile-gallery',
  templateUrl: './profile-gallery.component.html',
  styleUrls: ['./profile-gallery.component.scss'],
})
export class ProfileGalleryComponent implements OnInit {
  @Input() public data: ProfileGaleryData;

  public images: { url: string; isNew: boolean }[] = [];

  constructor(
    private _modalsService: ModalsService,
    private _chatImagesService: ChatImagesService,
  ) {}

  public ngOnInit() {
    const newImages = this._chatImagesService.getNewImages(this.data.chatId);

    this.images = this.data.contentArray.map((img) => ({
      url: img.url,
      isNew: newImages.includes(img.url),
    }));
  }

  public openPreview(startWith: number) {
    this._modalsService
      .openModal(FullscreenComponent, {
        contentData: this.data.contentArray,
        startWith,
        isGallery: true,
      })
      .subscribe();
  }
}
