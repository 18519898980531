<button (click)="close()" class="close-btn">&times;</button>

@if (isPremiumActive$ | async) {
  <swiper #swiper [config]="swiperConfig" class="subscription-selector">
    @for (
      lot of tokenLots$ | async;
      track lot.purchase.productId;
      let index = $index
    ) {
      <ng-template swiperSlide>
        <div class="subscription tokens-mode">
          <div class="inner">
            <div class="inner-body">
              <img
                [src]="
                  '/assets/img/shop-page/tokens/tokens' + (index + 1) + '.png'
                "
                alt=""
                class="tokens-img"
              />

              <p class="tokens-count">{{ lot.items[0].count }} Tokens</p>
              <p class="messages-count">
                ({{ lot.items[0].count / messageCost | number: "1.0-0" }}
                messages)
              </p>

              <button (click)="purchaseLot(lot)" class="buy-btn">
                <app-price-block [purchase]="lot.purchase"></app-price-block>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    }
  </swiper>

  <div class="swiper-pagination tokens-mode"></div>
} @else {
  @if ((tokenLots$ | async)[1]; as lot) {
    <div class="tokens-offer">
      <div class="offer-content">
        <p class="offer-title">Tokens offer</p>
        <img src="/assets/img/icon/tokens.png" alt="" class="tokens-img" />
        <p class="tokens-count">{{ lot.items[0].count }} Tokens</p>
        <p class="messages-count">
          ({{ lot.items[0].count / messageCost | number: "1.0-0" }} messages)
        </p>

        <button (click)="purchaseLot(lot)" class="tokens-buy-btn">
          <app-price-block [purchase]="lot.purchase"></app-price-block>
        </button>
      </div>
    </div>
  }

  <swiper #swiper [config]="swiperConfig" class="subscription-selector">
    @for (subscription of subscriptions; track subscription.id) {
      <ng-template swiperSlide>
        <div class="subscription">
          <div class="inner">
            <div class="inner-head">PREMIUM</div>
            <div class="inner-body">
              <img
                src="/assets/img/icon/tokens.png"
                alt=""
                class="tokens-img"
              />

              <p class="tokens-count">
                {{ subscription.dailyRewardGemsAmount }} Tokens / DAY
              </p>
              <p class="messages-count">
                ({{
                  subscription.dailyRewardGemsAmount / messageCost
                    | number: "1.0-0"
                }}
                messages)
              </p>

              <button
                (click)="purchaseSubscription(subscription)"
                class="buy-btn"
              >
                <span class="btn-title"> Subscribe </span>
                <app-premium-button-text
                  [offerData]="subscription"
                  fullTemplate="{cost} then {nextCost} / {period}"
                  shortTemplate="1 {period} / {cost}"
                  class="price"
                ></app-premium-button-text>

                @if (subscription.items[0]?.type === "Gem") {
                  <div class="tokens-bonus">
                    <p class="pink">BONUS</p>
                    <p>{{ subscription.items[0].count }}</p>
                    <p>Tokens</p>
                    <p class="pink">NOW</p>
                  </div>
                }
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    }
  </swiper>

  <div class="swiper-pagination"></div>

  <app-premium-description-text
    [offerData]="selectedSubscription"
    class="subscription-info"
  >
  </app-premium-description-text>

  <div
    *ngIf="environment.buildVersion === buildVersion.legal"
    class="paywall-policies"
  >
    <a
      (click)="openTerms()"
      [innerHTML]="'SETTINGS_PAGE.TERMS_OF_USE' | translate"
    ></a>
    <a
      (click)="openPolicy()"
      [innerHTML]="'SETTINGS_PAGE.PRIVACY' | translate"
    ></a>
  </div>
}
