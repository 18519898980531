import { TrialPurchaseModalComponent } from 'src/app/shared/components/misc-alerts/trial-purchase-modal/trial-purchase-modal.component';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';
import { DualOfferComponent } from 'src/app/shared/components/promo/dual-offer/dual-offer.component';
import { WebsocketSignalRService } from 'src/app/shared/services/websocket-signalr.service';
import { WebsocketCommandType } from 'src/app/shared/enums/websocket-command-type.enum';
import { RealCostProperties } from 'src/app/shared/types/purchase-data.interface';
import { SplitTestVariant } from 'src/app/shared/enums/split-test-variant.enum';
import { PaywallService } from 'src/app/shared/services/paywall.service';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { ShopLot } from 'src/app/shared/types/shop-lot-new.interface';
import { switchMap, Observable, Subject, of } from 'rxjs';
import { ChatStateService } from './chat-state.service';
import { Injectable } from '@angular/core';

interface TrialData {
  subscriptionPurchase: RealCostProperties;
  trialPurchase: RealCostProperties;
}

@Injectable({ providedIn: 'root' })
export class ChatTrialService {
  public readonly trialCanceled = new Subject();

  private _trialData: TrialData;
  private _splitTestVariant: SplitTestVariant;

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _settingsDataService: SettingsDataService,
    private _chatStateService: ChatStateService,
    private _paywallService: PaywallService,
    private _modalsService: ModalsService,
  ) {}

  public setTrial(trialData: TrialData, splitTestVariant?: SplitTestVariant) {
    this._trialData = trialData;
    this._splitTestVariant = splitTestVariant;

    this.openTrialPurchase();
  }

  public openTrialPurchase() {
    if (
      this._settingsDataService.updateSettingsData$.value.config
        .paywallShowOnTrial
    ) {
      this.openSubscriptionModal();
      return;
    }

    if (this._splitTestVariant === SplitTestVariant.starterDualOffer) {
      this.openDualOffer().subscribe();
      return;
    }

    this.openTrialModal();
  }

  private openSubscriptionModal() {
    this._paywallService
      .showPaywall('trialPhoto')
      .pipe(
        switchMap((res) => {
          if (res) {
            return this.cancelTrial();
          }
          return of(false);
        }),
      )
      .subscribe();
    return;
  }

  private openTrialModal() {
    this._modalsService
      .openModal<boolean>(TrialPurchaseModalComponent, {
        chatName: this._chatStateService.modelName$.value,
        trialData: this._trialData.trialPurchase,
        subscriptionPurchaseData: this._trialData.subscriptionPurchase,
        avatar: this._chatStateService.bigCharacterAvatar,
        chatId: this._chatStateService.chatId,
        messagesCount: this._chatStateService.messages$.value.length,
      })
      .pipe(
        switchMap((res) => {
          if (res) {
            return this.cancelTrial();
          }
          return of(false);
        }),
      )
      .subscribe();
  }

  private cancelTrial(): Observable<boolean> {
    this._chatStateService.messages$.value.forEach(
      (m) => (m.hiddenInTrial = false),
    );
    this._chatStateService.setState('normal');

    this.trialCanceled.next(null);

    return of(true);
  }

  private openDualOffer(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<ShopLot[]>(WebsocketCommandType.getStarterDualOffer)
      .pipe(
        switchMap((offers) => {
          if (offers.length) {
            return this._modalsService
              .openModal(DualOfferComponent, {
                offers,
              })
              .pipe(
                switchMap((res) => {
                  if (res) {
                    return this.cancelTrial();
                  }
                  return of(false);
                }),
              );
          }

          this.openTrialModal();
          return of(false);
        }),
      );
  }

  public clear() {
    this._trialData = null;
    this._splitTestVariant = null;
  }
}
