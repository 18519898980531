import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TermsOfUseComponent } from './templates/terms-of-use/terms-of-use.component';
import { SubscriptionTermsComponent } from './templates/subscription-terms/subscription-terms.component';
import { PrivacyPolicyComponent } from './templates/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './templates/cookies-policy/cookies-policy.component';
import { USC18Component } from './templates/18usc/18usc.component';

export enum TermsType {
  termsOfUse,
  privacy,
  subscription,
  cookies,
  usc18,
}

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
  standalone: true,
  imports: [
    TermsOfUseComponent,
    SubscriptionTermsComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    USC18Component,
  ],
})
export class TermsModalComponent {
  @Input() public type: TermsType = TermsType.termsOfUse;

  public readonly TermsType = TermsType;

  constructor(private _modalController: ModalController) {}

  public close(action: boolean = false): void {
    this._modalController.dismiss(action);
  }
}
