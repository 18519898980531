import { TutorialService } from 'src/app/shared/services/communication_services/tutorial.service';
import { SettingsDataService } from '../communication_services/settingsData.service';
import { UserDataService } from '../communication_services/userData.service';
import { OpenNextMessage } from '../../types/open-next-message.interface';
import { PromoStripchatService } from '../promo-stripchat.service';
import { ChatData, ChatType } from '../../types/chat-data.interface';
import { ChatApiMethodsService } from './chat-api-methods.service';
import { PromoBrand } from '../../enums/chat-promo-brand.enum';
import { environment } from 'src/environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface NextMessagesPack {
  chatId: string;
  messages: OpenNextMessage[];
}
@Injectable({
  providedIn: 'root',
})
export class ChatsService {
  quickMessaging = false;
  private _ageAndAgreementAreSet = false;

  constructor(
    private _promoStripchatService: PromoStripchatService,
    private _chatApiMethodsService: ChatApiMethodsService,
    private _settingsDataService: SettingsDataService,
    private _userDataService: UserDataService,
    private _tutorialService: TutorialService,
  ) {
    this._settingsDataService.updateSettingsData$.subscribe((res) => {
      this.quickMessaging = res.quickMessaging;
      this._ageAndAgreementAreSet =
        res.isAgeConfirmed && res.isAgreementAccepted;
    });
  }

  public getAllChats(): Observable<boolean> {
    return this._chatApiMethodsService.getAllChats().pipe(
      switchMap((response) => {
        if (
          environment.isPromoWidgetChat &&
          this._tutorialService.currentStep$.value === null &&
          this._ageAndAgreementAreSet
        ) {
          return this._promoStripchatService.addPromoWidgeToChat(response);
        }
        return of(response);
      }),
      // switchMap(response => {
      //   if(environment.isPromoWidgetChat && !this._tutorialService.tutorialModeOn){
      //     return this._promoOnlyFansService.addPromoWidgeToChat(response);
      //   }
      //   return of(response);
      // }),
      // switchMap((response) => {
      //   if (
      //     environment.isPromoWidgetChat &&
      //     !this._tutorialService.tutorialModeOn
      //   ) {
      //     return this._promoDatingService.addPromoWidgeToChat(response);
      //   }
      //   return of(response);
      // }),
      // map((response) => {
      //   if (isEmpty) {
      //      return this.addNoMessages(response);
      //   } else {
      //     return response;
      //   }

      //   return response;
      // }),
      // map((response) => this.addDiscord(response)),
      tap((response: ChatData[]) => {
        this.saveChats(response);
      }),
      map(() => true),
    );
  }

  public addDiscord(listOfChats) {
    listOfChats.push({
      characterAvatar: null,
      bigCharacterAvatar: null,
      characterName: null,
      createdAt: '',
      id: '',
      dan: 0,
      messages: [],
      modifiedAt: '',
      packId: null,
      status: 'Open',
      users: null,
      feedbackFormShow: false,
      type: ChatType.discordLink,
      promoCompany: PromoBrand.discord,
      unbanPurchase: null,
      locale: null,
      isNew: false,
    } as ChatData);
    return listOfChats;
  }

  public addNoMessages(listOfChats) {
    listOfChats.push({
      characterAvatar: null,
      bigCharacterAvatar: null,
      characterName: null,
      createdAt: null,
      id: null,
      dan: null,
      messages: [],
      modifiedAt: null,
      packId: null,
      status: null,
      users: null,
      feedbackFormShow: false,
      type: ChatType.noMessagesBadge,
      promoCompany: null,
      unbanPurchase: null,
      locale: null,
      isNew: false,
    } as ChatData);
    return listOfChats;
  }

  private saveChats(chats: ChatData[]) {
    this._userDataService.changeChatsData(chats);
  }
}
