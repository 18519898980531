import { WebsocketSignalRService } from '../../services/websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ShopLot } from '../../types/shop-lot-new.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShopTokensService {
  public readonly lots$ = new BehaviorSubject<ShopLot[]>([]);

  constructor(private _websocketSignalRService: WebsocketSignalRService) {}

  public fetchTokens(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<ShopLot[]>(WebsocketCommandType.getGems, {})
      .pipe(
        take(1),
        map((res) => {
          this.lots$.next(res);
          return true;
        }),
      );
  }
}
