import { HalloweenModalInfoComponent } from './info/halloween-modal-info.component';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { Halloween2023Service } from '../halloween2023.service';
import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-halloween-modal',
  templateUrl: './halloween-modal.component.html',
  styleUrls: ['./halloween-modal.component.scss'],
})
export class HalloweenModalComponent {
  public readonly rewards$ = this._halloweenService.rewards$;

  constructor(
    private _halloweenService: Halloween2023Service,
    private _modalController: ModalController,
    private _modalsService: ModalsService,
  ) {}

  public openInfo() {
    this._modalsService.openModal(HalloweenModalInfoComponent).subscribe();
  }

  public close() {
    this._modalController.dismiss();
  }
}
