import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-halloween-modal-info',
  templateUrl: './halloween-modal-info.component.html',
  styleUrls: ['./halloween-modal-info.component.scss'],
})
export class HalloweenModalInfoComponent {
  constructor(private _modalController: ModalController) {}

  public close() {
    this._modalController.dismiss();
  }
}
