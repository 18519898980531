<ng-container *ngFor="let token of tokens">
  <ng-container [ngSwitch]="token.type">
    <ng-container *ngSwitchCase="'text'">
      <span [innerHTML]="token.text"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'period'">
      <span> {{ period }} </span>
    </ng-container>
    <ng-container *ngSwitchCase="'cost'">
      <span>
        {{ " " }}
        <app-price-block [purchase]="offerData.purchase"></app-price-block>
        {{ " " }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'nextCost'">
      <span>
        {{ " " }}
        <app-price-block
          [purchase]="offerData.purchase"
          [nextPrice]="true"
        ></app-price-block>
        {{ " " }}
      </span>
    </ng-container>
  </ng-container>
</ng-container>
