import { ChatStateService } from '../../services/chat-state.service';
import { NavHelper } from 'src/app/shared/helpers';
import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { SettingsDataService } from 'src/app/shared/services/communication_services/settingsData.service';

@Component({
  selector: 'app-ai-offline-modal',
  templateUrl: './ai-offline-modal.component.html',
  styleUrls: ['./ai-offline-modal.component.scss'],
})
export class AiOfflineModalComponent {
  public readonly modelName$ = this._chatStateService.modelName$;
  public isBoys = false;

  constructor(
    private _settingsDataService: SettingsDataService,
    private _chatStateService: ChatStateService,
    private _modalController: ModalController,
    private _navHelper: NavHelper,
  ) {
    this.isBoys =
      this._settingsDataService.updateSettingsData$.value.preference === 'Boy';
  }

  public goToSwipes() {
    this._navHelper.goToCards();
    this.close();
  }

  public close(): void {
    this._modalController.dismiss();
  }
}
