import { SettingsDataService } from '../../services/communication_services/settingsData.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { switchMap, take } from 'rxjs';

@Component({
  selector: 'app-download-app-modal',
  templateUrl: './download-app-modal.component.html',
  styleUrls: ['./download-app-modal.component.scss'],
})
export class DownloadAppModalComponent {
  disableButton = false;
  @Input() showedPlace: string;
  constructor(
    private _modalCtrl: ModalController,
    private _http: HttpClient,
    private _settingsDataService: SettingsDataService,
    private _analyticsService: AnalyticsService,
  ) {}

  close() {
    this._analyticsService.showDownloadApkModal(this.showedPlace, 'close');
    this._modalCtrl.dismiss();
  }

  downloadApk() {
    this._analyticsService.showDownloadApkModal(this.showedPlace, 'download');
    this.disableButton = true;
    this._settingsDataService.updateSettingsData$
      .pipe(
        switchMap((res) =>
          this._http.get<{ url: string }>(
            `https://apkbuilder.getmynudes.com/apk?uspidntfr=${res.id}`,
          ),
        ),
        take(1),
      )
      .subscribe({
        next: (res) => {
          location.href = res?.url;
          this.disableButton = false;
          this._modalCtrl.dismiss();
        },
        error: (err) => {
          this.disableButton = false;
        },
      });
  }
}
