import { Injectable } from '@angular/core';
import { LocalizedText } from '../types/localized-text.interface';
import { TranslateConfigService } from '../services/translate-config.service';
import { LocalizePipe } from '../pipes/localize.pipe';

@Injectable({
  providedIn: 'root',
})
export class MessageParser {
  private _localizePipe: LocalizePipe;

  constructor(private _translateConfigService: TranslateConfigService) {
    this._localizePipe = new LocalizePipe(_translateConfigService);
  }

  public parseSystemMessage(message: string): any {
    const str = message;

    if (!str) {
      return;
    }

    const result = str.match(/\<(.*?)\>/gim);
    let text = '';
    let id = '';
    if (result) {
      text = result[0];
      id = result[1];
      if (id) {
        return {
          text: text.replace('<', '').replace('>', ''),
          id: id.replace('<', '').replace('>', ''),
        };
      } else {
        return {
          text: text.replace('<', '').replace('>', ''),
          id: '',
        };
      }
    } else {
      return {
        text: str,
        id: '',
      };
    }
  }

  public parseEmoji(message: string, isAllChats: boolean): string | string[] {
    const str = message;

    if (!str) {
      return '';
    }

    const test = str.split(/\<(.*?)\>/gim);
    if (isAllChats) {
      let maxLenght = 40;
      let end = false;
      for (let i = 0; i < test.length; i++) {
        if (end === true) {
          test[i] = '';
        } else {
          maxLenght -= test[i].length;
          if (maxLenght < 0) {
            if (this.checkEmoji(test[i])) {
              test[i] = '';
            } else {
              test[i] = test[i].slice(0, test[i].length + maxLenght);
            }
            test[i] += '...';
            end = true;
          }
        }
      }
    }

    return test;
  }

  public getEmoji(str: string): any {
    let emoji = '';
    const a = str.replace('U+', '0x');
    try {
      emoji = String.fromCodePoint(+a);
    } catch (e) {
      // throw e;
      emoji = str;
    }

    return emoji;
  }

  public checkEmoji(str = ''): boolean {
    return str.includes('U+');
  }

  public tryParseEmoji(str: string) {
    return this.checkEmoji(str) ? this.getEmoji(str) : str;
  }

  public parseV2(text: string | LocalizedText) {
    if (typeof text !== 'string') {
      text = this._localizePipe.transform(text);
    }

    if (this.checkEmoji(text)) {
      const parsed = this.parseEmoji(text, false) as string[];

      return parsed.map((part) => this.tryParseEmoji(part)).join('');
    } else {
      return text;
    }
  }
}
