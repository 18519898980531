import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { ChatService } from 'src/app/shared/views/chat/services/chat.service';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { Component, Input, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavHelper } from 'src/app/shared/helpers';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { InventoryService } from 'src/app/shared/services/inventory.service';

@Component({
  selector: 'app-additional-content-modal',
  templateUrl: './additional-content-modal.component.html',
  styleUrls: ['./additional-content-modal.component.scss'],
})
export class AdditionalContentModalComponent implements OnDestroy {
  public pending = false;
  private subscribers: Subscription[] = [];
  private timer: ReturnType<typeof setInterval>;

  @Input() data = {
    cost: 0,
    chatId: '',
    name: '',
  };
  environment = environment;

  constructor(
    private _unsubscribeHelper: UnsubscribeHelper,
    private _analyticsService: AnalyticsService,
    private _inventoryService: InventoryService,
    private _chatService2: ChatService,
    private _modalCtrl: ModalController,
    private _navHelper: NavHelper,
  ) {}

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this._unsubscribeHelper.unsubscribe(this.subscribers);
  }

  public async buy(): Promise<void> {
    if (this._inventoryService.gems >= this.data.cost) {
      this.pending = true;

      this.subscribers.push(
        this._chatService2.buyAdditionalContent().subscribe(() => {
          this._modalCtrl.dismiss();
        }),
      );
    } else {
      this._navHelper.goToShopGems();
    }
  }

  public close() {
    this._analyticsService.buyAdditionalContent('close', this.data.name);
    this.out();
  }

  private out(): void {
    this._modalCtrl.dismiss();
  }
}
