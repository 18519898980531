import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NavHelper } from '../../helpers';
import { Subscription, timer } from 'rxjs';
import { GestureController } from '@ionic/angular';

@Component({
  selector: 'app-chat-levelup-popup',
  templateUrl: './chat-levelup-popup.component.html',
  styleUrls: ['./chat-levelup-popup.component.scss'],
})
export class ChatLevelUpPopupComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popupEl')
  public readonly popupEl: ElementRef<HTMLElement>;

  public title = '';
  public isVisible = false;
  private _touchActive = false;
  private _timerSub: Subscription = null;

  private _titles = [
    'More points!',
    'Level up!',
    'Upgrade!',
    'Wow, points!',
    'Some more points!',
  ];

  constructor(
    private _navHelper: NavHelper,
    private _gestureCtrl: GestureController,
    private _changeDetection: ChangeDetectorRef
  ) {}

  public ngAfterViewInit() {
    const gesture = this._gestureCtrl.create({
      el: this.popupEl.nativeElement,
      gestureName: 'long-press',
      direction: 'y',
      onStart: () => {
        this._touchActive = true;
        this._timerSub?.unsubscribe();
      },
      onMove: (ev) => {
        let delta = ev.currentY - ev.startY;
        if (delta > 0) {
          delta = 0;
        }
        this.popupEl.nativeElement.style.transition = '.1s ease';
        this.popupEl.nativeElement.style.transform = `translateY(${delta}px)`;
      },
      onEnd: (ev) => {
        const delta = ev.currentY - ev.startY;
        if (
          ev.velocityY < 0 ||
          this.popupEl.nativeElement.clientHeight / 2 < Math.abs(delta)
        ) {
          this.isVisible = false;
        } else {
        }

        this.startTimer();
        this.popupEl.nativeElement.style.transition = null;
        this.popupEl.nativeElement.style.transform = null;
        setTimeout(() => (this._touchActive = false));
        this._changeDetection.detectChanges();
      },
    });

    gesture.enable(true);
  }

  public goToProfile() {
    if (this._touchActive) {
      return;
    }

    this._navHelper.goToSettings();
  }

  public show() {
    this.title = this._titles[Math.floor(Math.random() * this._titles.length)];
    this.isVisible = true;
    this.startTimer();
  }

  private startTimer() {
    this._timerSub?.unsubscribe();
    this._timerSub = timer(2000).subscribe(() => {
      this.isVisible = false;
    });
  }

  public ngOnDestroy(): void {
    this._timerSub?.unsubscribe();
  }
}
