<ion-header id="header" role="heading" class="ion-no-border">
  @if (
    (state[uiStatesEnum.state] === stateEnum.chat ||
      state[uiStatesEnum.state] === stateEnum.album ||
      state[uiStatesEnum.state] === stateEnum.profile) &&
    (tutorialStep$ | async) === null
  ) {
    <button class="back-btn" (click)="back()">
      <img src="/assets/img/header/back-btn.svg" alt="" />
    </button>
  }

  <div
    class="title-wrapper"
    (click)="playPing1()"
    *ngIf="
      state[uiStatesEnum.state] !== stateEnum.chat &&
      state[uiStatesEnum.state] !== stateEnum.album
    "
  >
    <h1 class="page-title">
      <ng-container *ngIf="state.state === stateEnum.main">
        {{ "CHATS_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.gallery">
        {{ "GALLERY_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container
        *ngIf="
          state.state === stateEnum.bonus ||
          state.state === stateEnum.questLines ||
          state.state === stateEnum.promoCode
        "
        >{{ "BONUS_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.shop">
        {{ "SHOP_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.settings">
        {{ "SETTINGS_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.swipe">
        {{ "SWIPE_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.profile">
        <span class="text-gray"> Profile </span>
      </ng-container>
    </h1>
  </div>

  <div
    class="profile"
    *ngIf="state[uiStatesEnum.state] === stateEnum.album"
    (click)="gotoProfile(state[uiStatesEnum.chatId])"
  >
    <a class="avatar">
      <img
        [attr.src]="state[uiStatesEnum.avatarUrl]"
        alt="girl"
        class="avatar"
        *ngIf="state[uiStatesEnum.avatarUrl] !== ''"
      />
    </a>

    <div class="name-box">
      <p class="main-information-title-14">
        {{ state[uiStatesEnum.characterName] }}
      </p>
    </div>
  </div>

  <div
    *ngIf="
      state[uiStatesEnum.state] === stateEnum.chat && {
        name: chatStateService.modelName$ | async,
        avatar: chatStateService.modelAvatar$ | async,
        isOffline: chatStateService.isOffline$ | async
      } as chatData
    "
    (click)="gotoProfile(chatStateService.chatId)"
    class="profile"
    [class.visible]="chatData.name"
  >
    <a class="avatar">
      <img
        *ngIf="chatData.avatar"
        [src]="chatData.avatar"
        alt="girl"
        class="avatar"
      />
    </a>

    <div class="name-box">
      <p class="main-information-title-14">
        {{ chatData.name }}
      </p>
      <div
        class="status main-information-description-10"
        [class.status-online]="!chatData.isOffline"
        [class.status-offline]="chatData.isOffline"
      >
        {{
          (chatData.isOffline ? "PROFILE_PAGE.OFFLINE" : "PROFILE_PAGE.ONLINE")
            | translate
        }}

        <ng-container *ngIf="newImagesCount$ | async as newImagesCount">
          <span class="new-images-label"
            >+{{ newImagesCount }}
            <img src="/assets/img/header/photo-icon.svg" alt=""
          /></span>
        </ng-container>
      </div>
    </div>
  </div>

  <app-money-counter></app-money-counter>
</ion-header>

@if (
  state[uiStatesEnum.state] === stateEnum.chat &&
  (chatState$ | async) !== "notLoaded"
) {
  @if ((isAiChat$ | async) === false) {
    <app-chat-rating-bar></app-chat-rating-bar>
  }
}
