<div class="head">
  <button class="back-btn" (click)="close()">
    <img src="/assets/img/promo/halloween2023/back-btn.svg" alt="" />
  </button>
  <app-money-counter></app-money-counter>
</div>

<div class="title-line">
  <img src="/assets/img/promo/halloween2023/title.svg" alt="" class="title" />
  <button class="info-btn" (click)="openInfo()"></button>
</div>

<div class="ribbon">Scratch and find UNIQUE CHAT</div>

<div class="rewards-wrapper">
  <div class="rewards-grid">
    <img src="/assets/img/promo/halloween2023/web.svg" alt="" class="web-img" />
    <app-halloween-modal-reward
      *ngFor="let reward of rewards$ | async; let i = index"
      [reward]="reward"
    ></app-halloween-modal-reward>
  </div>
</div>
