export enum LotType {
  giftPack = 'GiftPack',
  gems = 'Gems',
  chat = 'Chat',
  chatPack = 'ChatPack',
  unban = 'Unban',
  offer = 'Offer',
  subscription = 'Subscription',
  lifetimeSubscription = 'LifetimeSubscription',
  swipePack = 'SwipePack',
  skipOfflinePack = 'SkipOfflinePack',
  starterDualOfferPack = 'StarterDualOfferPack',
  starterDualOfferSubscription = 'StarterDualOfferSubscription',
  aiTokens = 'AiTokens',
}
