import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

interface Token {
  type: 'period' | 'cost' | 'nextCost' | 'text';
  text?: string;
}

@Component({
  selector: 'app-premium-button-text',
  templateUrl: './premium-button-text.component.html',
})
export class PremiumButtonTextComponent implements OnChanges, OnDestroy {
  @Input()
  public offerData: OfferData;
  @Input()
  public fullTemplate = '{period} for a {cost} then {nextCost} / {period}';
  @Input()
  public shortTemplate = '{cost} / {period}';

  public tokens: Token[];
  public period: string;

  public readonly environment = environment;
  public readonly buildVersion = BuildVersion;

  private _priceSubscribtion: Subscription;

  constructor(
    private _legalPaymentService: LegalPaymentService,
    private _platform: Platform,
  ) {}

  public ngOnChanges(): void {
    switch (environment.buildVersion) {
      case BuildVersion.legal:
        this._legalPaymentService
          .getProductPricingPhases(this.offerData.purchase)
          .subscribe((phases) => {
            if (phases.length === 1) {
              this.transformTemplate(this.shortTemplate);
            } else {
              this.transformTemplate(this.fullTemplate);
            }
          });
        break;
      case BuildVersion.nutaku:
      case BuildVersion.erogames:
        this.transformTemplate(this.shortTemplate);
        break;
      case BuildVersion.default:
        if (this.offerData.purchase.nextCost) {
          this.transformTemplate(this.fullTemplate);
        } else {
          this.transformTemplate(this.shortTemplate);
        }
        break;
    }

    switch (this.offerData.type) {
      case OfferType.weeklySubscription:
        this.period = 'week';
        break;
      case OfferType.monthlySubscription:
        this.period = 'month';
        break;
      case OfferType.yearlySubscription:
        this.period = 'year';
        break;
    }
  }

  private transformTemplate(template: string) {
    const split = template.split(' ');
    this.tokens = [];
    let text = '';

    for (let i = 0; i < split.length; i++) {
      const token = split[i];

      if (
        token === '{period}' ||
        token === '{cost}' ||
        token === '{nextCost}'
      ) {
        if (text) {
          this.tokens.push({ type: 'text', text: text });
          text = '';
        }

        this.tokens.push({ type: token.replace(/{*}*/g, '') as any });
      } else {
        if (text) {
          text += ' ';
        }
        text += token;
      }
    }
    if (text) {
      this.tokens.push({ type: 'text', text: text });
    }
  }

  public ngOnDestroy(): void {
    this._priceSubscribtion?.unsubscribe();
  }
}
