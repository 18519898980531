import { ModalController } from '@ionic/angular';
import { GiftsApiService } from '../../../../services/API_services/gifts-api.service';
import { Component, OnInit, Input } from '@angular/core';
import { catchError, map, Observable, take } from 'rxjs';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { Gift, UseGiftResponse } from 'src/app/shared/types/gift.interface';
import { ToastsService } from 'src/app/shared/services/toasts.service';

@Component({
  selector: 'app-gifts-modal',
  templateUrl: './gifts-modal.component.html',
  styleUrls: ['./gifts-modal.component.scss'],
})
export class GiftsModalComponent implements OnInit {
  @Input() chatId: string;
  @Input() modelName: string;
  @Input() openFrom = 'default';

  public sendGiftLoader = false;
  public giftsList$: Observable<Gift[]>;

  constructor(
    private _giftsApiService: GiftsApiService,
    private _analyticsService: AnalyticsService,
    private _modalCtrl: ModalController,
    private _toastsService: ToastsService,
  ) {}

  public ngOnInit(): void {
    this.giftsList$ = this._giftsApiService.getGifts();
    this._analyticsService.showGiftsModal(
      this.chatId,
      this.modelName,
      this.openFrom,
    );
  }

  public closeModal(res: UseGiftResponse | Error): void {
    this._modalCtrl.dismiss(res);
  }

  public sendGift(gift: Gift): void {
    if (this.sendGiftLoader) {
      return;
    }

    if (gift.count === 0) {
      this.closeModal(new Error('Not enough gifts'));
      return;
    }

    this.sendGiftLoader = true;
    this._giftsApiService
      .useGift(gift.id, this.chatId)
      .pipe(
        take(1),
        map((res) => {
          this._toastsService.showMessage('You have sent a gift');
          this._analyticsService.buyGift(this.chatId, this.modelName, gift.id);
          this.closeModal(res);
        }),
        catchError((error) => {
          if (error.error.status === 'notEnoughGift.') {
            this.closeModal(new Error('Not enough gifts'));
          }
          this.sendGiftLoader = false;
          return null;
        }),
      )
      .subscribe();
  }
}
