import {
  AfterViewInit,
  Directive,
  ElementRef,
  Host,
  Input,
  OnDestroy,
} from '@angular/core';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { PromoAnalyticsData } from '../types/show-promo-analytics-options.interface';

@Directive({
  selector: '[appEnterTheViewPort]',
})
export class EnterTheViewPortDirective implements AfterViewInit, OnDestroy {
  @Input() showPromoOptions: PromoAnalyticsData = null;

  private _observer: IntersectionObserver;
  private _isShownOnce = false;

  constructor(
    @Host() private _elementRef: ElementRef,
    private _analyticsService: AnalyticsService,
  ) {}

  ngAfterViewInit(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.0,
    };

    this._observer = new IntersectionObserver(this._callback, options);

    this._observer.observe(this._elementRef.nativeElement);
  }

  ngOnDestroy() {
    this._observer.disconnect();
  }

  private _callback = (entries) => {
    if (!this.showPromoOptions) {
      return;
    }
    entries.forEach((entry) => {
      if (!this._isShownOnce) {
        // console.log(
        //   entry.isIntersecting
        //     ? 'I am visible: ' +
        //         this.showPromoOptions.section +
        //         ', name: ' +
        //         this.showPromoOptions.chatName
        //     : 'I am hidden'
        // );
        if (entry.isIntersecting) {
          this._analyticsService.promoIsShown(this.showPromoOptions);
          this._isShownOnce = true;
        }
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
      }
    });
  };
}
