import { RealCostProperties } from './../../types/purchase-data.interface';
import { PromoBrand } from '../../enums/chat-promo-brand.enum';

export enum SwipingCardType {
  free = 'Free',
  secret = 'Secret',
  real = 'Real',
  soft = 'Soft',
  trial = 'Trial',
  fake = 'Fake',
  promo = 'Promo',
  premium = 'Premium',
  ai = 'Ai',
}

export interface SwipingCard {
  id: string;
  cardSetId: string;
  type: SwipingCardType;
  name: string;
  description: string;
  chatId: string;
  imageUrl: string;
  purchase: RealCostProperties;
  softPurchase?: RealCostProperties;
  subscriptionPurchase?: RealCostProperties;
  chatLotId: string;
  promoWidget?: string;
  promoCompany?: PromoBrand;
}

export interface GetSwipingCardsResponse {
  id: string;
  cards: SwipingCard[];
  timeout: number;
  skipCostSingle: number;
  skipCostPack: number;
  swipePackTokens: number;
  noMoreCards: boolean;
  canUseAdForSingleCard: boolean;
}

export interface ReactOnCardsRequest {
  // cardSetId: string;
  cardId: string;
  like: boolean;
}
