import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceBlockModule } from '../../price-block/price-block.module';
import { PremiumButtonTextComponent } from './premium-button-text.component';

@NgModule({
  declarations: [PremiumButtonTextComponent],
  imports: [CommonModule, PriceBlockModule],
  exports: [PremiumButtonTextComponent],
})
export class PremiumButtonTextModule {}
