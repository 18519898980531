import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TutorialService } from '../services/communication_services/tutorial.service';

@Injectable({
  providedIn: 'root',
})
export class TutorialGuard {
  constructor(private _tutorialService: TutorialService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._tutorialService.check(route, state);
  }
}
