export enum RouteName {
  allChats = 'allChats',
  launch = 'launch',
  gallery = 'gallery',
  comics = 'comics',
  settings = 'settings',
  bonus = 'bonus',
  shop = 'shop',
  swipingChatCards = 'swiping-chat-cards',
  profile = 'profile',
}
