import {
  ChangeDetectorRef,
  HostListener,
  Component,
  OnDestroy,
  OnInit,
  NgZone,
} from '@angular/core';
import {
  AppTrackingStatusResponse,
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { OnlineStreamNotificationsService } from './shared/services/communication_services/online-stream-notifications.service';
import { PaymentsCommonMethodsService } from './shared/services/payments/payments-common-methods.service';
import { NotificationService } from './shared/services/notifications_services/notification.service';
import { HeaderFooterService } from './shared/services/communication_services/headerFooter.service';
import { IncomePayloadDataService } from './shared/services/income-payload-data.service';
import { LoaderService } from './shared/services/communication_services/loader.service';
import { LegalPaymentService } from './shared/services/payments/legal-payment.service';
import { WebsocketSignalRService } from './shared/services/websocket-signalr.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { WebsocketCommandType } from './shared/enums/websocket-command-type.enum';
import { AnalyticsService } from './shared/services/analytics/analytics.service';
import { CustomHeaderTextKey } from './shared/enums/custom-header-text-key.enum';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { SoundsService } from './shared/services/sounds.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { RouteName } from './shared/enums/route-name.enum';
import { environment } from 'src/environments/environment';
import { NavController, Platform } from '@ionic/angular';
import { filter, switchMap, take } from 'rxjs/operators';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { SwUpdate } from '@angular/service-worker';
import { StatusBar } from '@capacitor/status-bar';
import { Observable, Subscription } from 'rxjs';
import { NavHelper } from './shared/helpers';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AnalyticsService, NotificationService, LoaderService],
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly isShowHeader$ = this._headerFooterService.isShowHeader$;
  public readonly isShowFooter$ = this._headerFooterService.isShowFooter$;

  public paddingTop = '0px';
  public paddingBottom = '0px';
  public offsetTop = '0px';
  public isShowLoader = false;
  public installPrompt = null;
  public isDeskTop =
    this._platform.is('desktop') && !this._platform.is('cordova');
  registrationCustomTextKey: CustomHeaderTextKey;
  registrationFormEnableClosing: boolean;
  _wsSubscription: Subscription;
  currentRoute = '';
  routeName = RouteName;

  constructor(
    public websocketSignalRService: WebsocketSignalRService,
    private _onlineStreamNotificationsService: OnlineStreamNotificationsService,
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService,
    private _incomePayloadDataService: IncomePayloadDataService,
    private _headerFooterService: HeaderFooterService,
    private _notificationService: NotificationService,
    private _legalPaymentService: LegalPaymentService,
    private _screenOrientation: ScreenOrientation,
    private _analyticsService: AnalyticsService,
    private _loaderService: LoaderService,
    private _soundsService: SoundsService,
    private _navController: NavController,
    private _cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _navHelper: NavHelper,
    private _platform: Platform,
    private _swUpdate: SwUpdate,
    private _router: Router,
    private _zone: NgZone,
  ) {
    if (environment.mode === 'prod') {
      // PWA update on changes
      this._swUpdate.checkForUpdate().then((result) => {
        if (result && confirm('A new version is available. Load it?')) {
          window.location.reload();
        }
      });
    }

    this._loaderService.isLoader.subscribe((state) => {
      this.isShowLoader = state;
      if (state) {
        this._cdr.detectChanges();
      }
    });
    this._analyticsService.init();

    if (environment.buildVersion === BuildVersion.default) {
      this._onlineStreamNotificationsService.init();
    }
    if (environment.buildVersion === BuildVersion.legal) {
      setTimeout(() => {
        this._legalPaymentService.init();
      }, 1000);
    }

    if (_platform.is('cordova')) {
      SafeArea.setImmersiveNavigationBar();

      this.initDeeplinks();

      this._screenOrientation.lock(
        this._screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY,
      );
    }
  }

  ngOnInit() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      this.paddingTop = `${insets.top}px`;
      this.paddingBottom = `${insets.bottom}px`;
      this.offsetTop = `${insets.top}px`;

      // this.paddingTop = `${50}px`;
      // this.paddingBottom = `${50}px`;
      this.checkHeight();
    });

    this._notificationService.init();

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });

    this._route.queryParams.subscribe((params) => {
      if (environment.allowRegistrationForm) {
        // Discord confirmation registration code
        // if (params.code) {
        //   this._authMethodsService.signUpWithDiscord(params.code).subscribe(
        //     (res) => {},
        //     (error) => {
        //       console.error(error);
        //     }
        //   );
        // }
        // Twitter confirmation token
        // if (params.oauth_token && params.oauth_verifier) {
        //   this._authMethodsService
        //     .signWithTwitter({
        //       oAuthToken: params.oauth_token,
        //       oAuthVerifier: params.oauth_verifier,
        //     })
        //     .subscribe(
        //       (res) => {},
        //       (error) => {
        //         console.error(error);
        //       }
        //     );
        // }
      }
      this._incomePayloadDataService.getPayloadDataFromParams(params);
    });
    // this.ym.hit.emit();
    this._analyticsService.setPlatform();
    setTimeout(() => this._analyticsService.setPlatform(), 30_000);

    // Check if we have pending transaction
    // In Localstorage
    // And finish it
    this._paymentsCommonMethodsService.checkTransaction(null, true);
    this._wsSubscription = this.websocketSignalRService
      .init()
      .pipe(
        filter((res) => res === true),
        switchMap(() => this._instantWebsocketConnections()),
        take(1),
      )
      .subscribe();

    try {
      if (Capacitor.getPlatform() === 'ios') {
        this.getStatus()
          .then(async (trackStat) => {
            if (trackStat.status !== 'authorized') {
              await AppTrackingTransparency.requestPermission();
            }
          })
          .catch((e) => console.warn('IOS Error', e));
      }
    } catch (e) {
      console.warn('Authorization failed', e);
    }

    if (this._platform.is('cordova')) {
      StatusBar.hide();
      StatusBar.setOverlaysWebView({ overlay: true });

      document.body.addEventListener('click', () => {
        StatusBar.getInfo().then((info) => {
          if (info.visible) {
            StatusBar.hide();
          }
        });
      });
    }

    this._fbSdkInit();
  }

  private async _fbSdkInit() {
    // await FacebookLogin.initialize({ appId: '768301854963875' });
  }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

  private initDeeplinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this._zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.app').pop();

        if (slug) {
          const queryParams = Object.fromEntries(new URLSearchParams(slug));
          this._incomePayloadDataService.getPayloadDataFromParams(queryParams);
          this._router.navigateByUrl(slug);
        }
      });
    });
  }

  getInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  askUserToInstallApp() {
    this.installPrompt.prompt();
  }

  private _instantWebsocketConnections(): Observable<boolean> {
    return this.websocketSignalRService.send(WebsocketCommandType.coins, {});
  }

  ngOnDestroy(): void {
    this._soundsService.destroy();
    this._wsSubscription?.unsubscribe();
  }

  refreshConnection(event) {
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  private checkHeight(): void {
    if (!this.isDeskTop) {
      document.documentElement.style.setProperty('--app-scale', '1');
    } else {
      const width = Math.min(window.innerHeight / 1.78, 1000);

      document.documentElement.classList.toggle(
        'bg-visible',
        width < window.innerWidth,
      );

      document.documentElement.style.setProperty(
        '--app-scale',
        `${width / 1000}`,
      );
    }
  }
}
