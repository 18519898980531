import { AnimatedCounterModule } from '../../animated-counter/animated-counter.module';
import { MoneyCounterComponent } from './money-counter.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [MoneyCounterComponent],
  imports: [CommonModule, AnimatedCounterModule],
  exports: [MoneyCounterComponent],
})
export class MoneyCounterModule {}
