import {
  Component,
  HostBinding,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { take } from 'rxjs';
import { BonusService } from 'src/app/shared/services/API_services/bonus.service';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { UserQuestLinesResponse } from 'src/app/shared/types/user-quest-lines-response.interface';

@Component({
  selector: 'app-discord-offer',
  templateUrl: './discord.component.html',
  styleUrls: ['./discord.component.scss'],
})
export class DiscordComponent implements OnInit {
  @Input()
  @HostBinding('class')
  public variant: 'modal-variant' | 'shop-variant';

  @Output() public closeModal = new EventEmitter();

  public quest: UserQuestLinesResponse;

  constructor(
    private _analyticsService: AnalyticsService,
    private _bonusService: BonusService,
    private _platform: Platform,
  ) {}

  public ngOnInit(): void {
    this.quest = this._bonusService.getDiscordQuest();
  }

  @HostListener('click')
  private onClick() {
    this._bonusService
      .questLineAcceptation(this.quest.id)
      .pipe(take(1))
      .subscribe(() => {
        this._analyticsService.getBonus(0, this.quest.name);

        if (this._platform.is('cordova')) {
          window.open(this.quest.action.data, '_system', 'location=yes');
        } else {
          window.open(this.quest.action.data, '_blank');
        }
      });
  }

  public close(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.closeModal.emit();
  }
}
