import { LottieModule } from 'ngx-lottie';
import { PaymentAnimatedStatesComponent } from './payment-animated-states/payment-animated-states.component';
import { PaymentsComponent } from './payments.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentIframeComponent } from './payment-iframe/payment-iframe.component';
import { PurchaseConfirmComponent } from './payment-modals/purchase-confirm/purchase-confirm.component';
import { NotEnoughMoneyPopupComponent } from './payment-modals/not-enough-money-popup/not-enough-money-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../controls/button/button.module';

@NgModule({
  declarations: [
    PaymentsComponent,
    PaymentIframeComponent,
    PaymentAnimatedStatesComponent,
    NotEnoughMoneyPopupComponent,
    PurchaseConfirmComponent,
  ],
  imports: [CommonModule, LottieModule, TranslateModule, ButtonModule],
  exports: [
    PaymentsComponent,
    PaymentIframeComponent,
    PaymentAnimatedStatesComponent,
    NotEnoughMoneyPopupComponent,
    PurchaseConfirmComponent,
  ],
})
export class PaymentsModule {}
