import {
  HostBinding,
  Component,
  OnDestroy,
  OnInit,
  Input,
} from '@angular/core';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { NavHelper } from 'src/app/shared/helpers';
import { Subscription, map, of, switchMap, timer } from 'rxjs';

@Component({
  selector: 'app-money-counter',
  templateUrl: './money-counter.component.html',
  styleUrls: ['./money-counter.component.scss'],
})
export class MoneyCounterComponent implements OnInit, OnDestroy {
  @Input()
  @HostBinding('class.enabled-nav')
  public navigationEnabled = true;

  public gems$ = this._inventoryService.gems$.pipe(
    switchMap((value, i) =>
      i === 0 ? of(value) : timer(100).pipe(map(() => value)),
    ),
  );
  public isDiamondScaled = false;

  private _subscribers: Subscription[] = [];
  private _diamondAnimationTimer: ReturnType<typeof setTimeout>;

  constructor(
    private _unsubscribeHelper: UnsubscribeHelper,
    private _inventoryService: InventoryService,
    private _navHelper: NavHelper,
  ) {}

  public ngOnInit(): void {
    this._inventoryService.gems$.subscribe(() => this.startDiamondAnimation());
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);

    if (this._diamondAnimationTimer !== null) {
      clearTimeout(this._diamondAnimationTimer);
    }
  }

  public goToShop(): void {
    if (!this.navigationEnabled) {
      return;
    }
    this._navHelper.goToShopGems();
  }

  private startDiamondAnimation(): void {
    if (this._diamondAnimationTimer !== null) {
      clearTimeout(this._diamondAnimationTimer);
    }

    this.isDiamondScaled = true;

    this._diamondAnimationTimer = setTimeout(() => {
      this.isDiamondScaled = false;
    }, 100);
  }
}
