<div class="rating-bar">
  <ng-container *ngFor="let index of starsArray">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      (click)="select(index)"
      class="rating-star"
    >
      <ng-container *ngIf="stars > index">
        <path
          d="M8.73171 0.83756C8.85427 0.586165 9.04395 0.374484 9.27928 0.226465C9.5146 0.078447 9.78619 0 10.0633 0C10.3404 0 10.612 0.078447 10.8473 0.226465C11.0827 0.374484 11.2723 0.586165 11.3949 0.83756L13.4587 5.06504L18.0724 5.74271C18.3467 5.783 18.6043 5.90013 18.8162 6.08085C19.0281 6.26157 19.1857 6.49867 19.2712 6.76531C19.3568 7.03195 19.3669 7.31749 19.3003 7.58961C19.2337 7.86174 19.0932 8.10958 18.8946 8.3051L15.5558 11.5959L16.3446 16.2438C16.3915 16.5199 16.3611 16.8038 16.2569 17.0633C16.1527 17.3229 15.9788 17.5478 15.7548 17.7126C15.5309 17.8774 15.2658 17.9756 14.9896 17.996C14.7134 18.0164 14.437 17.9583 14.1917 17.8282L10.0624 15.6319L5.93488 17.8282C5.68961 17.9583 5.41325 18.0164 5.13703 17.996C4.86081 17.9756 4.59574 17.8774 4.37179 17.7126C4.14784 17.5478 3.97393 17.3229 3.86971 17.0633C3.7655 16.8038 3.73513 16.5199 3.78205 16.2438L4.56895 11.5959L1.2302 8.3051C1.03156 8.10946 0.891036 7.86147 0.824558 7.5892C0.758081 7.31694 0.768302 7.03129 0.854064 6.7646C0.939827 6.49791 1.0977 6.26084 1.30981 6.08023C1.52192 5.89963 1.77979 5.78271 2.05422 5.74271L6.66796 5.06692L8.73171 0.83756Z"
          fill="url(#paint0_linear_0_3867)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_0_3867"
            x1="9.5"
            y1="0"
            x2="9.5"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FC46A4" />
            <stop offset="1" stop-color="#DA2FF5" />
          </linearGradient>
        </defs>
      </ng-container>

      <ng-container *ngIf="stars <= index">
        <path
          d="M8.29421 0.83756C8.41677 0.586165 8.60645 0.374484 8.84178 0.226465C9.0771 0.078447 9.34869 0 9.62581 0C9.90293 0 10.1745 0.078447 10.4098 0.226465C10.6452 0.374484 10.8348 0.586165 10.9574 0.83756L13.0212 5.06504L17.6349 5.74271C17.9092 5.783 18.1668 5.90013 18.3787 6.08085C18.5906 6.26157 18.7482 6.49867 18.8337 6.76531C18.9193 7.03195 18.9294 7.31749 18.8628 7.58961C18.7962 7.86174 18.6557 8.10958 18.4571 8.3051L15.1183 11.5959L15.9071 16.2438C15.954 16.5199 15.9236 16.8038 15.8194 17.0633C15.7152 17.3229 15.5413 17.5478 15.3173 17.7126C15.0934 17.8774 14.8283 17.9756 14.5521 17.996C14.2759 18.0164 13.9995 17.9583 13.7542 17.8282L9.62488 15.6319L5.49738 17.8282C5.25211 17.9583 4.97575 18.0164 4.69953 17.996C4.42331 17.9756 4.15824 17.8774 3.93429 17.7126C3.71034 17.5478 3.53643 17.3229 3.43221 17.0633C3.328 16.8038 3.29763 16.5199 3.34455 16.2438L4.13145 11.5959L0.792703 8.3051C0.594055 8.10946 0.453536 7.86147 0.387058 7.5892C0.320581 7.31694 0.330802 7.03129 0.416564 6.7646C0.502327 6.49791 0.660203 6.26084 0.872313 6.08023C1.08442 5.89963 1.34229 5.78271 1.61672 5.74271L6.23046 5.06692L8.29421 0.83756Z"
          fill="url(#paint0_linear_3318_3640)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3318_3640"
            x1="9.625"
            y1="0"
            x2="9.625"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E9E9E9" />
            <stop offset="1" stop-color="#D8D8D8" />
          </linearGradient>
        </defs>
      </ng-container>
    </svg>
  </ng-container>
</div>
