import { PremiumDescriptionTextComponent } from './premium-description-text.component';
import { PremiumButtonTextModule } from '../button-text/premium-button-text.module';
import { PriceBlockModule } from '../../price-block/price-block.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [PremiumDescriptionTextComponent],
  imports: [CommonModule, PriceBlockModule, PremiumButtonTextModule],
  exports: [PremiumDescriptionTextComponent],
})
export class PremiumDescriptionTextModule {}
