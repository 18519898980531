import { DirectivesModule } from './../../shared/directives/directives.module';
import { BannerSliderModule } from './../../shared/components/banner-slider/banner-slider.module';
import { PhotoGalleryModule } from 'src/app/shared/components/photo-gallery/photo-gallery.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DiscordLinkModule } from 'src/app/shared/components/discord-link/discord-link.module';
import { ProfileGalleryComponent } from './profile-gallery/profile-gallery.component';

@NgModule({
  declarations: [ProfileComponent, ProfileGalleryComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    PhotoGalleryModule,
    HttpClientModule,
    BannerSliderModule,
    DirectivesModule,
    PipesModule,
    IonicModule,
    TranslateModule,
    DiscordLinkModule,
  ],
})
export class ProfileModule {}
