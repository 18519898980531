import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLevelUpPopupComponent } from './chat-levelup-popup.component';

@NgModule({
  declarations: [ChatLevelUpPopupComponent],
  imports: [CommonModule],
  exports: [ChatLevelUpPopupComponent],
})
export class ChatLevelUpPopupModule {}
